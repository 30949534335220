import axios from "axios";
import store from "@/store";
import Notifications from "@kyvg/vue3-notification";
import emitter from "./eventBus"; // Import the event bus

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/api/v1/"
      : "https://prysmdev.xtractautomation.com/api/v1/",
  timeout: 5000, // Adjust as needed
});
// Utility function to get user details
export async function getUserDetails() {
  // Create notifications instance
  try {
    const response = await axiosInstance.get("accounts/get-user-details/");
    console.log("response.data", response.data);

    localStorage.setItem("firstName", response.data.user.first_name);
    localStorage.setItem("lastName", response.data.user.last_name);
    localStorage.setItem("userRole", response.data.user.role);
    localStorage.setItem("isStaff", response.data.user.is_staff);
    console.log("details set", response.data.user.is_staff);
    localStorage.setItem("userCart", JSON.stringify(response.data.cart_items));
    store.commit("setUserDetails", response.data.user);
    store.commit("updateUserCart", response.data.cart_items);
    console.log(store.state.user);
  } catch (error) {
    console.log(error);
    // throw error;
  }
}

export async function signOut() {
  axiosInstance.defaults.headers.common["Authorization"] = "";

  // localStorage.removeItem("userCart");
  localStorage.clear();

  sessionStorage.removeItem("cart");
  store.commit("removeAccessToken");
  store.commit("removeRefreshToken");
  store.commit("removeUserDetails");
  store.commit("removeCart");
  store.commit("removeUserCart");
  window.location.href = "/";
}

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log("Request interceptor triggered");
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // console.log("Access token found inside interceptor:", accessToken);
    } else {
      // console.log("No access token found");
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lies within the range of 2xx causes this function to trigger
    // Do something with response data
    // console.log("Response interceptor triggered");
    return response;
  },
  async function (error) {
    // console.log("Error response interceptor triggered");
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log("error response inside interceptor", error.response);
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Unauthorized error, attempt to refresh token
      // console.log("Unauthorized error, attempting to refresh token");
      //  {
      const response = await axiosInstance.post("accounts/token-jwt/refresh/", {
        refresh: localStorage.getItem("refreshToken"),
      });

      // console.log("response data in jwt refresh ", response.data);

      const newAccessToken = response.data.access;

      if (newAccessToken) {
        console.log(
          "user.isAutheticated beofre commit",
          store.state.user.isAuthenticated
        );
        store.commit("setAccessToken", newAccessToken);

        localStorage.setItem("accessToken", newAccessToken);

        // config.headers["Authorization"] = `JWT ${newAccessToken}`;

        axiosInstance.defaults.headers.common["Authorization"] =
          "JWT " + newAccessToken;
      }

      getUserDetails();

      // Retry the original request with the new access token
      error.response.config.headers["Authorization"] = `JWT ${newAccessToken}`;

      return axiosInstance(error.response.config);
      // catch (refreshError) {
      //   // Handle refresh token failure
      //   console.error("Error refreshing token:", refreshError);
      //   // Optionally, redirect to login page or show error message
      //   throw refreshError;
      // }
    } else if (error.response && error.response.status === 418) {
      // Token expired, sign out and notify user to sign in again
      console.log("Token expired, signing out...");

      emitter.emit("notify", {
        title: "Your session has expired.Please sign in again.",
        type: "bg-danger-subtle text-danger",
        duration: 5000,
      });

      setTimeout(function () {
        signOut();
      }, 2000);
      // Notify user to sign in again
      // Notifications.value({
      //   title: "Your session has expired. Please sign in again.",
      //   type: "bg-danger-subtle text-danger",
      //   duration: "5000",
      // });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
