<template>
  <div>
    <h1>Zoho Contacts</h1>
    <ul>
      <li v-for="(contact, index) in contacts" :key="index">
        {{ contact.contact_name }} - {{ contact.customer_name }} -
        {{ contact.vendor_name }} - {{ contact.company_name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contacts: [],
    };
  },
  mounted() {
    this.getZohoContacts();
  },
  methods: {
    async getZohoContacts() {
      this.$store.commit("setIsLoading", true);

      try {
        const response = await axiosInstance.get(
          "/accounts/get_contacts_list/"
        );
        this.contacts = response.data.contacts;
        console.log("Zoho contacts", this.contacts);
      } catch (error) {
        console.error(error);
      }

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
