<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <label class="form-label">Name</label>
              <p>{{ profile.first_name }} {{ profile.last_name }}</p>
            </div>
            <div class="col">
              <label class="form-label">Email Address</label>
              <p>{{ profile.email }}</p>
            </div>
            <div class="col">
              <label class="form-label">Phone Number</label>
              <p>{{ profile.phone_number }}</p>
            </div>
            <div class="col">
              <div
                class="btn-group-vertical"
                role="group"
                aria-label="Basic outlined example"
              >
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#editProfileModal"
                >
                  Edit Details
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#changePasswordModal"
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="editProfileModal"
      tabindex="-1"
      aria-labelledby="editProfileModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editProfileModalLabel">
              Edit Profile Details
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <EditProfile v-model="editProfileForm" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-dark"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-outline-success"
              @click="saveChanges"
              data-bs-dismiss="modal"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="changePasswordModal"
      tabindex="-1"
      aria-labelledby="changePasswordModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="changePasswordModalLabel">
              Change Password
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <ChangePassword v-model="changePasswordForm" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-dark"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-outline-success"
              @click="submitChangePassword"
              data-bs-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditProfile from "./EditProfile.vue";
import ChangePassword from "./ChangePassword.vue";

export default {
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editProfileForm: { ...this.profile },
      changePasswordForm: {
        oldPassword: "",
        newPassword: "",
        newPassword2: "",
      },
    };
  },
  mounted() {},
  methods: {
    async saveChanges() {
      try {
        console.log("print");
        const response = await axiosInstance.put(
          "/accounts/edit-self-user-account/",
          this.editProfileForm
        );
        this.$emit("profileUpdated", response.data.user);
        this.$notify({
          title: "Profile Details Updated successfully",
          type: "bg-success-subtle text-success",
          duration: "5000",
        });
      } catch (error) {
        this.$notify({
          title: "An error occured, please try again later",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
        console.error("Error updating user details:", error);
      }
    },
    async submitChangePassword() {
      try {
        console.log("print");
        if (
          this.changePasswordForm.newPassword !==
          this.changePasswordForm.newPassword2
        ) {
          this.$notify({
            title: "Passwords do not match",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
          return;
        }
        const response = await axiosInstance.post(
          "/accounts/change-password/",
          this.changePasswordForm
        );
        this.$notify({
          title: "Password changed successfully, please log in again",
          type: "bg-success-subtle text-success",
          duration: "5000",
        });
        this.signOut();
      } catch (error) {
        this.$notify({
          title: "An error occured, please try again later",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
        console.error("Error updating user details:", error);
      }
    },
    signOut() {
      axiosInstance.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("userRole");
      localStorage.removeItem("userCart");
      sessionStorage.removeItem("cart");
      this.$store.commit("removeAccessToken");
      this.$store.commit("removeRefreshToken");
      this.$store.commit("removeUserDetails");
      this.$store.commit("removeCart");
      this.$store.commit("removeUserCart");
      this.$router.push("/");
    },
  },
  components: { EditProfile, ChangePassword },
};
</script>

<style>
/* Your CSS styles here */
</style>
