<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <h6><strong> Shipping Information </strong></h6>
          <div>
            {{ shippingAddress.first_name }}
            {{ shippingAddress.last_name }}
          </div>
          <div>
            {{ shippingAddress.email }}
          </div>
          <div>
            {{ shippingAddress.phone_number }}
          </div>
          <div>
            {{ shippingAddress.street_address_1 }}
          </div>
          <div>
            {{ shippingAddress.street_address_2 }}
          </div>
          <div>
            {{ shippingAddress.city }}
          </div>
          <div>
            {{ shippingAddress.state }}
          </div>
          <div>
            {{ shippingAddress.postal_code }}
          </div>
        </div>
        <div class="col">
          <h6>
            <strong> Billing Information </strong>
          </h6>

          <div>
            {{ billingAddress.first_name }}
            {{ billingAddress.last_name }}
          </div>
          <div>
            {{ billingAddress.email }}
          </div>
          <div>
            {{ billingAddress.phone_number }}
          </div>
          <div>
            {{ billingAddress.street_address_1 }}
          </div>
          <div>
            {{ billingAddress.street_address_2 }}
          </div>
          <div>
            {{ billingAddress.city }}
          </div>
          <div>
            {{ billingAddress.state }}
          </div>
          <div>
            {{ billingAddress.postal_code }}
          </div>
        </div>
        <div class="col">
          <h6><strong> Payment Information </strong></h6>
          <div v-if="order?.payment_info?.method">
            Payment Method:
            {{ capitalize(order.payment_info.method) }}
          </div>
          <div v-if="order?.payment_info?.card">
            {{ capitalize(order?.payment_info?.card?.network) }} card ending in
            {{ order?.payment_info?.card?.last4 }}
          </div>
          <div v-if="order?.payment_info?.vpa">
            VPA: {{ order?.payment_info?.vpa }}
          </div>
          <div v-if="order?.payment_info?.wallet">
            Wallet: {{ capitalize(order?.payment_info?.wallet) }}
          </div>
        </div>
        <div class="col">
          <h6>
            <strong> Order Summary </strong>
          </h6>
          <div v-if="order.order_bills">
            <div class="d-flex justify-content-between mb-2">
              <span>Sub Total</span>
              ₹ {{ $parsePriceToString(order.order_bills[0].sub_total) }}
            </div>

            <div
              v-if="order.discount_code"
              class="d-flex justify-content-between mb-2"
            >
              <div class="text-success">
                <h6 class="my-0">Discount code</h6>
                <small>{{ order.discount_code.discount_code }}</small>
              </div>
              <span class="text-success"
                >- ₹{{
                  $parsePriceToString(order.order_bills[0].discount_amount)
                }}</span
              >
            </div>

            <div class="d-flex justify-content-between">
              <span>Total</span>
              <strong
                >₹ {{ $parsePriceToString(order.order_bills[0].total) }}</strong
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <h5>Order Items</h5>
      <OrderItemsListWithOptions
        @orderItemSelected="handleOrderItemSelected"
        :order-items="order.order_items"
      />
    </div>
  </div>
</template>
<script>
import OrderItemsListWithOptions from "./OrderItemsListWithOptions.vue";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shippingAddress: "",
      billingAddress: "",
    };
  },
  mounted() {
    this.shippingAddress = this.order.order_addresses.filter(
      (address) => address.address_type == "Shipping"
    )[0];
    this.billingAddress = this.order.order_addresses.filter(
      (address) => address.address_type == "Billing"
    )[0];
  },
  methods: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    handleOrderItemSelected(orderItem) {
      this.$emit("orderItemSelected", orderItem);
    },
  },
  components: { OrderItemsListWithOptions },
};
</script>
