<template>
  <div class="container">
    <div class="row justify-content-center">
      <div
        class="col-md-8 mt-2"
        style="
          background-color: rgba(255, 255, 255, 0.7);
          border-radius: 10px;
          padding: 20px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        "
      >
        <form @submit.prevent="handleSubmit">
          <h3>Reset Password</h3>

          <div class="form-group">
            <label>Enter New Password</label>
            <input
              type="password"
              class="form-control"
              v-model="password"
              placeholder="New Password"
            />
          </div>

          <div class="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              class="form-control"
              v-model="repeatPassword"
              placeholder="Confirm Password"
            />
          </div>

          <button class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ResetPassword",

  data() {
    return {
      password: "",
      repeatPassword: "",
    };
  },

  methods: {
    async handleSubmit() {
      await axios
        .post("accounts/reset_password/", {
          password: this.password,
          repeatPassword: this.repeatPassword,
          token: this.$route.params.token,
        })
        .then((response) => {
          console.log(response.data);
          this.$router.push("/password-done");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
