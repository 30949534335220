<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h4 class="d-flex justify-content-between align-items-center">
          Review Summary
        </h4>
      </div>

      <div class="accordion" id="accordionExample" style="margin-top: 2%">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <strong> Customer Details </strong>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                <strong>Name:</strong>
                {{ customerDetails.customer_display_name }}
              </p>
              <p><strong>Email:</strong> {{ addressDetails.email }}</p>
              <p>
                <strong>Company Name : </strong
                >{{ customerDetails.company_name }}
              </p>
              <p>
                <strong>Customer Type : </strong
                >{{ customerDetails.customer_type }}
              </p>
              <p>
                <strong>Fax Number : </strong>{{ customerDetails.fax_number }}
              </p>
              <p>
                <strong>GST Treatment : </strong>
                {{ customerDetails.gst_treatment }}
              </p>
              <p>
                <strong>PAN Card Number : </strong>{{ customerDetails.pan }}
              </p>
              <p>
                <strong>Tax Preference : </strong>
                {{ customerDetails.tax_preference }}
              </p>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <strong> Address Details </strong>
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                <strong>Street 1 : </strong>
                {{ addressDetails.street_address_1 }}
              </p>
              <p>
                <strong>Street 2 : </strong>
                {{ addressDetails.street_address_2 }}
              </p>
              <p><strong>City:</strong> {{ addressDetails.city }}</p>
              <p><strong>State : </strong>{{ addressDetails.state }}</p>
              <p>
                <strong>Postal Code : </strong>{{ addressDetails.postal_code }}
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <strong> Payment Details </strong>
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                <strong>Payment Method:</strong>
                {{ paymentDetails.percentage }}%
                {{ paymentDetails.payment_type }}
              </p>
              <!-- <p><strong>Card Number:</strong> {{ paymentDetails.cardNumber }}</p>
                            <p><strong>Expiration Date:</strong> {{ paymentDetails.expirationDate }}</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customerDetails: {
      type: Object,
      default: () => ({}),
    },
    addressDetails: {
      type: Object,
      default: () => ({}),
    },
    paymentDetails: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
