<template>
  <div>
    <span class="h4 text-body-secondary d-md-block d-none">Filters</span>
    <div class="mb-3">
      <span class="badge me-2 custom-badge" v-for="tag in selectedTags" :key="tag.product_tag_id">
        {{ tag.name }}
        <span  @click="removeFilter(tag)" style="cursor: pointer;">
          <i class="bi-x-lg"></i>
        </span>
        <!-- <button type="button" class="btn-close" @click="removeFilter(tag)" aria-label="Close"></button> -->
      </span>
    </div>
    <div v-for="(tag, index) in tags" :key="tag.product_tag_id">
      <div class="card mt-3" v-if="shouldDisplayCategory(tag, index)" style=" background-color: #dc3545;">
        <div class="card-header d-flex justify-content-between align-items-center text-white" @click="toggleList">
          <span>{{ tag.product_tag_category.name }}</span>
          <i :class="['bi', showList ? 'bi-caret-up-fill text-white' : 'bi-caret-down-fill text-white']"></i>
        </div>
        <ul class="list-group list-group-flush" v-if="showList">
          <li class="list-group-item" v-for="tagItem in tags" :key="tagItem.product_tag_id" @click="toggleCheckbox(tagItem)">
            <input type="checkbox"  v-model="tagItem.checked"  />
            {{ tagItem.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showList: true,
    };
  },
  props: {
    tags: Array,
  },
  computed: {
    selectedTags() {
      return this.tags.filter(tag => tag.checked);
    }
  },
  methods: {
    toggleList() {
      this.showList = !this.showList;
    },
    shouldDisplayCategory(tag, index) {
      if (index === 0 || this.tags[index - 1].product_tag_category.product_tag_category_id !== tag.product_tag_category.product_tag_category_id) {
        return true;
      }
      return false;
    },
    toggleCheckbox(tagItem) {
      tagItem.checked = !tagItem.checked;
      console.log(tagItem)
      this.$emit('filterChanged',tagItem)
    },
    removeFilter(tag) {
      tag.checked = false;
      this.$emit('filterChanged', tag);
    }
  },
};
</script>

<style scoped>
.list-group-item:hover {
  background-color: #db4c5a;
  color: whitesmoke;
  cursor: pointer;
}
.custom-badge {
  background-color: #db4c5a;
}

</style>