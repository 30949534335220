<template>
  <div class="col-12">
    <h4 class="d-flex justify-content-between align-items-center">
      Select Customer
      <button
        class="btn btn-outline-success"
        data-bs-toggle="modal"
        data-bs-target="#addCustomerModal"
      >
        <i class="bi bi-plus-lg"></i> Add Customer
      </button>
    </h4>
  </div>

  <div
    class="modal fade"
    id="addCustomerModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addCustomerModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="addCustomerModalLabel">Add Customers</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <!-- <span aria-hidden="true">&times;</span> -->
          </button>
        </div>
        <div class="modal-body">
          <AddCustomer :isModal="true" />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <ag-grid-vue
      :rowData="rowData"
      :columnDefs="columnDefs"
      :pagination="true"
      :paginationPageSize="10"
      style="height: 300px"
      class="ag-theme-quartz"
      @selectionChanged="onSelectionChanged"
      ref="agGrid"
      @firstDataRendered="onFirstDataRendered"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import CustomerForms from "@/components/Account/CustomerForms.vue";
import AddCustomer from "@/views/Account/AddCustomer.vue";

export default {
  props: {
    rowData: {
      type: Array,
      required: true,
    },
    selectedCustomer: {
      type: Object,
      default: null,
    },
  },
  components: {
    AgGridVue,
    CustomerForms,
    AddCustomer,
  },

  data() {
    return {
      columnDefs: [
        {
          headerName: "Company Name",
          field: "company_name",
          filter: true,
          checkboxSelection: true,
        },
        { headerName: "Customer Type", field: "customer_type" },
        { headerName: "Tax Preference", field: "tax_preference" },
        { headerName: "Gst Treatment", field: "gst_treatment" },
        {
          headerName: "Payment Term",
          cellRenderer: function (params) {
            const paymentTerm = params.data.payment_term;
            if (paymentTerm) {
              const percentage = paymentTerm.percentage;
              const type = paymentTerm.payment_type;

              return `<span>${percentage}% ${type}</span>`;
            } else {
              return "null";
            }
          },
        },
      ],
      selectedRowId: null,

      defaultColDef: {
        flex: 1,
        minWidth: 150,
      },
    };
  },
  computed: {
    updatedRowData() {
      if (!this.rowData || !this.selectedCustomer) return [];
      return this.rowData.map((row) => {
        return {
          ...row,
          isSelected: row.id === this.selectedRowId,
        };
      });
    },
  },
  mounted() {
    if (this.selectedCustomer) {
      console.log("selected cust", this.selectedCustomer);
      //   const agGrid = this.$refs.agGrid.api;
      //   const selectedNode = agGrid.getRowNode(this.selectedCustomer.id);
      //   if (selectedNode) {
      //     selectedNode.setSelected(true);
      //   }
    }
  },
  methods: {
    onSelectionChanged(event) {
      const selectedRows = event.api.getSelectedRows();
      if (selectedRows.length > 0) {
        this.selectedRowId = selectedRows[0].id; // Update selectedRowId when a row is selected
        this.$emit("customer-selected", selectedRows[0]);
      }
    },
    onFirstDataRendered(params) {
      console.log("hell ");
      if (this.selectedCustomer) {
        params.api.forEachNode((node) => {
          if (this.selectedCustomer.id == node.data.id) {
            node.setSelected(true);
          }
        });
      }
    },
  },
};
</script>
