<template>
  <div class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>Home</a
          >
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <a class="text-secondary" href="#"
            ><b><i class="bi bi-person"></i> Log In</b></a
          >
        </li>
      </ol>
    </nav>

    <p class="h1">Log In</p>
    <hr />
    <div class="row justify-content-center">
      <div
        class="col-md-4 text-center signin mt-4"
        style="
          background-color: rgba(255, 255, 255, 0.7);
          border-radius: 10px;
          padding: 20px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        "
      >
        <p class="h4 mb-4 text-center signintext top">
          <strong style="color: #333"> Log back into Prysm </strong>
        </p>

        <!-- <div class="social-btn text-center mb-4">
                        <a href="#" class="btn btn-primary btn-lg mx-2" title="Facebook"><i class="bi bi-facebook"></i></a>
                        <a href="#" class="btn btn-info btn-lg mx-2" title="Twitter"><i class="bi bi-twitter"></i></a>
                        <a href="#" class="btn btn-danger btn-lg mx-2" title="Google"><i class="bi bi-google"></i></a>
                    </div> -->

        <!-- <p class="text-center signintext" style="color: #333;">Or use your Email account</p> -->
        <form class="needs-validation" @submit="logIn" novalidate>
          <div class="mb-4">
            <div class="input-group">
              <span class="input-group-text"
                ><i class="bi bi-envelope"></i
              ></span>
              <input
                v-model="logInForm.email"
                type="email"
                class="form-control"
                placeholder="Email"
                required
              />
              <div class="invalid-feedback">Please provide a valid email.</div>
            </div>
          </div>

          <div class="mb-4">
            <div class="input-group">
              <span class="input-group-text"><i class="bi bi-lock"></i></span>
              <input
                v-model="logInForm.password"
                type="password"
                class="form-control"
                placeholder="Password"
                required
              />
              <div class="invalid-feedback">Password is required.</div>
            </div>
          </div>

          <div class="button mb-2">
            <button
              class="btn btn-outline-success btn-block LoginBtn"
              type="submit"
            >
              Sign In
            </button>
          </div>
          <p>
            <a href="/forgot-password">Forgot Your Password?</a>
          </p>
          <p>
            Don't have a Prysm Account?
            <a href="/sign-up">Create an Account</a>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import axiosInstance from "@/utils/axiosInstance";

export default {
  data() {
    return {
      logInForm: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    console.log(this.$store.state.user.accessToken);
    const forms = document.querySelectorAll(".needs-validation");
    Array.from(forms).forEach((form) => {
      form.addEventListener(
        "submit",
        (event) => {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });
  },
  components: {},
  computed: {},
  methods: {
    async logIn(event) {
      event.preventDefault();
      const form = event.target;
      if (form.checkValidity()) {
        console.log("callled");
        await axiosInstance
          .post("accounts/token-jwt/create-new/", this.logInForm)
          .then((response) => {
            console.log("response.data");
            console.log(response.data);
            const accessToken = response.data.access;
            console.log("acess token", accessToken);
            const refreshToken = response.data.refresh;
            this.$store.commit("setAccessToken", accessToken);
            this.$store.commit("setRefreshToken", refreshToken);
            axiosInstance.defaults.headers.common["Authorization"] =
              "JWT " + accessToken;
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            this.getUserDetails();
            this.$notify({
              title: "Logged In Successfuly",
              type: "bg-success-subtle text-success",
              duration: "5000",
            });
            this.$router.push("/");
          })
          .catch((error) => {
            console.log(error);
            if (error.response) {
              if (
                error.response.status == 401 ||
                error.response.status == 400
              ) {
                for (const property in error.response.data) {
                  this.$notify({
                    title: error.response.data[property],
                    type: "bg-danger-subtle text-danger",
                    duration: "5000",
                  });
                }
              } else {
                this.$notify({
                  title: "Something went wrong. Please try again",
                  type: "bg-danger-subtle text-danger",
                  duration: "5000",
                });
              }
            } else {
              this.$notify({
                title: "Something went wrong. Please try again",
                type: "bg-danger-subtle text-danger",
                duration: "5000",
              });
            }
          });
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add("was-validated");
    },
    async getUserDetails() {
      await axiosInstance
        .get("accounts/get-user-details/")
        .then((response) => {
          console.log("response.data");
          console.log(response.data);

          localStorage.setItem("firstName", response.data.user.first_name);
          localStorage.setItem("lastName", response.data.user.last_name);
          localStorage.setItem("userRole", response.data.user.role);
          localStorage.setItem("isStaff", response.data.user.is_staff);
          console.log("details set", response.data.user.is_staff);
          localStorage.setItem(
            "userCart",
            JSON.stringify(response.data.cart_items)
          );
          this.$store.commit("setUserDetails", response.data.user);
          this.$store.commit("updateUserCart", response.data.cart_items);
          console.log(this.$store.state.user);
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Something went wrong. Please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },
  },
};
</script>
