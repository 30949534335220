<template>
  <div class="container" style="text-align: left">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home</a
          >
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <a class="text-secondary" herf="/my-cart">
            <b>
              <i class="bi bi-cart3"></i>
              My Cart
            </b>
          </a>
        </li>
      </ol>
    </nav>
    <h1>My Cart</h1>
    <hr />
    <div class="row">
      <div class="col-md-8">
        <CartItem
          :order_items="order_items"
          @delete-item="handleDeleteItem"
          @cart-updated="handleCartUpdated"
        />
      </div>
      <div class="col-md-4">
        <h4 class="mb-1">Discount Code</h4>
        <span
          v-if="appliedCoupon"
          class="badge bg-success-subtle text-success mb-1"
          >{{ appliedCoupon.discount_code }} Applied</span
        >
        <div class="d-flex justify-content-between mb-3">
          <button
            class="btn btn-secondary"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#applyCouponCodes"
            style="margin-right: 3%"
          >
            View All Discount Codes
          </button>
        </div>
        <hr />

        <ul class="list-group mb-3">
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <div>
              <h6 class="my-0">Sub Total</h6>
            </div>
            <span class="text-muted">
              ₹ {{ $parsePriceToString(subTotal) }}
            </span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <div class="text-success">
              <h6 class="my-0">Savings</h6>
            </div>
            <span class="text-success">₹ {{ savings }}</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span><b>Total</b> </span>

            <strong>₹ {{ $parsePriceToString(totalPrice) }}</strong>
          </li>
        </ul>
        <button
          class="w-100 btn btn-success btn-lg"
          type="submit"
          @click="$router.push('/checkout')"
          v-if="$store.state.user.isAuthenticated"
        >
          Continue to Checkout
        </button>
        <button
          class="w-100 btn btn-success btn-lg"
          type="submit"
          data-bs-toggle="modal"
          data-bs-target="#signUpModal"
          v-else
        >
          Continue to Checkout
        </button>
      </div>
    </div>

    <div
      class="modal fade"
      id="applyCouponCodes"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="applyCouponCodesLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="applyCouponCodes">Discount Codes</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Code"
                id="coupon"
                v-model="couponCode"
              />
              <button
                @click="searchCoupon"
                class="btn btn-outline-secondary"
                type="button"
              >
                Check
              </button>
            </div>
            <br />
            <ul class="list-group">
              <li
                v-for="code in discountCodes"
                :key="code.discount_code_id"
                class="list-group-item d-flex justify-content-between align-items-start"
              >
                <div class="ms-2 me-auto">
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      :id="`checkbox-${code.discount_code_id}`"
                      v-model="selectedCoupon"
                      :value="code"
                    />
                    <label
                      class="form-check-label"
                      :for="`checkbox-${code.discount_code_id}`"
                    >
                      <strong>{{ code.discount_code }}</strong>
                    </label>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item" v-if="code.discount_percentage">
                      {{ code.discount_percentage }}% Off
                    </li>
                    <li class="list-group-item" v-if="code.discount_amount">
                      Discount Amount: ₹
                      {{ $parsePriceToString(code.discount_amount) }}
                    </li>
                    <li
                      class="list-group-item"
                      v-if="code.min_amount_for_discount"
                    >
                      Minimum amount for discount: ₹
                      {{ $parsePriceToString(code.min_amount_for_discount) }}
                    </li>
                    <li class="list-group-item" v-if="code.max_discount_amount">
                      Maximum Discount Amount: ₹
                      {{ $parsePriceToString(code.max_discount_amount) }}
                    </li>
                    <li
                      class="list-group-item"
                      v-if="code.max_number_of_applicable_products"
                    >
                      Applicable on a maximum of
                      {{ code.max_number_of_applicable_products }} products
                    </li>
                    <li class="list-group-item" v-if="code.note">
                      {{ code.note }}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-outline-success"
              data-bs-dismiss="modal"
              @click="applyCoupon"
            >
              Apply Coupon
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="signUpModal"
      tabindex="-1"
      aria-labelledby="signUpModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="signUpModalLabel">
              You need to have an account to proceed
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <SignUpForm />
          </div>
          <!-- <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Sign Up</button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <productCustomizationsSelectionsDetail :order_items="order_items" /> -->
  </div>
</template>

<script>
import CartItem from "@/components/Orders/CartItem.vue";
import SignUpForm from "@/components/Account/SignUpForm.vue";
// import productCustomizationsSelectionsDetail from "@/components/modal/productCustomizationsSelectionsDetail.vue";

export default {
  // props: {
  //     order_items: []
  // },

  data() {
    return {
      orders: [],
      order_items: [],
      discountCodes: [],
      couponCode: "",
      selectedCoupon: null,
      totalPrice: 0,
      appliedCoupon: "",
      subTotal: 0,
      savings: 0,
    };
  },

  components: {
    CartItem,
    SignUpForm,
    // productCustomizationsSelectionsDetail,
  },
  mounted() {
    this.getCart();
    this.listDiscountCode();
  },

  methods: {
    searchCoupon() {
      const foundCoupon = this.discountCodes.find(
        (code) =>
          code.discount_code.toLowerCase() === this.couponCode.toLowerCase()
      );
      if (foundCoupon) {
        this.selectedCoupon = foundCoupon;
        this.$notify({
          title: "Coupon Found",
          type: "bg-success-subtle text-success",
          duration: "5000",
        });
      } else {
        this.$notify({
          title: "No coupon found",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
      }
    },
    applicableProducts(coupon) {
      let count = 0;
      for (let product of coupon.applicable_products) {
        for (let orderItem of this.order_items) {
          // console.log(product)
          if (orderItem.product.product_id === product) {
            count += orderItem.quantity;
          } else {
            continue;
          }
        }
      }
      console.log(count);
      if (count > coupon.max_number_of_applicable_products) {
        return (
          false,
          this.$notify({
            title: "Coupons applied on maximum products. ",
            type: "bg-success-subtle text-success",
            duration: "5000",
          })
        );
      } else {
        return true;
      }
    },

    checkCouponExpiryDateAndMaxDiscountAmount(coupon) {
      let currentDateTime = new Date().toISOString();
      if (
        coupon.expiration_date > currentDateTime ||
        coupon.expiration_date === null
      ) {
        if (this.calculateSubTotal() >= coupon.min_amount_for_discount) {
          return true;
        } else {
          this.$notify({
            title:
              "The minimum amount for this coupon is ₹" +
              this.$parsePriceToString(coupon.min_amount_for_discount),
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
          return false;
        }
      } else {
        this.$notify({
          title: "This coupon has expired",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
        return false;
      }
    },
    maxCouponPerUser(coupon) {
      let count = 0;

      for (let orderItem of this.order_items) {
        if (
          coupon.discount_code === orderItem.order.discount_code.discount_code
        ) {
          count += 1;
        } else {
          continue;
        }
      }
      if (count >= coupon.max_usage_per_user) {
        return (
          false,
          this.$notify({
            title:
              "You cannot use this coupon more than" +
              coupon.max_usage_per_user.toString() +
              " times",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          })
        );
      } else {
        return true;
      }
    },
    applyCoupon() {
      if (this.selectedCoupon) {
        if (
          this.checkCouponExpiryDateAndMaxDiscountAmount(this.selectedCoupon) &&
          this.applicableProducts(this.selectedCoupon)
        ) {
          let totalDiscount = 0;

          let discountCode = this.selectedCoupon;

          if (discountCode.discount_percentage) {
            let discountAmount = 0;
            for (let orderItem of this.order_items) {
              let itemPrice = 0;
              if (orderItem.customizedPrice) {
                itemPrice = orderItem.customizedPrice * orderItem.quantity;
              } else {
                itemPrice = orderItem.price * orderItem.quantity;
              }
              discountAmount +=
                (itemPrice * discountCode.discount_percentage) / 100;
            }
            if (discountAmount <= this.selectedCoupon.max_discount_amount) {
              totalDiscount = discountAmount;
            } else {
              totalDiscount = this.selectedCoupon.max_discount_amount;
            }
          } else {
            let discountPrice =
              this.calculateSubTotal() - discountCode.discount_amount;

            if (discountPrice <= this.selectedCoupon.max_discount_amount) {
              totalDiscount = discountPrice;
            } else {
              totalDiscount = this.selectedCoupon.max_discount_amount;
            }
          }
          this.count += 1;
          this.savings = totalDiscount;
          this.totalPrice = this.calculateSubTotal() - totalDiscount;
          this.appliedCoupon = this.selectedCoupon;

          sessionStorage.setItem(
            "appliedCoupon",
            JSON.stringify(this.appliedCoupon)
          );
          this.$store.commit("updateAppliedCoupon", this.appliedCoupon);

          sessionStorage.setItem("discountedTotal", this.totalPrice);
          this.$store.commit("updateDiscountedTotal", this.totalPrice);

          this.$notify({
            title: "Coupon code applied successfully",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
        }
      } else {
        this.$notify({
          title: "No coupon code selected",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
      }
    },
    applyCouponOnMount(coupon) {
      if (
        this.checkCouponExpiryDateAndMaxDiscountAmount(coupon) &&
        this.applicableProducts(coupon)
      ) {
        let totalDiscount = 0;

        let discountCode = coupon;

        if (discountCode.discount_percentage) {
          let discountAmount = 0;
          for (let orderItem of this.order_items) {
            let itemPrice = 0;
            if (orderItem.customizedPrice) {
              itemPrice = orderItem.customizedPrice * orderItem.quantity;
            } else {
              itemPrice = orderItem.price * orderItem.quantity;
            }
            discountAmount +=
              (itemPrice * discountCode.discount_percentage) / 100;
          }
          if (discountAmount <= coupon.max_discount_amount) {
            totalDiscount = discountAmount;
          } else {
            totalDiscount = coupon.max_discount_amount;
          }
        } else {
          let discountPrice =
            this.calculateSubTotal() - discountCode.discount_amount;

          if (discountPrice <= coupon.max_discount_amount) {
            totalDiscount = discountPrice;
          } else {
            totalDiscount = coupon.max_discount_amount;
          }
        }
        this.count += 1;
        this.savings = totalDiscount;
        this.totalPrice = this.calculateSubTotal() - totalDiscount;
        this.appliedCoupon = coupon;

        sessionStorage.setItem(
          "appliedCoupon",
          JSON.stringify(this.appliedCoupon)
        );
        this.$store.commit("updateAppliedCoupon", this.appliedCoupon);

        sessionStorage.setItem("discountedTotal", this.totalPrice);
        this.$store.commit("updateDiscountedTotal", this.totalPrice);
      }
    },
    async getCart() {
      if (this.$store.state.user.isAuthenticated) {
        this.$store.commit("setIsLoading", true);

        await axiosInstance
          .get("orders/get-cart/")
          .then((response) => {
            this.order_items = response.data.cart.order_items;
            this.totalPrice = response.data.cart.total_amount;
            console.log("Orders Data :", this.order_items);
          })
          .catch((error) => {
            console.log(error);
          });

        this.$store.commit("setIsLoading", false);
      } else {
        this.order_items = this.$store.state.cart;
        if (this.$store.state.appliedCoupon) {
          console.log("coupon found ");
          this.applyCouponOnMount(this.$store.state.appliedCoupon);
        } else {
          this.totalPrice = this.calculateSubTotal();
        }
      }
    },

    calculateSubTotal() {
      console.log(this.order_items);
      let subTotal = 0;
      for (const orderItem of this.order_items) {
        if (orderItem.customizedPrice) {
          subTotal += orderItem.customizedPrice * orderItem.quantity;
        } else {
          subTotal += orderItem.price * orderItem.quantity;
        }
      }
      this.subTotal = subTotal;
      if (subTotal <= 0) {
        this.totalPrice = 0;
      }
      return subTotal;
    },
    listDiscountCode() {
      axiosInstance
        .get("orders/list-discount-codes/")
        .then((response) => {
          this.discountCodes = response.data;
          console.log("DiscountCodes :", this.discountCodes);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleDeleteItem(orderItemID) {
      const indexToRemove = this.order_items.findIndex(
        (item) => item.order_item_id === orderItemID
      );
      if (indexToRemove !== -1) {
        this.order_items.splice(indexToRemove, 1);
      }

      if (this.$store.state.user.isAuthenticated) {
        localStorage.setItem("userCart", JSON.stringify(this.order_items));
        this.$store.commit("updateUserCart", this.order_items);
      }
      sessionStorage.setItem("cart", JSON.stringify(this.order_items));
      this.$store.commit("updateCart", this.order_items);
      if (this.appliedCoupon) {
        this.applyCouponOnMount(this.appliedCoupon);
      }
      this.totalPrice = this.calculateSubTotal() - this.savings;
    },
    handleCartUpdated(orderItem) {
      let item = this.order_items.find(
        (item) => item.order_item_id == orderItem.id
      );
      console.log("order item", item);
      if (item) {
        item.quantity = orderItem.quantity;
      }
      this.calculateSubTotal();
      if (this.appliedCoupon) {
        this.applyCouponOnMount(this.appliedCoupon);
      }
      this.totalPrice = this.calculateSubTotal() - this.savings;
    },
  },
};
</script>
<style>
.selected-coupon {
  background-color: #f0f0f0; /* Change the color to your desired shade of grey */
  cursor: pointer;
}
</style>
