<template>
  <div class="container">
    <div
      v-for="review in paginatedReviews"
      :key="review.product_review_id"
      class="review-card"
    >
      <div class="user-comment">
        <div style="display: flex; align-items: left">
          <p class="user-name" style="margin-right: 2cap">
            {{ review.user.first_name }} {{ review.user.last_name }}
          </p>
          <p
            class="star-rating"
            v-html="generateStarRating(review.rating.display_order)"
          ></p>
        </div>
        <p class="comment-text">{{ review.comment }}</p>
      </div>

      <p class="comment-date">{{ formatDate(review.created_at) }}</p>

      <div v-if="review && review.images && review.images.length > 0">
        <button
          class="btn btn-outline-dark"
          @click="toggleImages(review.product_review_id)"
        >
          {{ showImages[review.product_review_id] ? "Close" : "View Images" }}
        </button>

        <div
          v-if="
            showImages[review.product_review_id] &&
            review.product_review_id === toggledReviewId
          "
        >
          <div
            class="review-image"
            v-for="image in review.images"
            :key="image.id"
            style="display: inline-block; margin-right: 10px"
          >
            <img
              :src="image.image_file_path"
              alt="Review Image"
              width="100"
              height="100"
            />
          </div>
        </div>
      </div>
    </div>
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" href="#" @click.prevent="prevPage">
          <i class="bi bi-arrow-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        v-for="page in totalPages"
        :key="page"
        :class="{ active: page === currentPage }"
      >
        <a class="page-link" href="#" @click.prevent="currentPage = page">{{
          page
        }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="#" @click.prevent="nextPage">
          <i class="bi bi-arrow-right"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productReviews: [],
      currentPage: 1,
      itemsPerPage: 5,
      showImages: {},
      toggledReviewId: null,
    };
  },
  computed: {
    paginatedReviews() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.productReviews?.slice(startIndex, endIndex);
    },
    totalPages() {
      if (this.productReviews) {
        return Math.ceil(this.productReviews.length / this.itemsPerPage);
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.getProductReview();
  },

  methods: {
    async getProductReview() {
      const productID = this.$route.params.product_id;
      await axiosInstance
        .get(`/products/get_product_review/${productID}/`)
        .then((response) => {
          console.log("product reviewes", response.data.product_reviews);
          this.productReviews = response.data.product_reviews;
          this.reviewImages = response.data.review_images;
          console.log("reviews:", this.productReviews);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleImages(productReviewId) {
      this.showImages[productReviewId] = !this.showImages[productReviewId];
      this.toggledReviewId = this.showImages ? productReviewId : null;
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleString(undefined, options);
    },
    generateStarRating(displayValue) {
      const stars = [];
      for (let i = 0; i < displayValue; i++) {
        stars.push('<i class="bi bi-star-fill"></i>');
      }
      return stars.join(" ");
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
  },
};
</script>
<style scoped>
/* .reviews-container {
  max-width: 800px;
  margin: 0 auto;
} */

.review-card {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.comment-text {
  margin-bottom: 10px;
}

.comment-date {
  color: #555;
  font-size: 0.8rem;
}

.star-rating {
  color: gold;
  font-size: 1rem;
}
</style>
