<template>
  <ag-grid-vue
    style="height: 500px"
    class="ag-theme-quartz"
    :rowData="formattedOrderList"
    :columnDefs="colDefs"
    :defaultColDef="defaultColDef"
    :quickFilterText="filterText"
    @firstDataRendered="onFirstDataRendered"
  >
  </ag-grid-vue>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridVue } from "ag-grid-vue3";

export default {
  components: {
    AgGridVue,
  },
  props: {
    orderList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formattedOrderList() {
      return this.orderList.map((order) => ({
        ORDER: order.order_number,
        DATE: this.formatDate(order.created_at),
        CUSTOMER_EMAIL: order.user_account.email,
        CUSTOMER_NAME: `${order.user_account.first_name} ${order.user_account.last_name}`,
        PAYMENT: order.total_amount,
        STATUS: order.fulfillment_status,
        order_id: order.order_id,
      }));
    },
  },
  data() {
    return {
      statusSummary: {
        delivered: 0,
        shipped: 0,
        confirmed: 0,
        failed: 0,
      },
      filterText: "",
      colDefs: [
        { field: "ORDER" },
        { field: "DATE" },
        { field: "CUSTOMER_EMAIL", headerName: "CUSTOMER EMAIL", filter: true },
        { field: "CUSTOMER_NAME", headerName: "CUSTOMER NAME", filter: true },
        { field: "PAYMENT" },
        { field: "STATUS" },
        {
          headerName: "Details",
          cellRenderer: (params) => {
            const route = {
              name: "AdminOrderDetails",
              params: { order_id: params.data.order_id },
            };
            const icon = document.createElement("i");
            icon.className = "fas fa-external-link-alt";
            icon.style.cursor = "pointer";
            icon.style.border = "1px solid #ccc";
            icon.style.borderRadius = "5px";
            icon.style.padding = "5px";
            icon.style.marginRight = "5px";
            icon.style.color = "#0066cc";

            icon.addEventListener("click", (e) => {
              e.stopPropagation();
              this.$router.push(route);
            });

            return icon;
          },
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
      },
      gridApi: null,
    };
  },
  watch: {},
  methods: {
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    onFirstDataRendered(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener(
        "filterChanged",
        this.calculateStatusSummary
      );
      this.calculateStatusSummary();
    },
    calculateStatusSummary() {
      if (this.gridApi) {
        const summary = {
          delivered: 0,
          shipped: 0,
          confirmed: 0,
          failed: 0,
        };
        const displayedRows = this.gridApi.getModel().rowsToDisplay;

        displayedRows.forEach((row) => {
          const order = row.data;
          switch (order.STATUS) {
            case "Delivered":
              summary.delivered++;
              break;
            case "Shipped":
              summary.shipped++;
              break;
            case "Confirmed":
              summary.confirmed++;
              break;
            case "Failed":
              summary.failed++;
              break;
            default:
              break;
          }
        });

        this.statusSummary = summary;
        this.$emit("updatedStatusSummary", this.statusSummary);
        console.log("Status Summary:", this.statusSummary);
      } else {
        console.warn("Grid API is not initialized yet.");
      }
    },
  },
};
</script>
