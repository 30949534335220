<template>
  <form>
    <div class="mb-3">
      <label for="first_name" class="form-label">First Name</label>
      <input
        type="text"
        class="form-control"
        id="first_name"
        v-model="profile.first_name"
      />
    </div>
    <div class="mb-3">
      <label for="last_name" class="form-label">Last Name</label>
      <input
        type="text"
        class="form-control"
        id="last_name"
        v-model="profile.last_name"
      />
    </div>

    <div class="mb-3">
      <label for="phone_number" class="form-label">Phone Number</label>
      <input
        type="text"
        class="form-control"
        id="phone_number"
        v-model="profile.phone_number"
      />
    </div>
  </form>
</template>

<script>
export default {
  props: {
    modelValue: Object,
  },
  data() {
    return {
      profile: { ...this.modelValue },
    };
  },
  watch: {
    profile: {
      handler(newVal) {
        this.$emit("update:modelValue", newVal);
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {},
  components: {},
};
</script>
