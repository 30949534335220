<template>
  <div class="container">
    <div class="row align-items-center py-2">
      <div class="col md-6">
        <div class="d-flex align-items-center">
          <h2 class="mb-0">User Detail</h2>
          <span class="ms-3 fs-4 text-muted"> | </span>
          <nav
            style="--bs-breadcrumb-divider: '>'"
            aria-label="breadcrumb"
            class="d-inline-block ms-3"
          >
            <ol class="breadcrumb m-0 p-0">
              <li class="breadcrumb-item">
                <a class="text-secondary" href="/admin">
                  <i class="bi bi-house"></i>
                  Home
                </a>
              </li>
              <li class="breadcrumb-item">
                <a class="text-secondary" href="/admin/user-details">
                  <i class="bi bi-list-ul"></i>
                  User List
                </a>
              </li>
              <li class="breadcrumb-item">
                <a class="text-secondary" href="#">
                  <b>
                    <i class="bi bi-person-vcard"></i>
                    User Detail
                  </b>
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h6 class="fs-5 mb-3">Customer Details:</h6>
            <div v-if="orderItems.length > 0">
              <p><b>Total Orders: </b> {{ orderItems.length }}</p>
              <p>
                <b>Name:</b> {{ orderItems[0].user_account.first_name }}
                {{ orderItems[0].user_account.last_name }}
              </p>
              <p><b>Email:</b> {{ orderItems[0].user_account.email }}</p>
              <p>
                <b>Status:</b>
                {{ orderItems[0].is_active === 1 ? "Active" : "Inactive" }}
              </p>
              <p>
                <b>Phone Number:</b>
                {{ orderItems[0].user_account.phone_number }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="btn-group mb-2" role="group" aria-label="Basic example">
          <button
            type="button"
            class="btn btn-outline-secondary"
            :class="{ active: currentTab === 'orderDetail' }"
            @click="switchTab('orderDetail')"
          >
            Order Detail
          </button>

          <button
            type="button"
            class="btn btn-outline-secondary ml-1"
            :class="{ active: currentTab === 'userDetail' }"
            @click="switchTab('userDetail')"
          >
            User Detail
          </button>
        </div>

        <div v-if="currentTab === 'orderDetail'">
          <ag-grid-vue
            style="width: 100%; height: 400px"
            class="ag-theme-alpine"
            :gridOptions="gridOptions"
            :columnDefs="columnDefs"
            :rowData="orderItems"
          ></ag-grid-vue>
        </div>

        <div v-if="currentTab === 'userDetail'">
          <div class="card row ms-0">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="name" class="form-label">Name</label>
                  <p class="fs-6">
                    {{ profile.first_name }} {{ profile.last_name }}
                  </p>
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label">Email Address</label>
                  <p class="fs-6">
                    {{ profile.email }}
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-4">
                  <label for="phone_number" class="form-label"
                    >Phone Number</label
                  >
                  <p class="fs-6">
                    {{ profile.phone_number }}
                  </p>
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>

                  <p
                    class="btn btn-link text-muted fs-6"
                    data-bs-toggle="modal"
                    data-bs-target="#updatePasswordModal"
                  >
                    update password
                  </p>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-3">
              <button
                class="btn btn-outline-success me-2"
                data-bs-toggle="modal"
                data-bs-target="#editProfileModal"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal"
    id="orderDetailModal"
    tabindex="-1"
    aria-labelledby="orderDetailModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="orderDetailModalLabel">
            List of OrderItems
          </h3>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Product</th>
                  <th scope="col"></th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="orderItem in orderItem.order_items"
                  :key="orderItem.order_item_id"
                  style="border-bottom: white"
                >
                  <td>
                    <div class="row">
                      <div class="col">
                        <img
                          :src="orderItem.product.product_image_path"
                          aria-label="Product Image"
                          style="max-width: 50px; max-height: 50px"
                        />
                      </div>
                      <div class="col">
                        <p>{{ orderItem.product.name }}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div
                        style="width: 600px"
                        class="accordion"
                        :id="
                          'accordionExampleTechSpecs' + orderItem.order_item_id
                        "
                      >
                        <div
                          class="accordion-item"
                          v-for="(
                            customizationOption, index
                          ) in orderItem.order_item_customization_options"
                          :key="index"
                        >
                          <h2
                            class="accordion-header"
                            :id="
                              'headingTechSpecs' +
                              orderItem.order_item_id +
                              '-' +
                              index
                            "
                          >
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="
                                '#collapseTechSpecs' +
                                orderItem.order_item_id +
                                '-' +
                                index
                              "
                              aria-expanded="true"
                              :aria-controls="
                                'collapseTechSpecs' +
                                orderItem.order_item_id +
                                '-' +
                                index
                              "
                            >
                              <span class="accordion-focus">
                                <b>Tech Specs</b>
                              </span>
                            </button>
                          </h2>
                          <div
                            :id="
                              'collapseTechSpecs' +
                              orderItem.order_item_id +
                              '-' +
                              index
                            "
                            class="accordion-collapse collapse"
                            :aria-labelledby="
                              'headingTechSpecs' +
                              orderItem.order_item_id +
                              '-' +
                              index
                            "
                            :data-bs-parent="
                              '#accordionExampleTechSpecs' +
                              orderItem.order_item_id
                            "
                          >
                            <div class="accordion-body">
                              <div class="bg-light">
                                <b
                                  v-if="
                                    customizationOption.customization_option
                                      .customization_category_hierarchy == 3
                                  "
                                >
                                  {{
                                    customizationOption.customization_option
                                      .customization_category
                                      .parent_customization_category.name
                                  }}
                                </b>
                                <b v-else>
                                  {{
                                    customizationOption.customization_option
                                      .customization_category.name
                                  }}
                                </b>
                                <ul>
                                  <li>
                                    <b
                                      v-if="
                                        customizationOption.customization_option
                                          .customization_category_hierarchy == 3
                                      "
                                    >
                                      {{
                                        customizationOption.customization_option
                                          .customization_category.name
                                      }}
                                    </b>
                                    {{
                                      customizationOption.customization_option
                                        .name
                                    }}
                                    <span
                                      v-if="
                                        customizationOption.selected_quantity >
                                        1
                                      "
                                    >
                                      ( Qty:
                                      {{
                                        customizationOption.selected_quantity
                                      }})
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {{ orderItem.quantity }}
                    <div class="container" style="margin-top: 5px"></div>
                  </td>
                  <td>{{ orderItem.product.price_with_tax }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="orderbillsDetail"
    tabindex="-1"
    aria-labelledby="orderbillsDetailLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="orderbillsDetailLabel">
            Order Bill Details
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-for="(orderBill, index) in orderItem.order_bills" :key="index">
            <div>
              <!-- <p>
                <strong>Order Bill ID:</strong> {{ orderBill.order_bill_id }}
              </p> -->
              <p>
                <strong>Billed To:</strong>
                {{ orderBill.billed_to.first_name }}
                {{ orderBill.billed_to.last_name }}
              </p>
              <p>
                <strong>Shipping & Handling:</strong>
                {{ orderBill.shipping_handling }}
              </p>
              <p><strong>Tax Amount:</strong> {{ orderBill.tax_amount }}</p>
              <p>
                <strong>Tax Percentage:</strong> {{ orderBill.tax_percentage }}%
              </p>
              <p>
                <strong>Discount Amount:</strong>
                {{ orderBill.discount_amount }}
              </p>
              <p><strong>Subtotal:</strong> {{ orderBill.sub_total }}</p>
              <p><strong>Total:</strong> {{ orderBill.total }}</p>
              <p>
                <strong>Updated At:</strong>
                {{
                  new Date(orderBill.updated_at).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="orderaddressDetail"
    tabindex="-1"
    aria-labelledby="orderaddressDetailLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="orderaddressDetailLabel">
            Order Bill Details
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-for="(orderAddress, index) in orderItem.order_addresses"
            :key="index"
          >
            <div v-if="orderAddress.address_type == 'Shipping'">
              <p>
                <strong>Address Type:</strong> {{ orderAddress.address_type }}
              </p>
              <p><strong>Email:</strong> {{ orderAddress.email }}</p>
              <p><strong>First Name:</strong> {{ orderAddress.first_name }}</p>
              <p><strong>Last Name:</strong> {{ orderAddress.last_name }}</p>

              <p>
                <strong>Phone Number:</strong> {{ orderAddress.phone_number }}
              </p>
              <p>
                <strong>Street Address 1:</strong>
                {{ orderAddress.street_address_1 }}
              </p>
              <p>
                <strong>Street Address 2:</strong>
                {{ orderAddress.street_address_2 }}
              </p>
              <p><strong>City:</strong> {{ orderAddress.city }}</p>
              <p><strong>State:</strong> {{ orderAddress.state }}</p>
              <p>
                <strong>Postal Code:</strong> {{ orderAddress.postal_code }}
              </p>
            </div>
            <div v-if="orderAddress.address_type == 'Billing'">
              <hr />
              <p>
                <strong>Address Type:</strong> {{ orderAddress.address_type }}
              </p>
              <p><strong>Email:</strong> {{ orderAddress.email }}</p>
              <p><strong>First Name:</strong> {{ orderAddress.first_name }}</p>
              <p><strong>Last Name:</strong> {{ orderAddress.last_name }}</p>

              <p>
                <strong>Phone Number:</strong> {{ orderAddress.phone_number }}
              </p>
              <p>
                <strong>Street Address 1:</strong>
                {{ orderAddress.street_address_1 }}
              </p>
              <p>
                <strong>Street Address 2:</strong>
                {{ orderAddress.street_address_2 }}
              </p>
              <p><strong>City:</strong> {{ orderAddress.city }}</p>
              <p><strong>State:</strong> {{ orderAddress.state }}</p>
              <p>
                <strong>Postal Code:</strong> {{ orderAddress.postal_code }}
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editProfileModal"
    tabindex="-1"
    aria-labelledby="editProfileModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editProfileModalLabel">Edit Profile</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <!-- <div class="mb-3">
              <label for="profileImage" class="form-label">Profile Image</label>
              <input type="file" class="form-control" id="profileImage" @change="handleImageUpload">
              <img :src="editedProfileImage" alt="Uploaded Image" class="img-fluid mt-2" v-if="editedProfileImage" />
            </div> -->
            <div class="mb-3">
              <label for="first_name" class="form-label">First Name</label>
              <input
                type="text"
                class="form-control"
                id="first_name"
                v-model="editedFirstName"
              />
            </div>
            <div class="mb-3">
              <label for="last_name" class="form-label">Last Name</label>
              <input
                type="text"
                class="form-control"
                id="last_name"
                v-model="editedLastName"
              />
            </div>

            <div class="mb-3">
              <label for="phone_number" class="form-label">Phone Number</label>
              <input
                type="text"
                class="form-control"
                id="phone_number"
                v-model="editedPhoneNumber"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-bs-dismiss="modal"
            @click="cancelEdit"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-outline-success"
            @click="saveChanges"
            data-bs-dismiss="modal"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="updatePasswordModal"
    tabindex="-1"
    aria-labelledby="updatePasswordModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="updatePasswordModalLabel">
            Update Password
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <form>
              <div class="mb-3">
                <label for="oldPassword" class="form-label">Old Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="oldPassword"
                  v-model="oldPassword"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="newPassword" class="form-label">New Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="newPassword"
                  v-model="newPassword"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="confirmPassword" class="form-label"
                  >Confirm New Password</label
                >
                <input
                  type="password"
                  class="form-control"
                  id="confirmPassword"
                  v-model="confirmPassword"
                  required
                />
              </div>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                @click="updatePassword"
              >
                Change Password
              </button>
            </form>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      currentTab: "orderDetail",
      orderItem: {},
      orderItems: [],
      gridOptions: {
        domLayout: "autoHeight",
      },
      columnDefs: [
        { headerName: "Order ID", field: "order_id" },
        { headerName: "Total Amount", field: "total_amount" },
        {
          headerName: "Order Date",
          field: "updated_at",
          valueFormatter: this.formatDate,
        },
        { headerName: "Total Items", field: "order_items.length" },

        {
          headerName: "Order Detail",
          cellRenderer: (params) => {
            const id = params.data.order_id;
            const icon = document.createElement("i");
            icon.className = "fas fa-external-link-alt";
            icon.style.cursor = "pointer";
            icon.style.border = "1px solid #ccc";
            icon.style.borderRadius = "5px";
            icon.style.padding = "5px";
            icon.style.marginRight = "5px";
            icon.style.color = "#0066cc";

            icon.setAttribute("data-bs-toggle", "modal");
            icon.setAttribute("data-bs-target", "#orderDetailModal");

            icon.addEventListener("click", (e) => {
              e.stopPropagation();
              this.getOrderItem(id);
            });

            return icon;
          },
        },
        {
          headerName: "Order Bills",
          cellRenderer: (params) => {
            const id = params.data.order_id;

            const icon = document.createElement("i");
            icon.className = "fas fa-external-link-alt";
            icon.style.cursor = "pointer";
            icon.style.border = "1px solid #ccc";
            icon.style.borderRadius = "5px";
            icon.style.padding = "5px";
            icon.style.marginRight = "5px";
            icon.style.color = "#0066cc";

            icon.setAttribute("data-bs-toggle", "modal");
            icon.setAttribute("data-bs-target", "#orderbillsDetail");

            icon.addEventListener("click", (e) => {
              e.stopPropagation();
              this.getOrderItem(id);
            });

            return icon;
          },
        },
        {
          headerName: "Order Address",
          cellRenderer: (params) => {
            const id = params.data.order_id;

            const icon = document.createElement("i");
            icon.className = "fas fa-external-link-alt";
            icon.style.cursor = "pointer";
            icon.style.border = "1px solid #ccc";
            icon.style.borderRadius = "5px";
            icon.style.padding = "5px";
            icon.style.marginRight = "5px";
            icon.style.color = "#0066cc";

            icon.setAttribute("data-bs-toggle", "modal");
            icon.setAttribute("data-bs-target", "#orderaddressDetail");

            icon.addEventListener("click", (e) => {
              e.stopPropagation();
              this.getOrderItem(id);
            });

            return icon;
          },
        },
      ],
      profile: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
      },
      editedFirstName: "",
      editedLastName: "",
      editedPhoneNumber: "",
      editedPassword: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  },

  methods: {
    switchTab(tab) {
      this.currentTab = tab;
    },
    formatDate(params) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(params.value).toLocaleDateString(undefined, options);
    },
    getOrderItem(orderId) {
      console.log(orderId);
      axiosInstance
        .get(`orders/get_orders_byId/${orderId}/`)
        .then((response) => {
          this.orderItem = response.data;
          console.log("orderItems:", this.orderItem);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllOrders() {
      const userId = this.$route.params.id;
      console.log(userId);
      axiosInstance
        .get(`orders/get-user-orders/${userId}/`)
        .then((response) => {
          this.orderItems = response.data;
          this.profile.first_name = this.orderItems[0].user_account.first_name;
          this.profile.last_name = this.orderItems[0].user_account.last_name;
          this.profile.email = this.orderItems[0].user_account.email;
          this.profile.phone_number =
            this.orderItems[0].user_account.phone_number;
          this.editedFirstName = this.profile.first_name;
          this.editedLastName = this.profile.last_name;
          this.editedPhoneNumber = this.profile.phone_number;

          console.log("orderItems:", this.orderItems);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async saveChanges() {
      try {
        const userId = this.$route.params.id;
        const response = await axiosInstance.put(
          `/accounts/update-user-account/${userId}/`,
          {
            phone_number: this.editedPhoneNumber,
            first_name: this.editedFirstName,
            last_name: this.editedLastName,
          }
        );
        this.profile = response.data.user;
        window.location.reload();
        this.$notify({
          title: "Profile Setting updated successfully",
          type: "bg-success-subtle text-success",
          duration: "5000",
        });
      } catch (error) {
        console.error("Error updating user details:", error);
      }
    },

    updatePassword() {
      const userId = this.$route.params.id;

      if (this.newPassword !== this.confirmPassword) {
        console.error("New password and confirm password do not match");
        return;
      }
      axiosInstance
        .put(`accounts/update-user-password/${userId}/`, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then((response) => {
          console.log(response.data.detail);
        })
        .catch((error) => {
          if (error.response) {
            console.error("Error response:", error.response.data.detail);
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Error setting up the request:", error.message);
          }
        });
    },
  },
  mounted() {
    this.getAllOrders();
  },
};
</script>
<style scoped>
.breadcrumb {
  background-color: whitesmoke;
}
</style>
