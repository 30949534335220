<template>
  <div>dcsdiugyfxdxdutdryuvhbkj</div>
</template>

<script>
export default {
  data() {},
  mounted() {},
  components: {},
  computed: {},
  methods: {},
};
</script>
