<template>
  <div class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/"
            ><i class="bi bi-house"></i> Home</a
          >
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <a class="text-secondary" href="#">
            <b> <i class="bi bi-person-plus"></i> Create Account</b>
          </a>
        </li>
      </ol>
    </nav>
    <p class="h1">Create Account</p>
    <hr />
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-lg-12 col-xl-11">
        <div class="card border-0" style="border-radius: 25px">
          <div class="card-body md-5">
            <div class="row justify-content-center">
              <div class="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                <form
                  class="mx-1 mx-md-4 needs-validation"
                  @submit="submitForm"
                  novalidate
                >
                  <div class="mb-3">
                    <div class="input-group">
                      <span class="input-group-text">
                        <i class="bi bi-person-fill"></i>
                      </span>
                      <input
                        v-model="signUpForm.firstName"
                        type="text"
                        id="first_name"
                        class="form-control"
                        placeholder="First Name"
                        required
                      />
                      <div class="invalid-feedback">
                        Please provide your first name.
                      </div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="input-group">
                      <span class="input-group-text">
                        <i class="bi bi-person-fill"></i>
                      </span>
                      <input
                        v-model="signUpForm.lastName"
                        type="text"
                        id="last_name"
                        class="form-control"
                        placeholder="Last Name"
                        required
                      />
                      <div class="invalid-feedback">
                        Please provide your last name.
                      </div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="input-group">
                      <span class="input-group-text">
                        <i class="bi bi-envelope"></i>
                      </span>
                      <input
                        v-model="signUpForm.email"
                        type="email"
                        id="email"
                        class="form-control"
                        placeholder="Your Email"
                        required
                      />
                      <div class="invalid-feedback">
                        Please provide a valid email address.
                      </div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="input-group">
                      <span class="input-group-text">
                        <i class="bi bi-lock-fill"></i>
                      </span>
                      <input
                        v-model="signUpForm.password"
                        type="password"
                        id="password"
                        class="form-control"
                        placeholder="Password"
                        required
                      />
                      <div class="invalid-feedback">Password is required.</div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="input-group">
                      <span class="input-group-text">
                        <i class="bi bi-key-fill"></i>
                      </span>
                      <input
                        v-model="signUpForm.repeatPassword"
                        type="password"
                        id="repeat_pass"
                        class="form-control"
                        placeholder="Repeat Password"
                        required
                      />
                      <div
                        :class="{
                          'd-block':
                            this.signUpForm.password !=
                            this.signUpForm.repeatPassword,
                        }"
                        class="invalid-feedback"
                      >
                        Passwords must match.
                      </div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="form-check">
                      <input
                        class="form-check-input me-2"
                        type="checkbox"
                        v-model="signUpForm.agreeTerms"
                        id="terms"
                        required
                      />
                      <label class="form-check-label" for="terms">
                        I agree to all statements in
                        <a href="#!">Terms of service</a>
                      </label>
                      <div class="invalid-feedback">
                        You must agree to the terms.
                      </div>
                    </div>
                  </div>
                  <div class="buttons">
                    <button type="submit" class="btn btn-outline-success">
                      Register
                    </button>
                  </div>
                </form>
                <div class="d-flex mt-4">
                  <p class="text-muted mb-0">
                    Have an account already? <a href="/sign-in"> Login here</a>
                  </p>
                </div>
              </div>
              <div
                class="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2"
              >
                <img
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                  class="img-fluid"
                  alt="Sample image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      signUpForm: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repeatPassword: "",
        agreeTerms: false,
      },
    };
  },
  mounted() {
    const forms = document.querySelectorAll(".needs-validation");
    Array.from(forms).forEach((form) => {
      form.addEventListener(
        "submit",
        (event) => {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });
  },
  components: {},
  computed: {},
  methods: {
    submitForm(event) {
      event.preventDefault();

      const form = event.target;
      console.log(this.signUpForm);
      if (
        form.checkValidity() &&
        this.signUpForm.password === this.signUpForm.repeatPassword
      ) {
        console.log(this.signUpForm);
        this.signUp();
      } else {
        form.classList.add("was-validated");
      }
    },
    async signUp() {
      await axiosInstance
        .post("accounts/create-user/", this.signUpForm)
        .then((response) => {
          console.log("response.data");
          console.log(response.data);
          this.$notify({
            title: "Account created Successfuly, Please Log In to continue",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
          this.$router.push("/sign-in");
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error.password[0];
            this.$notify({
              title: errorMessage,
              type: "bg-danger-subtle text-danger",
              duration: "5000",
            });
          } else {
            console.log(error);
            this.$notify({
              title: "Something went wrong. Please try again",
              type: "bg-danger-subtle text-danger",
              duration: "5000",
            });
          }
        });
    },
  },
};
</script>
