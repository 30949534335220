<template>
  <div class="container">
    <h4 class="d-flex justify-content-between align-items-center">
      Select Payment Methods
    </h4>
    <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span v-if="selectedMethod">
          {{ parseInt(selectedMethod.percentage) }}%
          {{ selectedMethod.payment_type }}
        </span>
        <span v-else>Select Payment Method</span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li v-for="(method, index) in payment" :key="index">
          <label class="dropdown-item">
            <input
              type="radio"
              :id="'paymentMethod' + index"
              :value="method"
              v-model="selectedMethod"
            />
            {{ parseInt(method.percentage) }}% {{ method.payment_type }}
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payment: {
      type: Array,
      default: () => [],
    },
    selectedPayment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedMethod: null,
    };
  },
  mounted() {
    // Initially set the selected method to the prop value
    this.selectedMethod = this.selectedPayment;
  },
  methods: {
    emitSelectedPayment() {
      if (this.selectedMethod) {
        this.$emit("payment-selected", this.selectedMethod);
      }
    },
  },
  watch: {
    selectedMethod() {
      this.emitSelectedPayment();
    },
    selectedPayment(newValue) {
      // Update the selected method when the prop changes
      this.selectedMethod = newValue;
    },
  },
};
</script>
