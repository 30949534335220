<template>
  <div class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary" href="#">
            <b>
              <i class="bi bi-person"></i>
              Customers
            </b>
          </a>
        </li>
      </ol>
    </nav>

    <h1 class="d-flex justify-content-between align-items-center">
      <span>Customers</span>
      <a class="btn btn-outline-success" href="/add-customer">Add Customer</a>
    </h1>
    <hr />

    <div id="ag-grid-container">
      <ag-grid-vue
        :gridOptions="gridOptions"
        :rowData="rowData"
        class="ag-theme-quartz"
        @firstDataRendered="onFirstDataRendered"
        @rowDataChanged="onRowDataChanged"
      >
      </ag-grid-vue>
    </div>

    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="deleteModalLabel">Modal title</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete this customer?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              @click="deleteCustomer()"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridVue } from "ag-grid-vue3";

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      gridOptions: {
        columnDefs: [
          { headerName: "Customer Name", field: "company_name", filter: true },
          { headerName: "Customer Email", field: "user.email", filter: true },
          { headerName: "Customer Type", field: "customer_type", filter: true },
          {
            headerName: "Tax Prefrence",
            field: "tax_preference",
            filter: true,
          },
          { headerName: "GST Treatment", field: "gst_treatment", filter: true },
          {
            headerName: "Currency",
            field: "currency",
            valueGetter: function (params) {
              const currency = params.data.currency;
              if (currency) {
                const currencyCode = params.data.currency.currency_code;
                const country = params.data.currency.name;
                return `${country} - ${currencyCode}`;
              } else {
                return " ";
              }
            },
            filter: true,
          },
          {
            headerName: "Payment Terms",
            field: "payment_term",
            valueGetter: function (params) {
              const paymentTerm = params.data.payment_term;
              if (paymentTerm) {
                const percentage = paymentTerm.percentage;
                const type = paymentTerm.payment_type;
                return `${parseInt(percentage)}% ${type}`;
              } else {
                return "null";
              }
            },
            filter: true,
          },
          {
            headerName: "Delete",
            cellRenderer: this.deleteButtonRenderer,

            width: 100,
          },
          {
            headerName: "Edit",
            cellRenderer: this.editButtonRenderer,
            width: 100,
          },
        ],
        domLayout: "autoHeight",
        suppressSizeToFit: true,
      },
      rowData: [],
      customerId: null,
    };
  },
  mounted() {
    this.getAllCustomers();
  },
  methods: {
    async getAllCustomers() {
      await axiosInstance
        .get("accounts/get_all_customers/")
        .then((response) => {
          console.log(response.data);
          this.rowData = response.data.customers;
        })
        .catch((error) => {
          console.error("Error fetching customers:", error);
        });
    },

    deleteButtonRenderer(params) {
      const icon = '<i class="bi bi-trash"></i>';
      const button = document.createElement("button");
      button.className = "btn btn-outline-danger btn-sm";
      button.innerHTML = icon;
      button.setAttribute("data-bs-toggle", "modal"); // Add data-bs-toggle attribute
      button.setAttribute("data-bs-target", "#deleteModal");
      button.addEventListener("click", () => {
        this.customerId = params.data.id;
      });
      return button;
    },
    editButtonRenderer(params) {
      const icon = '<i class="bi bi-pencil-square"></i>';
      const button = document.createElement("button");
      button.className = "btn btn-outline-success btn-sm";
      button.innerHTML = icon;
      button.addEventListener("click", () => this.onEditClick(params));
      return button;
    },

    async deleteCustomer() {
      const customerId = this.customerId;

      try {
        const response = await axiosInstance.delete(
          `/accounts/delete_customer/${customerId}/`
        );
        console.log(response.data.message);
        window.location.reload();
      } catch (error) {
        console.error("Error deleting customer:", error.response.data.message);
      }
    },
    onEditClick(params) {
      console.log("Edit button clicked for row:", params.data);
      const customerId = params.data.id;
      console.log(customerId);
      this.$router.push({
        name: "AddCustomer",
        query: {
          customerId: customerId,
          edit: true,
        },
      });
    },
  },
};
</script>
