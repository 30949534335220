<template>
  <!-- Review text area -->
  <div>
    <div class="form-group row">
      <label for="comment" class="col-sm-2 col-form-label">Review:</label>
      <div class="col-sm-10">
        <textarea
          class="form-control"
          v-model="review.comment"
          placeholder="Write a review...."
          required
        ></textarea>
      </div>
    </div>

    <!-- Rating section -->
    <div class="form-group row" v-if="defaultRatingSystem.name === 'Stars'">
      <label for="comment" class="col-sm-2 col-form-label">Rating:</label>
      <div class="col-sm-10">
        <star-rating
          v-model:rating="review.selectedRating"
          :star-size="30"
          :show-rating="false"
        />
      </div>
    </div>

    <!-- Image section -->
    <div class="form-group row">
      <label for="image" class="col-sm-2 col-form-label">Images:</label>
      <div class="col-sm-10">
        <div class="input-group mb-3">
          <input
            ref="fileInput"
            class="form-control"
            type="file"
            @change="handleFileChange"
            accept="image/*"
            multiple
          />

          <button
            class="btn btn-outline-secondary"
            type="button"
            @click.prevent="clearSelectedImages"
          >
            Clear
          </button>
        </div>

        <!-- Selected images -->
        <div
          v-if="review && review.selectedImages?.length > 0"
          class="mt-3 d-flex justify-content-left selected-images"
        >
          <div
            v-for="(file, index) in review.selectedImages"
            :key="index"
            class="mx-2 position-relative"
          >
            <!-- Cross button to remove the image -->
            <button
              class="btn btn-danger btn-sm position-absolute"
              style="top: -10px; right: -10px"
              @click="removeSelectedImage(index)"
            >
              <i class="bi bi-x"></i>
            </button>
            <img
              :src="file?.image_file_path ? file.image_file_path : file.url"
              alt="Selected Image"
              width="150"
              height="150"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  props: {
    orderItem: {
      type: Object,
      required: true,
    },

    defaultRatingSystem: {
      type: Object,
      required: true,
    },
    productReviews: Array, // Assuming it's an array, change the type if it's different
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ratingValues: [],
      review: {
        ...this.modelValue,
        // selectedImages: [],
        // deletedImages: [],
      },
    };
  },
  mounted() {
    this.fetchProductReviews();
  },
  watch: {
    orderItem: {
      handler(newVal) {
        console.log("review watcher triggered");
        this.fetchProductReviews();
      },
      deep: true,
    },
    review: {
      handler(newVal, oldVal) {
        console.log("review watcher triggered");
        this.$emit("update:modelValue", newVal);
      },
      deep: true,
    },
  },
  methods: {
    setRating(value) {
      this.review.selectedRating = value;
    },
    generateStarRating(displayValue) {
      const stars = [];
      for (let i = 0; i < displayValue; i++) {
        stars.push('<i class="bi bi-star-fill"></i>');
      }
      return stars.join(" ");
    },
    fetchProductReviews() {
      console.log("this.review", this.review);
      if (this.orderItem?.product && this.orderItem?.product?.product_id) {
        const productId = this.orderItem.product.product_id;
        axiosInstance
          .get(`/products/get-product-review/${productId}/`)
          .then((response) => {
            console.log("review found", response.data);
            this.review = { ...response.data };
            this.review.selectedRating = response.data.rating.display_order;
            this.review.selectedImages = response.data.images;
            console.log("Selected iamges", this.review.selectedImages);
            this.$emit("review-found", true);
          })
          .catch((error) => {
            console.error("Error fetching product reviews:", error);
            this.review = { selectedImages: [] };
            this.$emit("review-found", false);
          });
      }
    },
    // handleFileChange(event) {
    //   const files = event.target.files;
    //   for (const file of files) {
    //     this.review.selectedImages.push({
    //       url: URL.createObjectURL(file),
    //       file: file,
    //     });
    //   }
    //   this.$refs.fileInput.value = ""; // Clear the file input
    // },
    // clearSelectedImages() {
    //   this.review.selectedImages = [];
    //   this.$refs.fileInput.value = ""; // Clear the file input
    // },
    // removeSelectedImage(index) {
    //   this.review.selectedImages.splice(index, 1);
    // },
    handleFileChange(event) {
      const files = event.target.files;
      for (const file of files) {
        this.review.selectedImages.push({
          url: URL.createObjectURL(file),
          file: file,
        });
      }
      this.$refs.fileInput.value = ""; // Clear the file input
    },

    clearSelectedImages() {
      this.$emit("clear-images");
    },
    removeSelectedImage(index) {
      console.log(index);
      this.$emit("remove-image", index);
    },
  },
  components: {
    StarRating,
  },
};
</script>

<style scoped>
.rating {
  font-size: 2em;
  display: flex;
  justify-content: flex-end;
}

.rating span {
  display: inline-block;
  padding: 0.1em;
  cursor: pointer;
  color: #000;
}

.rating span:hover ~ span {
  color: black;
}

.rating span.selected {
  color: #ffd700;
}
.selected-images {
  display: flex;
  flex-direction: row;
}
</style>
