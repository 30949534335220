<template>
    <div class="container" style="text-align: left;">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4 class="d-flex justify-content-between align-items-center">
                        Select Shipping Address
                        <button class="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#addAddressModal">
                            <i class="bi bi-plus-lg"></i> Add Address
                        </button>
                    </h4>
                    <div  class="card">
                        <div class="card-header d-flex justify-content-between">
                            <span>Saved Addresses</span>
                        </div>
                        <div class="card-body">
                            <ul class="list-group list-group-flush">
                                <li v-if="addresses.length === 0" class="list-group-item">
                                    No addresses found
                                </li>
                                <li v-else v-for="(address, index) in addresses" :key="index" class="list-group-item">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="address" :id="'address' + index" @change="selectAddress(address)" :checked="address === internalSelectedAddress">
                                        <label class="form-check-label" :for="'address' + index">
                                            {{ address.first_name }} {{ address.last_name }}, {{ address.street_address_1 }}, {{ address.city }}, {{ address.state }}, {{ address.postal_code }}
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="d-flex justify-content-between align-items-center">
            Select Billing Address
        </h4>

        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="sameAddressCheckbox" v-model="sameAddressChecked">
            <label class="form-check-label" for="sameAddressCheckbox">
                Billing address is the same as shipping
            </label>
        </div>

        <div v-if="!sameAddressChecked" class="card">
            <div class="card-header d-flex justify-content-between">
                <span>Saved Addresses</span>
            </div>
            <div class="card-body">
                <ul class="list-group list-group-flush">
                    <li v-if="addresses.length === 0" class="list-group-item">
                        No addresses found
                    </li>
                    <li v-else v-for="(address, index) in addresses" :key="index" class="list-group-item">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="address" :id="'address' + index">
                            <label class="form-check-label" :for="'address' + index">
                                {{ address.first_name }} {{ address.last_name }}, {{ address.street_address_1 }}, {{ address.city }}, {{ address.state }}, {{ address.postal_code }}
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="modal fade" id="addAddressModal" tabindex="-1" role="dialog" aria-labelledby="addAddressModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addAddressModalLabel">
                            Add New Address
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <AddAddressForm />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="button" class="btn btn-success" data-bs-dismiss="modal">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddAddressForm from "@/components/Account/AddAddressForm.vue";

export default {
    props: {
        addresses: {
            type: Array,
            default: () => []
        },
        selectedAddress: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            sameAddressChecked: true,
            internalSelectedAddress: null, 
        };
    },
    components: {
        AddAddressForm,
    },
    mounted() {
        this.internalSelectedAddress = this.selectedAddress; 
    },
    watch: {
        selectedAddress(newValue) {
            // Update internalSelectedAddress when selectedAddress changes
            this.internalSelectedAddress = newValue;
        }
    },
    methods: {
        selectAddress(address) {
            this.internalSelectedAddress = address; // Update internalSelectedAddress when an address is selected
            this.$emit('address-selected', address);
        },
    },
};
</script>
