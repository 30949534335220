<template>
  <ag-grid-vue
    :gridOptions="gridOptions"
    :rowData="rowData"
    class="ag-theme-quartz"
    @firstDataRendered="onFirstDataRendered"
    @rowDataChanged="onRowDataChanged"
  >
  </ag-grid-vue>
  <button class="btn btn-sm btn-outline-secondary mt-2" @click="addNewRow()">
    Add Contact Person
  </button>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

export default {
  components: {
    AgGridVue,
  },
  props: {
    contactPersons: {
      type: Array,
      required: false,
    },
  },
  mounted() {
    this.rowData = this.contactPersons || [];
  },
  data() {
    return {
      gridOptions: {
        columnDefs: [
          { headerName: "First Name", field: "firstName", editable: true },
          { headerName: "Last Name", field: "lastName", editable: true },
          {
            headerName: "Email Address",
            field: "emailAddress",
            editable: true,
          },
          { headerName: "Phone Number", field: "phoneNumber", editable: true },
          {
            headerName: "Mobile Number",
            field: "mobileNumber",
            editable: true,
          },
          { headerName: "Skype Name/Number", field: "skype", editable: true },
          { headerName: "Designation", field: "designation", editable: true },
          { headerName: "Department", field: "department", editable: true },
        ],
        domLayout: "autoHeight",
        suppressSizeToFit: true,
      },
      gridApi: null,
      rowData: [
        {
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          mobileNumber: "",
          skype: "",
          designation: "",
          department: "",
        },
      ],
    };
  },
  mounted() {
    // this.onRowDataChanged();
    if (this.edit) {
      this.createRowDataFromPerson();
    }
  },
  props: {
    contactPersonData: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    addNewRow() {
      console.log("called");
      this.rowData.push({
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        mobileNumber: "",
        skype: "",
        designation: "",
        department: "",
      });
      console.log("rowData", this.rowData);
      if (this.gridApi) {
        this.gridApi.setRowData(this.rowData);
      }
      this.emitRowData();
    },
    deleteRow(index) {
      this.rowData.splice(index, 1);
      this.emitRowData();
    },
    emitRowData() {
      this.$emit("contactPersonsUpdated", this.rowData);
    },
    onFirstDataRendered(params) {
      this.gridApi = params.api;
      console.log("grd api");
      console.log(this.gridApi);
    },
    onRowDataChanged(params) {
      params.api.sizeColumnsToFit();
    },
    createRowDataFromPerson() {
      this.rowData = this.contactPersonData.map((person) => ({
        firstName: person.first_name,
        lastName: person.last_name,
        emailAddress: person.email,
        phoneNumber: person.work_phone,
        mobileNumber: person.mobile,
        skype: person.skype_name_number,
        designation: person.designation,
        department: person.department,
      }));
    },
    createRowDataFromPerson() {
      this.rowData = this.contactPersonData.map((person) => ({
        firstName: person.first_name,
        lastName: person.last_name,
        emailAddress: person.email,
        phoneNumber: person.work_phone,
        mobileNumber: person.mobile,
        skype: person.skype_name_number,
        designation: person.designation,
        department: person.department,
      }));
    },
  },
};
</script>
