// // export function isTokenExpired(token) {
// //   try {
// //     const tokenParts = token.split('.');
// //     if (tokenParts.length !== 3) {
// //       // Invalid token format
// //       return true;
// //     }

// //     const payload = JSON.parse(atob(tokenParts[1]));
// //     const currentTime = Math.floor(Date.now() / 1000);

// //     if (!payload.exp || typeof payload.exp !== 'number') {
// //       // Missing or invalid "exp" claim
// //       return true;
// //     }

// //     return payload.exp < currentTime;
// //   } catch (error) {
// //     return true;
// //   }
// // }

// // export function isTokenExpired(token) {
// //   return Date.now() >= JSON.parse(atob(token.split(".")[1])).exp * 1000;
// // }

import { jwtDecode } from "jwt-decode";

export function isTokenExpired(token) {
  const decodedToken = jwtDecode(token);
  console.log("decoded token", decodedToken);

  // The expiration time from the token is in seconds since the Unix epoch
  const expirationTimeInSeconds = decodedToken.exp;
  console.log("Expiration Time (in seconds):", expirationTimeInSeconds);

  // Get the current time in seconds since the Unix epoch
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  console.log("Current Time (in seconds):", currentTimeInSeconds);

  // Check if the current time is greater than or equal to the expiration time
  const isTokenExpired = currentTimeInSeconds >= expirationTimeInSeconds;
  console.log("Is token expired:", isTokenExpired);

  return isTokenExpired;
}
