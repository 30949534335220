<template>
  <ul class="list">
    <li
      v-for="(item, index) in timelineArray"
      :key="index"
      class="list-item mb-5"
    >
      <h5 class="fw-bold">{{ item.title }}</h5>
      <p class="text-muted mb-2 fw-bold">{{ item.date }}</p>
      <p class="text-muted">{{ item.description }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    timelineArray: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style>
.list {
  border-left: 2px solid #007bff;
  list-style: none;
  padding-left: 30px;
  position: relative;
}

.list .list-item {
  position: relative;
}

.list .list-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.list .list-item:after {
  position: absolute;
  display: block;
  top: 0;
  left: -38px;
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #ffffff 100%, transparent 50%);
  border: 2px solid #007bff;
}
</style>
