<template>
  <div class="container">
    <div class="row align-items-center py-2">
      <div class="col md-6">
        <div class="d-flex align-items-center">
          <h2 class="mb-0">User List</h2>
          <span class="ms-3 fs-4 text-muted"> | </span>
          <nav
            style="--bs-breadcrumb-divider: '>'"
            aria-label="breadcrumb"
            class="d-inline-block ms-3"
          >
            <ol class="breadcrumb m-0 p-0">
              <li class="breadcrumb-item">
                <a class="text-secondary" href="/admin">
                  <i class="bi bi-house"></i>
                  Home
                </a>
              </li>
              <li class="breadcrumb-item">
                <a class="text-secondary" href="/admin/user-details">
                  <b>
                    <i class="bi bi-list-ul"></i>
                    User List
                  </b>
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <input
          type="text"
          placeholder="Filter by name or email"
          style="margin-left: 5%"
          v-model="filterText"
        />
      </div>
      <div class="col-md-6 text-right">
        <button
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#addUserModal"
        >
          Add User
        </button>
        <span style="margin-right: 10px"></span>
      </div>
    </div>
    <br />
    <div class="container-wrapper">
      <ag-grid-vue
        style="height: 500px"
        class="ag-theme-quartz"
        :rowData="formattedUserList"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :quickFilterText="filterText"
      ></ag-grid-vue>
    </div>
  </div>
  <div
    class="modal fade"
    id="addUserModal"
    tabindex="-1"
    aria-labelledby="addUserModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addUserModalLabel">Create User</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="mx-1 mx-md-4 needs-validation" novalidate>
            <div>
              <i class="fas fa-user fa-lg me-3 fa-fw"></i>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="form-floating">
                      <input
                        v-model="signUpForm.firstName"
                        type="text"
                        id="first_name"
                        class="form-control"
                        required
                      />
                      <label class="form-label" for="first_name"
                        >First Name</label
                      >
                      <div class="invalid-feedback">
                        Please provide your first name.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="form-floating">
                      <input
                        v-model="signUpForm.lastName"
                        type="text"
                        class="form-control"
                      />
                      <label class="form-label">Last Name</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <i class="fas fa-envelope fa-lg me-3 fa-fw"></i>
              <div class="form-floating mb-3">
                <input
                  v-model="signUpForm.email"
                  type="email"
                  id="email"
                  class="form-control"
                  required
                />
                <label class="form-label" for="email">Your Email</label>
                <div class="invalid-feedback">
                  Please provide a valid email address.
                </div>
              </div>
            </div>

            <div class="mb-3 form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="signUpForm.generatePassword"
                id="generatePassword"
              />
              <label class="form-check-label" for="generatePassword">
                Generate Auto-generated Password
              </label>
              <small class="text-muted ml-2">
                (Uncheck to create password manually)
              </small>
            </div>

            <div v-if="!signUpForm.generatePassword">
              <div class="mb-3">
                <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                <div class="form-floating mb-3">
                  <input
                    v-model="signUpForm.password"
                    type="password"
                    id="password"
                    class="form-control"
                    required
                  />
                  <label class="form-label" for="password" autocomplete="off"
                    >Password</label
                  >
                  <div class="invalid-feedback">Password is required.</div>
                </div>
              </div>

              <div class="mb-4">
                <i class="fas fa-key fa-lg me-3 fa-fw"></i>
                <div class="form-floating mb-3">
                  <input
                    v-model="signUpForm.repeatPassword"
                    type="password"
                    id="repeat_pass"
                    class="form-control"
                    required
                  />
                  <label class="form-label" for="repeat_pass"
                    >Repeat your password</label
                  >
                  <div
                    :class="{
                      'd-block':
                        signUpForm.password !== signUpForm.repeatPassword,
                    }"
                    class="invalid-feedback"
                  >
                    Passwords must match.
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-4">
              <div class="form-check">
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  v-model="signUpForm.agreeTerms"
                  id="terms"
                  required
                />
                <label class="form-check-label" for="terms">
                  I agree to all statements in <a href="#!">Terms of service</a>
                </label>
                <div class="invalid-feedback">You must agree to the terms.</div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="submitForm"
            data-bs-dismiss="modal"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      filterText: "",
      UserDetail: [],
      gridOptions: {
        domLayout: "autoHeight",
      },
      columnDefs: [
        {
          field: "USER_NAME",
          headerName: "User Name",
          filter: true,
          cellRenderer: (params) => {
            const userId = params.data.id;
            const userName = params.value;

            const link = document.createElement("a");
            link.href = "#";
            link.textContent = userName;

            link.addEventListener("click", (e) => {
              e.stopPropagation();
              const route = {
                name: "AdminUserOrdersDetail",
                params: { id: userId },
              };
              this.$router.push(route);
            });

            return link;
          },
        },
        { field: "USER_EMAIL", headerName: "User Email", filter: true },
        // { field: "ROLE", headerName: "Role" },
        { field: "STATUS", headerName: "Status" },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
      },
      signUpForm: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repeatPassword: "",
        generatePassword: true,
        agreeTerms: false,
      },
    };
  },
  computed: {
    formattedUserList() {
      return this.UserDetail.map((user) => ({
        USER_NAME: `${user.first_name} ${user.last_name}`,
        USER_EMAIL: user.email,
        ROLE: user.user_role,
        STATUS: user.is_active === 1 ? "Active" : "Inactive",
        id: user.id,
      }));
    },
  },

  methods: {
    getUsersDetail() {
      axiosInstance
        .get("accounts/get-all-users/")
        .then((response) => {
          this.UserDetail = response.data.users;
          console.log("userDetail:", this.UserDetail);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitForm() {
      const generatedPassword = this.signUpForm.generatePassword
        ? Math.random().toString(36).slice(-8)
        : this.signUpForm.password;

      const requestData = {
        email: this.signUpForm.email,
        password: generatedPassword,
        firstName: this.signUpForm.firstName,
        lastName: this.signUpForm.lastName,
        updated_by: this.$store.state.user.email,
      };

      axiosInstance
        .post("accounts/create-user/", requestData)
        .then((response) => {
          console.log("Account created:", response.data);
          window.location.reload();
          this.$notify({
            title:
              "Guest Account created successfully. Please log in to continue",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Something went wrong. Please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },
  },

  mounted() {
    this.getUsersDetail();
  },
};
</script>
<style scoped>
.container-wrapper {
  padding: 15px;
}
.breadcrumb {
  background-color: whitesmoke;
}
</style>
