<template>
  <div
    v-if="show"
    class="modal fade show"
    id="myModal"
    tabindex="-1"
    aria-labelledby="myModalLabel"
    aria-modal="true"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">
            <strong style="color: #333">Prysm Admin Login</strong>
          </h4>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div class="col-md-10 text-center signin mt-4" style="">
              <form class="needs-validation" @submit="logIn" novalidate>
                <div class="mb-4">
                  <div class="input-group">
                    <span class="input-group-text"
                      ><i class="bi bi-envelope"></i
                    ></span>
                    <input
                      v-model="logInForm.email"
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      required
                    />
                    <div class="invalid-feedback">
                      Please provide a valid email.
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <div class="input-group">
                    <span class="input-group-text"
                      ><i class="bi bi-lock"></i
                    ></span>
                    <input
                      v-model="logInForm.password"
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      required
                    />
                    <div class="invalid-feedback">Password is required.</div>
                  </div>
                </div>

                <div class="button mb-2">
                  <button
                    class="btn btn-outline-success btn-block LoginBtn"
                    type="submit"
                  >
                    Sign In
                  </button>
                </div>
                <p>
                  <a href="#">Forgot Your Password?</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="show"
    class="modal-backdrop fade show"
    id="backdrop"
    style="display: block"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      logInForm: {
        email: "",
        password: "",
      },
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    closeModal() {
      document.getElementById("backdrop").style.display = "none";
      document.getElementById("myModal").style.display = "none";
      document.getElementById("myModal").classList.remove("show");
    },
    async logIn(event) {
      event.preventDefault();
      const form = event.target;

      if (form.checkValidity()) {
        try {
          const response = await axiosInstance.post(
            "jwt/create/",
            this.logInForm
          );

          const accessToken = response.data.access;
          const refreshToken = response.data.refresh;

          this.$store.commit("setAccessToken", accessToken);
          this.$store.commit("setRefreshToken", refreshToken);

          axiosInstance.defaults.headers.common["Authorization"] =
            "JWT " + accessToken;

          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);

          await this.getUserDetails();

          this.$notify({
            title: "Logged In Successfully",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
        } catch (error) {
          console.error(error);
          this.handleLoginError(error);
        }
      } else {
        event.preventDefault();
        event.stopPropagation();
      }

      form.classList.add("was-validated");
    },
    async getUserDetails() {
      try {
        const response = await axiosInstance.get("accounts/get-user-details/");

        localStorage.setItem("firstName", response.data.user.first_name);
        localStorage.setItem("lastName", response.data.user.last_name);
        localStorage.setItem("userRole", response.data.user.role);
        localStorage.setItem(
          "userCart",
          JSON.stringify(response.data.cart_items)
        );

        this.$store.commit("setUserDetails", response.data.user);
        this.$store.commit("updateUserCart", response.data.cart_items);
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Something went wrong. Please try again",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
      }
    },
    handleLoginError(error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 400) {
          for (const property in error.response.data) {
            this.$notify({
              title: error.response.data[property],
              type: "bg-danger-subtle text-danger",
              duration: "5000",
            });
          }
        } else {
          this.$notify({
            title: "Something went wrong. Please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        }
      } else {
        this.$notify({
          title: "Something went wrong. Please try again",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
      }
    },
  },
};
</script>
