<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light fixed-top"
    style="box-shadow: 0 2px 4px #dc3545"
  >
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img
          src="https://prysmelectronics.net/images/Prysm-technology.jpg"
          alt="Logo"
          class="logo"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeRoute === '/' }"
              aria-current="page"
              href="/"
            >
              <i class="bi bi-house"></i>
              Home
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="{ active: isActiveRoute('/products') }"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-grid"></i>
              Products
            </a>
            <ul class="dropdown-menu">
              <li
                v-for="productCategory in productCategories"
                :key="productCategory.id"
                :class="{
                  dropend:
                    productCategory.children_product_categories &&
                    productCategory.children_product_categories.length > 0,
                }"
              >
                <a
                  class="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :href="`/products/${productCategory.product_category_id}`"
                >
                  {{ productCategory.name }}
                </a>
                <ul class="dropdown-menu">
                  <li
                    v-if="productCategory.children_product_categories"
                    v-for="item in productCategory.children_product_categories"
                    :key="item.product_category_id"
                  >
                    <a
                      :href="`/products/${item.product_category_id}`"
                      class="dropdown-item"
                      >{{ item.name }}</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <form
          class="d-none d-lg-flex"
          role="search"
          id="productSearchForm"
          @submit.prevent="searchProduct"
        >
          <div class="input-group me-2">
            <input
              class="form-control"
              type="search"
              placeholder="Search Products..."
              aria-label="Search"
              v-model="searchInput"
            />
            <button
              class="btn btn-outline-danger"
              data-bs-toggle="modal"
              data-bs-target="#searchProduct"
              type="submit"
              id="searchButton"
            >
              <i class="bi bi-search"></i>
            </button>
          </div>
        </form>

        <ul class="navbar-nav">
          <li
            v-if="$store.state.user.isAuthenticated"
            class="nav-item dropdown"
          >
            <a
              class="nav-link dropdown-toggle"
              :class="{
                active:
                  isActiveRoute('/my-account') ||
                  isActiveRoute('/guest-sign-in'),
              }"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-person-check"></i>
              {{ $store.state.user.firstName }}
              <span
                v-if="
                  $store.state.user.lastName &&
                  $store.state.user.lastName.toString() != 'null'
                "
                >{{ $store.state.user.lastName }}</span
              >
            </a>
            <ul class="dropdown-menu">
              <a class="dropdown-item" href="/my-account">My Account</a>
              <a class="dropdown-item" href="/my-cart">My Cart</a>
              <a
                v-if="$store.state.user.is_staff"
                class="dropdown-item"
                href="/guest-sign-in"
                >Create Guest</a
              >
              <a
                v-if="$store.state.user.isStaff"
                class="dropdown-item"
                href="/customers-detail"
                >My Customers</a
              >

              <!-- <div class="dropdown-item"> -->
              <a @click="signOut" class="btn btn-outline-danger btn-sm ms-3">
                Sign Out
              </a>
            </ul>
          </li>

          <li v-else class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-person"></i>
              Account
            </a>
            <ul class="dropdown-menu">
              <a class="dropdown-item" href="/sign-up">Create an Account</a>
              <a class="dropdown-item" href="/sign-in">Sign in</a>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="#">
              <i class="bi bi-question-circle"></i>
              Support
            </a>
          </li>

          <li class="nav-item">
            <a
              v-if="$store.state.user.isAuthenticated"
              class="nav-link"
              :class="{
                active: isActiveRoute('/my-cart') || isActiveRoute('/checkout'),
              }"
              href="/my-cart"
            >
              <i class="bi bi-cart3"></i>
              Cart
              <span v-if="$store.state.user.userCart">
                ({{ getCartCount($store.state.user.userCart) }})
              </span>
            </a>
            <a v-else class="nav-link" href="/my-cart">
              <i class="bi bi-cart4"></i>
              Cart ({{ getCartCount($store.state.cart) }})
            </a>
          </li>
          <li v-if="$store.state.selectedCountry" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
            >
              <i class="bi bi-globe"></i>
              {{ $store.state.selectedCountry.name }}
            </a>
            <ul class="dropdown-menu">
              <li v-for="(country, index) in countries" :key="index">
                <a
                  class="dropdown-item"
                  href="#"
                  @click="selectCountry(country)"
                  >{{ country.name }}</a
                >
              </li>
            </ul>
          </li>
        </ul>

        <form
          class="d-lg-none"
          role="search"
          id="productSearchForm"
          @submit.prevent="searchProduct"
        >
          <div class="input-group">
            <input
              class="form-control"
              type="search"
              placeholder="Search Products..."
              aria-label="Search"
              v-model="searchInput"
            />
            <button
              class="btn btn-outline-danger"
              data-bs-toggle="modal"
              data-bs-target="#searchProduct"
              type="submit"
              id="searchButton"
            >
              <i class="bi bi-search"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </nav>
  <div
    class="modal fade"
    id="searchProduct"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="searchProductlLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <strong
            ><h4 class="modal-title" id="searchProductLabel">
              Search Results
            </h4></strong
          >
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="searchResults.length > 0">
            <div class="row mb-2">
              <div class="col-2">
                <span class="h4 text-body-secondary d-md-block d-none"
                  >Filters</span
                >
                <div class="mb-3">
                  <span
                    class="badge me-2 custom-badge"
                    v-for="category in selectedCategories"
                    :key="category"
                  >
                    {{ category.name }}
                    <span
                      @click="removeFilter(category)"
                      style="cursor: pointer"
                    >
                      <i class="bi-x-lg"></i>
                    </span>
                  </span>
                </div>

                <div class="card mt-3">
                  <div
                    class="card-header d-flex justify-content-between align-items-center text-white"
                    @click="toggleCategory()"
                    style="background-color: #db4c5a"
                  >
                    <span>Category</span>
                    <i
                      :class="[
                        'bi',
                        showCategoryFilter
                          ? 'bi-caret-up-fill '
                          : 'bi-caret-down-fill ',
                      ]"
                    ></i>
                  </div>
                  <template v-if="showCategoryFilter">
                    <ul
                      class="list-group list-group-flush mb-2"
                      v-for="category in productCategories"
                      :key="category.id"
                    >
                      <h6>{{ category.name }}</h6>
                      <li
                        class="list-group-item"
                        v-for="childCategory in category.children_product_categories"
                        :key="childCategory.product_category_id"
                        @click="toggleTag(childCategory)"
                      >
                        <input
                          type="checkbox"
                          v-model="childCategory.checked"
                        />
                        {{ childCategory.name }}
                      </li>
                    </ul>
                  </template>
                </div>

                <div class="position-sticky mt-2">
                  <ul class="nav nav-pills flex-column mb-auto">
                    <li class="nav-item">
                      <a class="nav-link text-black" href="#">
                        <strong>Price</strong>
                      </a>
                      <ul class="list-group">
                        <li class="list-group-item">
                          Min Price
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Min Price"
                            v-model="minPrice"
                          />
                        </li>
                        <li class="list-group-item">
                          Max Price
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Max Price"
                            v-model="maxPrice"
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-10">
                <div class="text-end text-md-end" style="margin-bottom: 10px">
                  <button
                    class="btn btn-danger dropdown-toggle mb-2 mb-md-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Sort by
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        @click="activeSort = 'relevance'"
                        :class="{ active: activeSort === 'relevance' }"
                        href="#"
                        >Relevance</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        @click="activeSort = 'low to high price'"
                        :class="{ active: activeSort === 'low to high price' }"
                        href="#"
                        >Price (Low to High)</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        @click="activeSort = 'high to low price'"
                        :class="{ active: activeSort === 'high to low price' }"
                        href="#"
                        >Price (High to Low)</a
                      >
                    </li>
                  </ul>
                </div>

                <div v-for="product in displayedProducts" :key="product.id">
                  <div class="card mb-4">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-2 p-2">
                          <img
                            v-if="product.product_image_path"
                            class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="200"
                            height="200"
                            :src="product.product_image_path"
                            role="img"
                            aria-label="Placeholder: 500x500"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                          />
                          <img
                            v-else
                            class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="200"
                            height="200"
                            src="https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
                            role="img"
                            aria-label="Placeholder: 500x500"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                          />
                        </div>
                        <div class="col-md-8">
                          <h4 class="card-title">
                            <a
                              :href="`/products/${$route.params.category_id}/${product.product_id}`"
                              style="text-decoration: none"
                              >{{ product.name }}</a
                            >
                          </h4>
                          <p class="card-text">
                            {{ product.product_description }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="card-text">
                            <b>
                              ₹
                              {{ $parsePriceToString(product.price_with_tax) }}
                            </b>
                            <br />

                            <a
                              :href="`/products/${$route.params.category_id}/${product.product_id}`"
                            >
                              <button
                                class="btn btn-outline-success"
                                data-bs-dismiss="modal"
                              >
                                View Detail
                              </button>
                            </a>

                            <br />
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul class="pagination">
                  <li
                    class="page-item"
                    :class="{ active: page === currentPage }"
                    v-for="page in totalPages"
                    :key="page"
                  >
                    <a class="page-link" @click="goToPage(page)">{{ page }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="container">
              <p>No results found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      perPage: 3,
      currentPage: 1,
      activeSort: "relevance",
      searchInput: "",
      searchResults: [],
      selectedCategory: "",
      minPrice: null,
      maxPrice: null,
      selectedCategories: [],
      showCategoryFilter: false,
    };
  },
  props: {
    activeRoute: {
      type: String,
      default: "",
    },
    productCategories: {
      Type: Array,
      default: [],
    },
    countries: {
      type: Array,
      default: [],
    },
  },
  mounted() {},
  computed: {
    filteredProducts() {
      let filtered = this.searchResults;

      if (this.selectedCategories.length > 0) {
        filtered = filtered.filter((product) => {
          return this.selectedCategories.some(
            (category) =>
              product.product_category === category.product_category_id
          );
        });
      }
      console.log(this.selectedCategories);

      if (this.minPrice && !this.maxPrice) {
        const min = parseFloat(this.minPrice);
        if (!isNaN(min)) {
          filtered = filtered.filter((product) => {
            const price = parseFloat(product.price_with_tax);
            return price >= min;
          });
        }
      }

      if (!this.minPrice && this.maxPrice) {
        const max = parseFloat(this.maxPrice);
        if (!isNaN(max)) {
          filtered = filtered.filter((product) => {
            const price = parseFloat(product.price_with_tax);
            return price <= max;
          });
        }
      }

      if (this.minPrice && this.maxPrice) {
        const min = parseFloat(this.minPrice);
        const max = parseFloat(this.maxPrice);
        if (!isNaN(min) && !isNaN(max) && min <= max) {
          filtered = filtered.filter((product) => {
            const price = parseFloat(product.price_with_tax);
            return price >= min && price <= max;
          });
        }
      }

      return filtered;
    },
    sortedProducts() {
      if (this.activeSort === "low to high price") {
        return this.filteredProducts
          .slice()
          .sort((a, b) => a.price_with_tax - b.price_with_tax);
      } else if (this.activeSort === "high to low price") {
        return this.filteredProducts
          .slice()
          .sort((a, b) => b.price_with_tax - a.price_with_tax);
      } else {
        return this.filteredProducts;
      }
    },
    displayedProducts() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.sortedProducts.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.sortedProducts.length / this.perPage);
    },
  },
  methods: {
    isActiveRoute(routeName) {
      return this.activeRoute.startsWith(routeName);
    },
    signOut() {
      axiosInstance.defaults.headers.common["Authorization"] = "";
      // localStorage.removeItem("accessToken");
      // localStorage.removeItem("refreshToken");
      // localStorage.removeItem("firstName");
      // localStorage.removeItem("lastName");
      // localStorage.removeItem("userRole");
      // localStorage.removeItem("userCart");
      localStorage.clear();

      sessionStorage.removeItem("cart");
      this.$store.commit("removeAccessToken");
      this.$store.commit("removeRefreshToken");
      this.$store.commit("removeUserDetails");
      this.$store.commit("removeCart");
      this.$store.commit("removeUserCart");
      this.$router.push("/");
    },

    getCartCount(cart) {
      var cartCount = 0;
      for (let cartItem of cart) {
        cartCount += cartItem.quantity;
      }
      return cartCount;
    },
    async searchProduct() {
      this.searchResults = [];
      let results = "";

      try {
        const response = await axiosInstance.get(
          "products/product_search_view/",
          {
            params: {
              search_term: this.searchInput,
            },
          }
        );
        if (this.searchInput === "") {
          results = "";
        } else {
          results = response.data.results;
          console.log(results);
        }

        if (results.length > 0) {
          this.searchResults = results;
          console.log(this.searchResults);
        } else {
          this.searchResults = [];
        }
      } catch (error) {
        console.error(error);
        this.searchResults = [];
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    toggleCategory() {
      this.showCategoryFilter = !this.showCategoryFilter;
    },

    toggleTag(childCategory) {
      console.log(childCategory);
      childCategory.checked = !childCategory.checked;

      if (childCategory.checked) {
        const existingCategory = this.selectedCategories.find(
          (category) =>
            category.product_category_id === childCategory.product_category_id
        );
        if (!existingCategory) {
          this.selectedCategories.push(childCategory);
        }
      } else {
        this.selectedCategories = this.selectedCategories.filter(
          (category) =>
            category.product_category_id !== childCategory.product_category_id
        );
      }
    },

    removeFilter(category) {
      let selectedCategory = this.selectedCategories.find(
        (cat) => cat.product_category_id === category.product_category_id
      );
      if (selectedCategory) {
        this.selectedCategories.pop(selectedCategory);
      }
      category.checked = false;
    },
    selectCountry(country) {
      localStorage.setItem("selectedCountry", JSON.stringify(country));
      this.$store.commit("setSelectedCountry", country);
    },
  },
};
</script>

<style scoped>
.logo {
  width: 120px;
  display: block;
  margin: 0 auto;
}

.list-group-item:hover {
  background-color: #db4c5a;
  color: whitesmoke;
  cursor: pointer;
}
.custom-badge {
  background-color: #db4c5a;
}
.form-control:focus {
  border-color: #dc3545;
  box-shadow: none;
}

.dropend .dropdown-toggle {
  color: #dc3545;
}
.dropdown-item:hover {
  background-color: #dc3545;
  color: #fff;
}
.dropdown .dropdown-menu {
  display: none;
}
.dropdown:hover > .dropdown-menu,
.dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 0.125em;
  margin-left: 0.125em;
}
@media screen and (min-width: 769px) {
  .dropend:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .dropend .dropdown-toggle {
    margin-left: 0.5em;
  }
}
</style>
