import { createRouter, createWebHistory } from "vue-router";
import SignIn from "../views/Account/SignIn.vue";
import GuestSignIn from "../views/Account/GuestSignIn.vue";
import MyCart from "../views/Orders/MyCart.vue";
import MyCartNew from "../views/Orders/MyCartNew.vue";
import ProductCatalogue from "../views/Products/ProductCatalogue.vue";
import ProductDetails from "../views/Products/ProductDetails.vue";
import HomePage from "../views/HomePage.vue";
import AboutPage from "../views/AboutPage.vue";
import AdminOrderList from "../views/Admin/AdminOrderList.vue";
import CheckoutPage from "../views/Orders/CheckoutPage.vue";
import OrderConfirmation from "../views/Orders/OrderConfirmation.vue";
import MyAccount from "../views/Account/MyAccount.vue";
import SignUp from "../views/Account/SignUp.vue";
import UserAddress from "../views/Account/UserAddress.vue";
import AdminOrderDetails from "@/components/Admin/AdminOrderDetails.vue";
import AdminUsersDetail from "../views/Admin/AdminUsersDetail.vue";
import AdminUserOrdersDetail from "../views/Admin/AdminUserOrdersDetail";
import AddCustomer from "../views/Account/AddCustomer.vue";
// import AddCustomer from "../views/Account/AddCustomer.vue";
import CustomersDetail from "../views/Account/CustomersDetail.vue";
import CustomersCheckOuts from "../views/Orders/CustomersCheckOuts.vue";
import OrderProcessing from "../views/Orders/OrderProcessing.vue";
import ZohoContacs from "../views/Orders/ZohoContacs.vue";
// import ForgotPassword from "../views/Account/ForgotPassword.vue";
import ForgotPassword from "@/components/Account/ForgotPassword.vue";
import ResetPassword from "@/components/Account/ResetPassword.vue";
import RePasswordDone from "@/components/Account/RePasswordDone.vue";

import invoice from "../views/Orders/invoice.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/invoice",
    name: "invoice",
    component: invoice,
  },
  {
    path: "/zoho-contacts",
    name: "ZohoContacs",
    component: ZohoContacs,
  },
  {
    path: "/about",
    name: "AboutPage",
    component: AboutPage,
  },
  {
    path: "/my-account",
    name: "MyAccount",
    component: MyAccount,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password/:token/",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/password-done/",
    name: "RePasswordDone",
    component: RePasswordDone,
  },
  {
    path: "/guest-sign-in",
    name: "GuestSignIn",
    component: GuestSignIn,
  },
  {
    path: "/products/:category_id",
    name: "ProductCatalogue",
    component: ProductCatalogue,
  },
  {
    path: "/products/:category_id/:product_id",
    name: "ProductDetails",
    component: ProductDetails,
  },
  {
    path: "/my-cart-old",
    name: "MyCart",
    component: MyCart,
  },
  {
    path: "/my-cart",
    name: "MyCartNew",
    component: MyCartNew,
  },
  {
    path: "/checkout",
    name: "CheckoutPage",
    component: CheckoutPage,
  },

  {
    path: "/customer-checkout",
    name: "CustomersCheckOuts",
    component: CustomersCheckOuts,
  },

  {
    path: "/order-processing/:orderId",
    name: "OrderProcessing",
    component: OrderProcessing,
  },

  {
    path: "/order-confirmation/:order_number",
    name: "OrderConfirmation",
    component: OrderConfirmation,
  },

  {
    path: "/admin/orderlist",
    name: "AdminOrderList",
    component: AdminOrderList,
  },
  {
    path: "/admin/order-details/:order_id",
    name: "AdminOrderDetails",
    component: AdminOrderDetails,
  },
  {
    path: "/admin/user-details",
    name: "AdminUsersDetail",
    component: AdminUsersDetail,
  },
  {
    path: "/admin/user-orders-detail/:id",
    name: "AdminUserOrdersDetail",
    component: AdminUserOrdersDetail,
  },
  {
    path: "/add-customer",
    name: "AddCustomer",
    component: AddCustomer,
  },
  {
    path: "/customers-detail",
    name: "CustomersDetail",
    component: CustomersDetail,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
