<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">Order #{{ order.order_number }}</h4>
          <p class="mb-0">
            Status:
            <strong>
              {{ order.fulfillment_status }}
            </strong>
          </p>
        </div>
        <div class="col-md-6 text-md-end">
          <p class="mb-0">Total Amount</p>
          <strong>
            <p>₹ {{ $parsePriceToString(order.total_amount) }}</p>
          </strong>
          <!-- <button
          class="btn btn-outline-success"
          data-bs-toggle="modal"
          :data-bs-target="'#orderDetailModal-' + index"
        >
          More details
        </button> -->
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Product</th>
              <th>Status</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, itemIndex) in order.order_items" :key="itemIndex">
              <td>
                <img
                  v-if="item.product.product_image_path"
                  class="img-fluid rounded"
                  :src="item.product.product_image_path"
                  :alt="item.product.name"
                  width="50"
                  height="50"
                />
                <router-link
                  :to="`/products/${item.product.product_category}/${item.product.product_id}`"
                  style="text-decoration: none; color: black"
                >
                  {{ item.product.name }}
                </router-link>
              </td>
              <td>{{ item.fulfillment_status }}</td>
              <td>{{ item.quantity }}</td>
              <td>₹ {{ $parsePriceToString(item.price) }}</td>
              <td>
                ₹
                {{ $parsePriceToString(item.quantity * item.price) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {},
  mounted() {},
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {},
  computed: {},
  methods: {},
};
</script>
