<template>
  <body
    v-if="activeRoute.startsWith('/admin')"
    class="layout-fixed layout-navbar-fixed sidebar-mini sidebar-collapse"
  >
    <div v-if="$store.state.user.isAuthenticated">
      <AdminPage />
    </div>
    <div v-else>
      <AdminLogInModal :show="true" />
    </div>

    <notifications position="bottom right" classes="alert" />
  </body>
  <div v-else id="app">
    <AppNavbar
      :product-categories="productCategories"
      :countries="countries"
      :active-route="activeRoute"
    />
    <div class="content-container">
      <router-view />
    </div>
    <AppFooter />
    <notifications position="bottom right" classes="alert" />
  </div>
</template>

<script>
import AppNavbar from "@/components/layout/AppNavbar.vue";
import AppFooter from "@/components/layout/AppFooter.vue";
import { isTokenExpired } from "@/utils/tokenUtils.js";
// import { showNotification } from "@/utils/notificationUtility.js";
import AdminPage from "./views/Admin/AdminPage.vue";
import axios from "axios";
import { useVuelidate } from "@vuelidate/core";
import SignIn from "./views/Account/SignIn.vue";
import AdminLogInModal from "./components/Admin/AdminLogInModal.vue";
import emitter from "./utils/eventBus";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "App",
  components: {
    AppNavbar,
    AppFooter,
    AdminPage,
    SignIn,
    AdminLogInModal,
  },
  // watch: {
  //   "$store.state.isRefreshTokenExpired"(newValue) {
  //     if (newValue) {
  //       this.$router.push("/sign-in");
  //     }
  //   },
  // },
  // beforeCreate() {
  //   this.$store.commit("initializeStore");

  //   const accessToken = this.$store.state.user.accessToken;
  //   const refreshToken = this.$store.state.user.refreshToken;
  //   if (accessToken) {
  //     if (isTokenExpired(accessToken)) {
  //       console.log("expired");

  //       if (refreshToken) {
  //         console.log("has refresh token");
  //         this.$store.commit("refreshAccessToken");
  //       } else {
  //         console.log("no refresh token");
  //         localStorage.removeItem("accessToken");
  //         localStorage.removeItem("refreshToken");
  //         this.$store.commit("removeAccessToken");
  //         this.$store.commit("removeRefreshToken");
  //         axios.defaults.headers.common["Authorization"] = "";
  //       }
  //     } else {
  //       console.log("token not expired");
  //       axios.defaults.headers.common["Authorization"] = "JWT " + accessToken;
  //     }
  //   } else {
  //     console.log("no access token ");
  //     axios.defaults.headers.common["Authorization"] = "";
  //   }
  // },
  beforeCreate() {
    this.$store.commit("initializeStore");
  },

  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    if (this.activeRoute.startsWith("/admin")) {
    } else {
      const accessToken = this.$store.state.user.accessToken;
      const refreshToken = this.$store.state.user.refreshToken;

      if (refreshToken) {
        if (isTokenExpired(refreshToken)) {
          // console.log(" isnide if of is token expired");

          this.$notify({
            title: "Your session has expired. Please sign in again.",
            type: "bg-danger-subtle text-danger",
            duration: 5000,
          });

          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          localStorage.clear();

          sessionStorage.removeItem("cart");
          this.$store.commit("removeAccessToken");
          this.$store.commit("removeRefreshToken");
          this.$store.commit("removeCart");
          this.$store.commit("removeUserCart");
          // this.$router.push("/"); // Navigate to home page
          window.location.href = "/";

          axios.defaults.headers.common["Authorization"] = "";
        } else {
          // console.log(" refresh token not expired");
          axiosInstance.defaults.headers.common["Authorization"] =
            "JWT " + accessToken;
        }
      } else {
        // console.log("no refresh token sdsdsd ");
        axios.defaults.headers.common["Authorization"] = "";
      }

      this.getNavContent();
    }
  },
  computed: {
    activeRoute() {
      return this.$route.path;
    },
  },
  data() {
    return {
      productCategories: [],
      countries: [],
    };
  },
  methods: {
    async getNavContent() {
      await axios
        .get("products/get-nav-content/")
        .then((response) => {
          this.productCategories = response.data.product_categories;
          this.countries = response.data.countries;

          if (this.countries.length !== 0) {
            if (!localStorage.getItem("selectedCountry")) {
              let defaultCountry = this.countries.filter(
                (country) => country.name.toString() == "India"
              )[0];
              console.log(defaultCountry);
              localStorage.setItem(
                "selectedCountry",
                JSON.stringify(defaultCountry)
              );
              this.$store.commit("setSelectedCountry", defaultCountry);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Something went wrong. Please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* body {
  font-family: 'Inter', sans-serif; 
} */
.content-container {
  padding-top: 60px;
  padding-bottom: 60px;
}

a {
  text-decoration: none !important;
}

.dropdown-item:hover {
  background-color: #db4c5a;
  color: whitesmoke;
}

.dropdown-menu .dropdown-item.active {
  background-color: #db4c5a;
}

.pagination .active .page-link {
  background-color: #dc3545;
  border-color: #dc3545;
}

.pagination .page-link {
  color: black;
}

.pagination .page-item.active .page-link {
  color: white;
  background-color: #db4c5a;
  border-color: #db4c5a;
}

.pagination .page-item.disabled .page-link {
  color: #6c757d;
}

.pagination .page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  outline: none;
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5) !important;
}

.accordion-button:not(.collapsed) {
  background-color: #ffffff !important;
}

.accordion .accordion-focus {
  color: black !important;
}
</style>
