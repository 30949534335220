<template>
  <div class="container mt-5">
    <h2>Addresses</h2>
    <button
      class="btn btn-outline-success"
      data-bs-toggle="modal"
      data-bs-target="#addAddressModal"
    >
      + Add Address
    </button>
  </div>
  <div class="container mt-3">
    <div
      v-for="(address, index) in paginatedAddresses"
      :key="index"
      class="card mb-3"
    >
      <div class="card-body">
        <div class="d-flex justify-content-end mb-3">
          <div class="row">
            <div class="col-6">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#editAddressModal"
                @click="openEditAddressModal(address)"
              >
                <i class="bi bi-pencil-square"></i>
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#deleteAddressModal"
                @click="setDeleteAddressId(address.user_address_id)"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <h5 class="card-title">
          {{ address.first_name }} {{ address.last_name }}
        </h5>
        <p class="card-text">{{ address.street_address_1 }}</p>
        <p class="card-text">{{ address.street_address_2 }}</p>
        <p class="card-text">{{ address.city }}</p>
        <p class="card-text">{{ address.state }}</p>
        <p class="card-text">{{ address.postal_code }}</p>
        <p class="card-text">{{ address.phone_number }}</p>
        <p class="card-text">{{ address.email }}</p>
      </div>
    </div>
    <nav>
      <ul class="pagination justify-content-center">
        <li class="page-item">
          <button class="page-link" @click="previousPage">
            <i class="bi bi-arrow-left"></i>
          </button>
        </li>
        <li class="page-item">
          <span class="page-link">{{ currentPage }}</span>
        </li>
        <li class="page-item">
          <button class="page-link" @click="nextPage">
            <i class="bi bi-arrow-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>

  <div
    class="modal fade"
    id="addAddressModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addAddressModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addAddressModalLabel">Add New Address</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createUserAddress">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="firstName">First Name *</label>
                  <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    v-model="newAddress.first_name"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lastName">Last Name *</label>
                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    v-model="newAddress.last_name"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="postalCode">Postal Code *</label>
              <input
                type="text"
                class="form-control"
                id="postalCode"
                v-model="newAddress.postal_code"
                required
              />
            </div>

            <div class="form-group">
              <label for="addressLine1">Address Line 1 *</label>
              <input
                type="text"
                class="form-control"
                id="addressLine1"
                v-model="newAddress.street_address_1"
                required
              />
            </div>
            <div class="form-group">
              <label for="addressLine2">Address Line 2</label>
              <input
                type="text"
                class="form-control"
                id="addressLine2"
                v-model="newAddress.street_address_2"
              />
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="city">City *</label>
                  <input
                    type="text"
                    class="form-control"
                    id="city"
                    v-model="newAddress.city"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="state">State *</label>
                  <input
                    type="text"
                    class="form-control"
                    id="state"
                    v-model="newAddress.state"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="AddressType">Address Type *</label>
                <select
                  class="form-control"
                  id="addressType"
                  v-model="newAddress.address_type"
                >
                  <option value="Billing">Billing</option>
                  <option value="Shipping">Shipping</option>
                  <option value="one">Customer</option>
                </select>
              </div>
            </div>

            <!-- <div class="form-group">
                <label for="mobileNumber">Mobile Number *</label>
                <input type="text" class="form-control" id="mobileNumber" v-model="newAddress.phone_number" required>
              </div> -->

            <!-- <div class="form-check">
              <input type="checkbox" class="form-check-input" id="contactByPhone" v-model="newAddress.contactByPhone">
              <label class="form-check-label" for="contactByPhone">Yes, I would like to be contacted by phone
                (Optional)</label>
            </div> -->

            <button
              type="submit"
              class="btn btn-outline-success"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editAddressModal"
    tabindex="-1"
    aria-labelledby="editAddressModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editAddressModalLabel">Edit Address</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="editUserAddress">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="firstName">First Name *</label>
                  <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    v-model="editedAddress.first_name"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lastName">Last Name *</label>
                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    v-model="editedAddress.last_name"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="postalCode">Postal Code *</label>
              <input
                type="text"
                class="form-control"
                id="postalCode"
                v-model="editedAddress.postal_code"
                required
              />
            </div>

            <div class="form-group">
              <label for="addressLine1">Address Line 1 *</label>
              <input
                type="text"
                class="form-control"
                id="addressLine1"
                v-model="editedAddress.street_address_1"
                required
              />
            </div>
            <div class="form-group">
              <label for="addressLine2">Address Line 2</label>
              <input
                type="text"
                class="form-control"
                id="addressLine2"
                v-model="editedAddress.street_address_2"
              />
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="city">City *</label>
                  <input
                    type="text"
                    class="form-control"
                    id="city"
                    v-model="editedAddress.city"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="state">State *</label>
                  <input
                    type="text"
                    class="form-control"
                    id="state"
                    v-model="editedAddress.state"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="AddressType">Address Type *</label>
                <select
                  class="form-control"
                  id="addressType"
                  v-model="editedAddress.address_type"
                >
                  <option value="Billing">Billing</option>
                  <option value="Shipping">Shipping</option>
                  <option value="one">Customer</option>
                </select>
              </div>
            </div>

            <!-- <div class="form-check">
              <input type="checkbox" class="form-check-input" id="contactByPhone" v-model="editedAddress.contactByPhone">
              <label class="form-check-label" for="contactByPhone">Yes, I would like to be contacted by phone
                (Optional)</label>
            </div> -->
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="btn btn-outline-success"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="deleteAddressModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteAddressModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteAddressModalLabel">
            Confirm Deletion
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Sure, you want to delete this address.</div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
            @click="confirmDeleteAddress"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addresses: [],
      newAddress: {
        user_address_id: "",
        user: "",
        street_address_1: "",
        street_address_2: "",
        city: "",
        state: "",
        address_type: "",
        postal_code: "",
        email: "",
        phone_number: "",
        first_name: "",
        last_name: "",
      },

      editedAddress: {
        user_address_id: "",
        first_name: "",
        last_name: "",
        street_address_1: "",
        street_address_2: "",
        city: "",
        state: "",
        address_type: "",
        postal_code: "",
      },
      currentPage: 1,
      addressesPerPage: 5,
    };
  },
  computed: {
    paginatedAddresses() {
      const startIndex = (this.currentPage - 1) * this.addressesPerPage;
      const endIndex = startIndex + this.addressesPerPage;
      return this.addresses.slice(startIndex, endIndex);
    },
  },
  methods: {
    openAddAddressModal() {
      this.newAddress = {
        street_address_1: "",
        street_address_2: "",
        city: "",
        state: "",
        address_type: "",

        postal_code: "",
        first_name: "",
        last_name: "",
      };
    },
    openEditAddressModal(address) {
      console.log("print");
      this.editedAddress.user_address_id = address.user_address_id;
      this.editedAddress.first_name = address.first_name;
      this.editedAddress.last_name = address.last_name;
      this.editedAddress.street_address_1 = address.street_address_1;
      this.editedAddress.street_address_2 = address.street_address_2;
      this.editedAddress.city = address.city;
      this.editedAddress.state = address.state;
      this.editedAddress.address_type = address.address_type;
      this.editedAddress.postal_code = address.postal_code;
    },

    async createUserAddress() {
      try {
        const response = await axiosInstance.post(
          `/accounts/create-user-address/`,
          this.newAddress
        );
        console.log("Address created:", response.data);
        this.fetchUserAddresses();
        this.$notify({
          title: "Address added successfully",
          type: "bg-success-subtle text-success",
          duration: "5000",
        });
      } catch (error) {
        console.error("Error creating address:", error);
      }
    },
    async fetchUserAddresses() {
      try {
        const response = await axiosInstance.get(`/accounts/get-user-address/`);
        this.addresses = response.data.address;
        console.log("Addresses:", this.addresses);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    },

    async editUserAddress() {
      try {
        console.log("print");
        const response = await axiosInstance.put(
          `/accounts/update-user-address/${this.editedAddress.user_address_id}/`,
          this.editedAddress
        );

        const editedIndex = this.addresses.findIndex(
          (address) =>
            address.user_address_id === this.editedAddress.user_address_id
        );
        if (editedIndex !== -1) {
          this.addresses[editedIndex] = response.data;
        }
        this.$store.commit("setUsersDetails", response.data);
        localStorage.setItem("firstName", response.data.first_name);
        localStorage.setItem("lastName", response.data.last_name);
        localStorage.setItem("addressType", response.data.address_type);
        localStorage.setItem("State", response.data.state);
        localStorage.setItem("City", response.data.city);
        localStorage.setItem("streetAddress_1", response.data.street_address_1);
        localStorage.setItem("streetAddress_2", response.data.street_address_2);
        this.fetchUserAddresses();

        this.$notify({
          title: "Address updated successfully",
          type: "bg-success-subtle text-success",
          duration: "5000",
        });
      } catch (error) {
        console.error("Error updating user details:", error);
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setDeleteAddressId(addressId) {
      this.addressToDeleteId = addressId;
    },
    confirmDeleteAddress() {
      if (this.addressToDeleteId !== null) {
        this.deleteAddress(this.addressToDeleteId);
        this.addressToDeleteId = null;
      }
    },
    async deleteAddress(addressId) {
      try {
        console.log(addressId);
        const response = await axiosInstance.delete(
          `/accounts/delete-user-address/${addressId}/`
        );
        if (response.status === 204) {
          const indexToDelete = this.paginatedAddresses.findIndex(
            (address) => address.id === addressId
          );
          if (indexToDelete !== -1) {
            this.paginatedAddresses.splice(indexToDelete, 1);
          }
          this.fetchUserAddresses();
          this.$notify({
            title: "Address deleted successfully",
            type: "bg-success-subtle text-danger",
            duration: "5000",
          });
        } else {
          console.error("Failed to delete the address:", response.data);
        }
      } catch (error) {
        console.error("An error occurred while deleting the address:", error);
      }
    },

    nextPage() {
      const maxPage = Math.ceil(this.addresses.length / this.addressesPerPage);
      if (this.currentPage < maxPage) {
        this.currentPage++;
      }
    },
  },
  mounted() {
    this.fetchUserAddresses();
  },
};
</script>
<style scoped>
/* button {
    background-color: grey;
} */
</style>
