s
<template>
  <div
    class="modal fade"
    id="productCustomizationDetail"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="productCustomizationDetailLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="productCustomizationDetailLabel">
            {{ product.name }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <strong>
                  Total Price : ₹ {{ $parsePriceToString(customizedPrice) }}
                </strong>
                <p>Price inclusive of GST. Free Delivery.</p>
              </div>
            </div>
            <!-- <div>
                  {{ selectedCustomizations }}
                 </div> -->
            <!-- parentCategory.option.customization_option.customization_category.parent_customization_category.name -->
            <table
              class="table"
              v-for="customizationCategoryId in uniqueCustomizationCategoryIds"
              :key="customizationCategoryId"
            >
              <thead>
                <h5>{{ customizationCategoryId.name }}</h5>
                <tr>
                  <th scope="col">Option</th>
                  <th scope="col">Selection</th>
                  <th scope="col">Quantity</th>
                </tr>
              </thead>

              <tbody
                v-for="(selectedCustom, index) in selectedCustomizations"
                :key="index"
              >
                <template
                  v-if="
                    selectedCustom.option.customization_option
                      .customization_category_hierarchy === 3
                  "
                >
                  <tr
                    v-if="
                      selectedCustom.option.customization_option
                        .customization_category.parent_customization_category
                        .parent_customization_category
                        .customization_category_id ===
                      customizationCategoryId.customization_category_id
                    "
                  >
                    <td>
                      {{
                        selectedCustom.option.customization_option
                          .customization_category.parent_customization_category
                          .name
                      }}
                    </td>
                    <td>
                      <strong>
                        {{
                          selectedCustom.option.customization_option
                            .customization_category.name
                        }}
                      </strong>
                      {{ selectedCustom.option.customization_option.name }}
                    </td>
                    <td>{{ selectedCustom.selected_quantity }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr
                    v-if="
                      selectedCustom.option.customization_option
                        .customization_category.parent_customization_category
                        .customization_category_id ===
                      customizationCategoryId.customization_category_id
                    "
                  >
                    <td>
                      {{
                        selectedCustom.option.customization_option
                          .customization_category.name
                      }}
                    </td>
                    <td>
                      {{ selectedCustom.option.customization_option.name }}
                    </td>
                    <td>{{ selectedCustom.selected_quantity }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-outline-success"
            data-bs-dismiss="modal"
            @click="addToCart"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selectedCustomizations: Array,
    product: Object,
    addToCartFunction: Function,
    customizedPrice: {
      type: Number,
    },
  },
  computed: {
    uniqueCustomizationCategoryIds() {
      const parentCategories = [];
      for (const customization of this.selectedCustomizations) {
        if (
          customization.option.customization_option
            .customization_category_hierarchy == 3
        ) {
          parentCategories.push(
            customization.option.customization_option.customization_category
              .parent_customization_category.parent_customization_category
          );
        } else {
          parentCategories.push(
            customization.option.customization_option.customization_category
              .parent_customization_category
          );
        }
      }
      const uniqueArray = parentCategories.reduce((acc, current) => {
        const existing = acc.find(
          (item) =>
            item.customization_category_id === current.customization_category_id
        );
        if (!existing) {
          acc.push(current);
        }
        return acc;
      }, []);
      return uniqueArray;
    },
    // uniqueCategoryNames() {
    //   const names = new Set();
    //   for (const product of this.selectedCustomizations) {
    //     names.add(product.option.customization_option.customization_category.name);
    //   }
    //   return Array.from(names);
    // },
  },

  methods: {
    someMethod() {
      let customizationData = this.selectedCustomizations;
      let product_data = this.product;

      console.log(customizationData);
      console.log(product_data);
    },
    getCategoryNameById(customizationCategoryId) {
      for (const selectedCustom of this.selectedCustomizations) {
        if (
          selectedCustom.option.customization_option.customization_category
            .parent_customization_category.customization_category_id ===
          customizationCategoryId
        ) {
          return selectedCustom.option.customization_option
            .customization_category.parent_customization_category.name;
        }
      }
      return "";
    },
    addToCart() {
      this.$emit("add-to-cart");
    },
  },
  mounted() {
    this.someMethod();
    console.log("selected", this.selectedCustomizations);
  },
};
</script>
