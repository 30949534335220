<template>
  <div class="container">
    <div class="row">
      <div class="col-3">Item</div>
      <div class="col-3"></div>
      <div class="col-3">Quantity</div>
      <div class="col-3">Price</div>
    </div>
    <hr />
    <div class="container">
      <div v-for="(cartItem, index) in cartItems" :key="index">
        <div class="row">
          <div class="col-3">
            <img
              v-if="cartItem.product && cartItem.product.product_image_path"
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="200"
              height="200"
              :src="cartItem.product.product_image_path"
              role="img"
              aria-label="Product Image"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            />
          </div>
          <div class="col-3">
            <a
              v-if="cartItem.customization_id"
              class="h4"
              :href="`/products/${cartItem.product.product_category.product_category_id}/${cartItem.product.product_id}?customizationId=${cartItem.customization_id}`"
            >
              {{ cartItem.product.name }}
            </a>
            <a
              v-else
              class="h4"
              :href="`/products/${cartItem.product.product_category.product_category_id}/${cartItem.product.product_id}`"
            >
              {{ cartItem.product.name }}
            </a>
          </div>
          <div class="col-3">
            <div v-if="enableQuantityChange" class="input-group">
              <button
                v-if="cartItem.quantity == 1"
                @click="updateCartItem(cartItem, 'delete')"
                type="button"
                class="btn btn-outline-secondary"
              >
                <i class="bi bi-trash"></i>
              </button>
              <button
                v-else
                @click="updateCartItem(cartItem, 'decrease')"
                type="button"
                class="btn btn-outline-secondary"
              >
                -
              </button>
              <span class="mx-2 my-2">{{ cartItem.quantity }}</span>
              <button
                @click="updateCartItem(cartItem, 'increase')"
                type="button"
                class="btn btn-outline-secondary"
              >
                +
              </button>
            </div>
            <div v-else>
              <span class="mx-2 my-2">{{ cartItem.quantity }}</span>
            </div>
          </div>

          <div v-if="cartItem.customizedPrice" class="col-3">
            ₹{{
              $parsePriceToString(
                cartItem.product &&
                  parseFloat(cartItem.customizedPrice) * cartItem.quantity
              )
            }}
          </div>
          <div v-else-if="cartItem.price" class="col-3">
            ₹{{
              $parsePriceToString(
                cartItem.product &&
                  parseFloat(cartItem.price) * cartItem.quantity
              )
            }}
          </div>
        </div>
        <div class="conatiner pt-4">
          <div
            class="accordion"
            :id="'accordionExampleTechSpecs' + index"
            style="padding-left: 25%"
          >
            <div class="accordion-item">
              <h2 class="accordion-header" :id="'headingTechSpecs' + index">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapseTechSpecs' + index"
                  aria-expanded="true"
                  :aria-controls="'collapseTechSpecs' + index"
                >
                  <span class="accordion-focus">
                    <b> Tech Specs </b>
                  </span>
                </button>
              </h2>
              <div
                :id="'collapseTechSpecs' + index"
                class="accordion-collapse collapse"
                :aria-labelledby="'headingTechSpecs' + index"
                :data-bs-parent="'#accordionExampleTechSpecs' + index"
              >
                <div
                  v-if="cartItem.customizations"
                  class="accordion-body"
                  style="text-align: left"
                >
                  <div
                    v-for="(
                      customizationOption, customizationIndex
                    ) in cartItem.customizations"
                    :key="customizationIndex"
                  >
                    <template
                      v-if="
                        customizationOption.option.customization_option
                          .customization_category_hierarchy == 3
                      "
                    >
                      <b>
                        {{
                          customizationOption.option.customization_option
                            .customization_category
                            .parent_customization_category.name
                        }}
                      </b>
                    </template>
                    <template v-else>
                      <b>
                        {{
                          customizationOption.option.customization_option
                            .customization_category.name
                        }}
                      </b>
                    </template>
                    <ul>
                      <template
                        v-if="
                          customizationOption.option.customization_option
                            .customization_category_hierarchy == 3
                        "
                      >
                        <li>
                          <b>
                            {{
                              customizationOption.option.customization_option
                                .customization_category.name
                            }}
                          </b>
                          {{
                            customizationOption.option.customization_option.name
                          }}
                          <span
                            v-if="customizationOption.selected_quantity > 1"
                          >
                            ( Qty:
                            {{ customizationOption.selected_quantity }})</span
                          >
                        </li>
                      </template>
                      <template v-else>
                        <li>
                          {{
                            customizationOption.option.customization_option.name
                          }}
                          <span
                            v-if="customizationOption.selected_quantity > 1"
                          >
                            ( Qty:
                            {{ customizationOption.selected_quantity }})</span
                          >
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
                <div
                  v-else-if="cartItem.order_item_customization_options"
                  class="accordion-body"
                  style="text-align: left"
                >
                  <div
                    v-for="(
                      customizationOption, index
                    ) in cartItem.order_item_customization_options"
                    :key="index"
                  >
                    <p
                      v-if="
                        customizationOption.customization_option &&
                        customizationOption.customization_option
                          .customization_category
                      "
                    >
                      <b>{{
                        customizationOption.customization_option
                          .customization_category.name
                      }}</b>
                    </p>
                    <ul>
                      <li v-if="customizationOption.customization_option">
                        {{ customizationOption.customization_option.name }}
                        <span v-if="customizationOption.quantity > 1">
                          ( Qty: {{ customizationOption.quantity }})</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//

export default {
  props: {
    cartItems: {
      type: Array,
      required: true,
    },
    enableQuantityChange: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },
  mounted() {},
  methods: {
    updateCartItem(cartItem, type) {
      console.log("update");
      this.$emit("cartItemUpdated", {
        cartItem: cartItem,
        type: type,
      });
    },
  },
};
</script>
