<template>
  <div class="row mb-2">
    <div class="col-6 d-md-none text-start">
      <!-- Mobile Filter Button -->
      <button
        class="btn btn-danger"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#mobileFilter"
        aria-controls="mobileFilter"
      >
        Filters
      </button>
    </div>

    <div class="col-6 col-md-12 text-end text-md-end">
      <!-- Sort By Button -->
      <button
        class="btn btn-danger dropdown-toggle mb-2 mb-md-0"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Sort by
      </button>
      <ul class="dropdown-menu">
        <li>
          <a
            class="dropdown-item"
            @click="activeSort = 'relevance'"
            :class="{ active: activeSort === 'relevance' }"
            href="#"
            >Relevance</a
          >
        </li>
        <li>
          <a
            class="dropdown-item"
            @click="activeSort = 'low to high price'"
            :class="{ active: activeSort === 'low to high price' }"
            href="#"
            >Price (Low to High)</a
          >
        </li>
        <li>
          <a
            class="dropdown-item"
            @click="activeSort = 'high to low price'"
            :class="{ active: activeSort === 'high to low price' }"
            href="#"
            >Price (High to Low)</a
          >
        </li>
        <!-- ... your dropdown items ... -->
      </ul>
    </div>
  </div>
  <!-- <div class="dropdown mb-2 text-end">
      <button
          class="btn btn-danger dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
      >
          Sort by
      </button>
      <ul class="dropdown-menu">
          <li><a class="dropdown-item" @click="activeSort ='relevance' " :class="{ 'active': activeSort === 'relevance' }" href="#">Relevance</a></li>
          <li><a class="dropdown-item" @click="activeSort ='low to high price' " :class="{ 'active': activeSort === 'low to high price' }" href="#">Price (Low to High)</a></li>
          <li><a class="dropdown-item" @click="activeSort ='high to low price' " :class="{ 'active': activeSort === 'high to low price' }" href="#">Price (High to Low)</a></li>
      </ul>
  </div> -->
  <div v-for="product in paginatedProducts" :key="product.product_id">
    <div class="card mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-md-2 p-2">
            <img
              v-if="product.product_image_path"
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="200"
              height="200"
              :src="product.product_image_path"
              role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            />
            <img
              v-else
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="200"
              height="200"
              src="https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
              role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            />
          </div>
          <div class="col-md-8">
            <h4 class="card-title">
              <a
                class="text-black"
                :href="`/products/${$route.params.category_id}/${product.product_id}`"
                style="text-decoration: none"
                >{{ product.name }}</a
              >
            </h4>
            <p class="card-text">{{ product.product_description }}</p>
          </div>
          <div class="col-md-2">
            <p class="card-text">
              <b> ₹ {{ $parsePriceToString(product.price_with_tax) }} </b>
              <a @click="addToCart(product)">
                <button class="btn btn-outline-success">Add To Cart</button>
              </a>

              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" href="#" @click.prevent="currentPage--">
          <i class="bi bi-arrow-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        v-for="page in totalPages"
        :key="page"
        :class="{ active: page === currentPage }"
      >
        <a class="page-link" href="#" @click.prevent="currentPage = page">{{
          page
        }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="#" @click.prevent="currentPage++">
          <i class="bi bi-arrow-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      activeSort: "relevance",
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  props: {
    products: Array,
  },
  computed: {
    sortedProducts() {
      if (this.activeSort === "low to high price") {
        return this.products
          .slice()
          .sort((a, b) => a.price_with_tax - b.price_with_tax);
      } else if (this.activeSort === "high to low price") {
        return this.products
          .slice()
          .sort((a, b) => b.price_with_tax - a.price_with_tax);
      } else {
        return this.products;
      }
    },
    totalPages() {
      return Math.ceil(this.sortedProducts.length / this.itemsPerPage);
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedProducts.slice(start, end);
    },
  },
  methods: {
    async addToCart(product) {
      const productId = product.product_id;
      if (this.$store.state.user.isAuthenticated) {
        this.$store.commit("setIsLoading", true);

        await axiosInstance
          .post(`/orders/add-to-cart-product-list/${productId}/`)
          .then((response) => {
            this.$notify({
              title: "Item added to cart successfully",
              type: "bg-success-subtle text-success",
              duration: "5000",
            });
            this.$router.push("/my-cart");
            console.log(response.data);
            localStorage.setItem(
              "userCart",
              JSON.stringify(response.data.updated_cart.order_items)
            );
            this.$store.commit(
              "updateUserCart",
              response.data.updated_cart.order_items
            );
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              title: "An error occured, please try again",
              type: "bg-danger-subtle text-danger",
              duration: "5000",
            });
          });

        this.$store.commit("setIsLoading", false);
      }
      let defaultCustomizations = [];
      for (let customization of product.product_default_customizations) {
        defaultCustomizations.push({
          option: customization,
          selected_quantity: customization.default_quantity,
        });
      }
      const cartItem = {
        product: product,
        quantity: 1,
        customization_id: 0,
        customizations: defaultCustomizations,
        customizedPrice: product.price_with_tax,
      };

      let existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];

      const existingCartItem = existingCart.find(
        (item) =>
          item.customization_id === cartItem.customization_id &&
          item.product.product_id === cartItem.product.product_id
      );

      if (existingCartItem) {
        existingCartItem.quantity += 1;
      } else {
        existingCart.push(cartItem);
      }

      sessionStorage.setItem("cart", JSON.stringify(existingCart));
      this.$store.commit("updateCart", existingCart);
      if (this.$store.state.user.isAuthenticated == false) {
        this.$notify({
          title: "Item added to cart successfully",
          type: "bg-success-subtle text-success",
          duration: "5000",
        });
        this.$router.push("/my-cart");
      }
    },
  },
};
</script>

<style scoped>
.dropdown-item:hover {
  background-color: #db4c5a;
  color: whitesmoke;
}

.dropdown-menu .dropdown-item.active {
  background-color: #db4c5a;
}

.pagination .active .page-link {
  background-color: #dc3545;
  border-color: #dc3545;
}

.pagination .page-link {
  color: black;
}

.pagination .page-item.active .page-link {
  color: white;
  background-color: #db4c5a;
  border-color: #db4c5a;
}

.pagination .page-item.disabled .page-link {
  color: #6c757d;
}

.pagination .page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); /* Red outline */
  outline: none; /* Remove default outline */
}
</style>
