<template>
  <div class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home</a
          >
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <b>
            <a class="text-secondary" herf="#">
              <i class="bi bi-check"></i>
              <!-- Order Processing -->
              {{
                utrNumbers.length > 0 ||
                order.fulfillment_status === "Confirmed"
                  ? "Order Confirmation"
                  : " Order Processing"
              }}
            </a>
          </b>
        </li>
      </ol>
    </nav>
    <div class="d-flex align-items-center justify-content-between">
      <h1>
        {{
          order.fulfillment_status === "Confirmed"
            ? "Order Confirmation"
            : " Order Processing"
        }}
      </h1>
      <div class="d-flex">
        <button
          v-if="!order.po_number"
          class="btn btn-primary btn-outlined me-2"
          data-bs-toggle="modal"
          data-bs-target="#OpenPOModal"
        >
          Upload PO
        </button>
        <button
          v-if="order.po_number"
          class="btn btn-primary btn-outlined me-2"
          data-bs-toggle="modal"
          data-bs-target="#OpenPOModal"
        >
          Uploaded PO
        </button>

        <button
          v-if="order.po_number > 0"
          class="btn btn-info btn-outlined me-2"
          data-bs-toggle="modal"
          data-bs-target="#OpenShipmentModal"
        >
          {{ utrNumbers.length > 0 ? "Uploaded UTRs" : "Upload UTR" }}
        </button>
        <button
          v-if="
            (order.payment_method === 1 &&
              utrNumbers.length > 0 &&
              order.fulfillment_status !== 'Confirmed') ||
            (order.payment_method === 2 &&
              utrNumbers.length > 0 &&
              order.fulfillment_status !== 'Confirmed') ||
            (order.payment_method === 6 &&
              order.po_number &&
              order.fulfillment_status !== 'Confirmed')
          "
          class="btn btn-success btn-outlined me-2"
          data-bs-toggle="modal"
          data-bs-target="#OpenconfirmOrderModal"
        >
          Confirm Order
        </button>
        <button
          v-if="
            (order.payment_method === 1 &&
              utrNumbers.length > 0 &&
              order.payment_status !== 'Success') ||
            (order.payment_method === 2 &&
              utrNumbers.length > 1 &&
              order.fulfillment_status === 'Confirmed' &&
              order.payment_status !== 'Success') ||
            (order.payment_method === 6 &&
              utrNumbers.length > 0 &&
              order.payment_status !== 'Success')
          "
          class="btn btn-success btn-outlined"
          data-bs-toggle="modal"
          data-bs-target="#OpenconfirmtModal"
        >
          Complete Payment
        </button>
      </div>
    </div>

    <hr />
    <div v-if="$store.state.isLoading" class="conatiner">
      <LoadingBar />
    </div>
    <div v-else class="container">
      <div class="text-center">
        <strong>
          <h3>
            <i
              v-if="order.fulfillment_status === 'Confirmed'"
              class="text-success bi bi-bag-check-fill"
            ></i>
            <i v-else class="text-primary bi bi-hourglass-split"></i>

            {{
              order.fulfillment_status === "Confirmed"
                ? "Thank You for placing an order"
                : " Your Order is in Processing"
            }}
          </h3>
        </strong>
        <h6 class="text-muted">
          We'll send you another email when your order ships.
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <h4 class="d-flex justify-content-between align-items-center">
          Order Details
        </h4>
        <!-- <ConfirmedOrderItemsList v-if="order" :order="order" /> -->

        <ProcessingOrderItemList v-if="order" :order="order" />

        <div class="row">
          <div v-if="shippingAddress" class="col-6">
            <h5>Shipping Info</h5>
            <div>
              <strong>
                {{ shippingAddress.first_name }}
                {{ shippingAddress.last_name }}
              </strong>
            </div>
            <div>
              {{ shippingAddress.email }}
            </div>
            <div>
              {{ shippingAddress.phone_number }}
            </div>
            <div>
              {{ shippingAddress.street_address_1 }}
            </div>
            <div>
              {{ shippingAddress.street_address_2 }}
            </div>
            <div>
              {{ shippingAddress.city }}
            </div>
            <div>
              {{ shippingAddress.state }}
            </div>
            <div>
              {{ shippingAddress.postal_code }}
            </div>
          </div>
          <div v-if="billingAddress" class="col-6">
            <h5>Billing Info</h5>
            <div>
              <strong>
                {{ billingAddress.first_name }}
                {{ billingAddress.last_name }}
              </strong>
            </div>
            <div>
              {{ billingAddress.email }}
            </div>
            <div>
              {{ billingAddress.phone_number }}
            </div>
            <div>
              {{ billingAddress.street_address_1 }}
            </div>
            <div>
              {{ billingAddress.street_address_2 }}
            </div>
            <div>
              {{ billingAddress.city }}
            </div>
            <div>
              {{ billingAddress.state }}
            </div>
            <div>
              {{ billingAddress.postal_code }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <h4 class="d-flex justify-content-between align-items-center">
          <span>Order Summary</span>
        </h4>
        <ConfirmedOrderBillSummary v-if="order" :order="order" />
        <h4 class="d-flex justify-content-between align-items-center">
          <span>Payment Information</span>
        </h4>
        <div>
          <div>
            Payment Status:

            {{ order.payment_status }}
            <br />
            Payment Method:
            {{ paymentMethod }}
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="OpenPOModal"
      tabindex="-1"
      aria-labelledby="OpenPOModal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="OpenPOModalLabel">
              {{
                order.po_number
                  ? "Uploaded PO Details"
                  : "Purchase Order Details"
              }}
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" v-if="!order.po_number">
            <!-- Enter PO number field -->
            <div class="mb-3">
              <label for="poNumber" class="form-label">Enter PO number</label>
              <input
                type="text"
                class="form-control"
                id="poNumber"
                v-model="poNumber"
              />
            </div>
            <!-- Upload file field -->
            <div class="mb-3">
              <label for="fileUpload" class="form-label">Upload file</label>
              <input
                type="file"
                class="form-control"
                id="fileUpload"
                @change="onFileChange"
              />
            </div>
          </div>
          <div class="modal-body" v-else>
            <div>
              <strong>PO Number:</strong> {{ order.po_number }}
              <!-- Download button -->
              <button
                class="btn btn-primary btn-outlined"
                style="margin-left: 50%"
                @click="downloadPOFile"
              >
                <i class="bi bi-download"></i>
              </button>
            </div>
            <!-- Add other details as needed -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              v-if="!order.po_number"
              type="button"
              class="btn btn-primary"
              @click="updateOrderWithPO"
              data-bs-dismiss="modal"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Shipment Modal -->

    <div
      class="modal fade"
      id="OpenShipmentModal"
      tabindex="-1"
      aria-labelledby="OpenShipmentModal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="OpenShipmentModalLabel">
              UTR Number Details
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="utrNumbers && utrNumbers.length > 0">
              <h5>Uploaded UTR Numbers:</h5>
              <ul>
                <li v-for="utr in utrNumbers" :key="utr.id">
                  UTR Number: {{ utr.utr_number }}
                  <!-- Download button -->
                  <button
                    class="btn btn-primary"
                    style="margin-left: 30%"
                    @click="downloadUTRFile(utr)"
                  >
                    <i class="bi bi-download"></i>
                  </button>
                </li>
              </ul>
            </div>

            <div v-else>No UTR numbers available.</div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="openAddUTRModal"
            >
              Add New UTR
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Add UTR Modal -->
    <div
      class="modal fade"
      id="AddUTRModal"
      tabindex="-1"
      aria-labelledby="AddUTRModal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="AddUTRModalLabel">
              Add UTR Number
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <UTRNumberDetails v-if="utrNumbers" :utrNumbers="utrNumbers" />
          </div>
          <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="addUTR">Save</button>
                    </div> -->
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="OpenconfirmtModal"
      tabindex="-1"
      aria-labelledby="OpenconfirmtModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="OpenconfirmtModalLabel">
              Confirmation
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Are you sure you want to complete the payment?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="completePayment"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="OpenconfirmOrderModal"
      tabindex="-1"
      aria-labelledby="OpenconfirmOrderModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="OpenconfirmOrderModalLabel">
              Confirmation
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Are you sure you want to confirm the order?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button type="button" class="btn btn-primary" @click="confirmOrder">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmedOrderBillSummary from "@/components/Orders/ConfirmedOrderBillSummary.vue";
// import ConfirmedOrderItemsList from "@/components/Orders/ConfirmedOrderItemsList.vue";
import LoadingBar from "@/components/layout/LoadingBar.vue";

import ProcessingOrderItemList from "@/components/Orders/ProcessingOrderItemList.vue";
import UTRNumberDetails from "@/components/Orders/UTRNumberDetails.vue";

export default {
  data() {
    return {
      order: "",
      shippingAddress: [],
      billingAddress: "",
      paymentInfo: "",
      poNumber: "",
      poFile: null,
      utrNumbers: [],
      status: false,
    };
  },
  mounted() {
    this.getOrderProcessingData();
  },
  components: {
    // ConfirmedOrderItemsList,
    ConfirmedOrderBillSummary,
    LoadingBar,
    ProcessingOrderItemList,
    UTRNumberDetails,
  },
  computed: {
    showUploadPOButton() {
      return !this.order.po_number;
    },
    paymentMethod() {
      if (this.order.payment_method === 1) {
        return "100% Payment";
      } else if (this.order.payment_method === 2) {
        return "50% Advance";
      } else {
        return "100% Credit";
      }
    },
  },
  methods: {
    // async getOrderConfirmation() {
    //     if (this.$store.state.user.isAuthenticated) {
    //         this.$store.commit("setIsLoading", true);

    //         await axiosInstance
    //             .get(
    //                 `orders/get-order-confirmation/${this.$route.params.order_number}/`
    //             )
    //             .then((response) => {
    //                 console.log(response.data);
    //                 this.order = response.data.order;
    //                 this.shippingAddress = this.order.order_addresses.filter(
    //                     (address) => address.address_type == "Shipping"
    //                 )[0];
    //                 this.billingAddress = this.order.order_addresses.filter(
    //                     (address) => address.address_type == "Billing"
    //                 )[0];
    //                 this.paymentInfo = response.data.payment_info;
    //                 this.$store.commit("setIsLoading", false);
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 this.$store.commit("setIsLoading", false);
    //             });
    //     }
    // },

    async confirmOrder() {
      try {
        const orderId = this.$route.params.orderId;

        await axiosInstance.put(`/orders/confirm_order/${orderId}/`);

        this.$notify({
          type: "bg-success-subtle text-success",
          title: "Success",
          text: "Order get confirmed successfully.",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error("Error completing payment:", error);
      }
    },
    async getOrderProcessingData() {
      const orderId = this.$route.params.orderId;

      if (this.$store.state.user.isAuthenticated) {
        this.$store.commit("setIsLoading", true);

        try {
          const response = await axiosInstance.get(
            `/orders/get_order_processing_data/${orderId}/`
          );
          console.log("The order data:", response.data);

          const { order, utr_numbers } = response.data;

          this.order = order;

          // Set shipping and billing addresses
          if (order && order.order_addresses) {
            this.shippingAddress =
              order.order_addresses.length > 0
                ? order.order_addresses[0]
                : null;
            this.billingAddress =
              order.order_addresses.length > 0
                ? order.order_addresses[0]
                : null;
          }

          this.paymentInfo = order.payment_info;

          this.utrNumbers = utr_numbers;

          this.$store.commit("setIsLoading", false);

          console.log("The shipping address:", this.shippingAddress);
        } catch (error) {
          console.error(error);
          this.$store.commit("setIsLoading", false);
        }
      }
    },

    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    async updateOrderWithPO() {
      const orderId = this.$route.params.orderId;
      const formData = new FormData();
      formData.append("po_number", this.poNumber);
      if (this.poFile) {
        formData.append("po_file", this.poFile);
      }
      try {
        const response = await axiosInstance.put(
          `/orders/update_order_with_po/${orderId}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.$notify({
          type: "bg-success-subtle text-success",
          title: "Success",
          text: " PO Details Uploaded Successfully.",
        });
        console.log("Order updated successfully", response.data);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error("Error updating order:", error);
      }
    },
    onFileChange(event) {
      this.poFile = event.target.files[0];
    },

    async completePayment() {
      try {
        const orderId = this.$route.params.orderId;

        await axiosInstance.post(`/orders/complete_payment/${orderId}/`);

        this.$notify({
          type: "bg-success-subtle text-success",
          title: "Success",
          text: "Payment completed successfully.",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error("Error completing payment:", error);
        // Handle error if needed
      }
    },

    openAddUTRModal() {
      // Reset the input field
      this.newUTRNumber = "";
      // Open the modal
      $("#AddUTRModal").modal("show");
    },

    addUTR() {
      // Add the new UTR number to the list
      this.utrNumbers.push({ number: this.newUTRNumber });
      // Close the modal
      $("#AddUTRModal").modal("hide");
    },
    async downloadPOFile() {
      if (this.order.po_file_path) {
        try {
          // Open the file in the browser instead of downloading
          window.open(this.order.po_file_path, "_blank");
        } catch (error) {
          console.error("Error opening PO file:", error);
        }
      } else {
        // Handle case when PO file path is not available
        console.error("PO file path not found.");
      }
    },

    async downloadUTRFile(utrNumber) {
      if (utrNumber && utrNumber.utr_file_path) {
        try {
          window.open(utrNumber.utr_file_path, "_blank");
        } catch (error) {
          console.error("Error opening UTR file:", error);
        }
      } else {
        console.error("UTR file path not found.");
      }
    },
  },
};
</script>
