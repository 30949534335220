<template>
  <div class="container">
    <div class="row align-items-center py-2">
      <div class="col md-6">
        <div class="d-flex align-items-center">
          <h2 class="mb-0">Order List</h2>
          <span class="ms-3 fs-4 text-muted"> | </span>
          <nav aria-label="breadcrumb" class="d-inline-block ms-3">
            <ol class="breadcrumb m-0 p-0">
              <li class="breadcrumb-item">
                <a class="text-secondary" href="/admin">
                  <i class="bi bi-house"></i>
                  Home
                </a>
              </li>
              <li class="breadcrumb-item">
                <a class="text-secondary" href="/admin/orderlist">
                  <b> Order List </b>
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <hr />
    <div class="row d-flex justify-content-center">
      <div class="col">
        <div class="info-box">
          <span class="info-box-icon"
            ><i class="fa fa-calendar" aria-hidden="true"></i
          ></span>
          <div class="info-box-content">
            <span class="info-box-text">Confirmed</span>
            <span class="info-box-number">{{ this.confirmedCount }}</span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="info-box">
          <span class="info-box-icon"><i class="fa fa-check-double"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Delivered</span>
            <span class="info-box-number">{{ this.deliveredCount }}</span>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="info-box">
          <span class="info-box-icon"><i class="fa fa-wallet"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Shipped</span>
            <span class="info-box-number">{{ this.shippedCount }}</span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="info-box">
          <span class="info-box-icon"
            ><i class="fa fa-exclamation-circle"></i
          ></span>
          <div class="info-box-content">
            <span class="info-box-text">Failed</span>
            <span class="info-box-number">{{ this.failedCount }}</span>
          </div>
        </div>
      </div>
    </div>

    <AdminOrderTable
      :orderList="allOrders"
      @updatedStatusSummary="handleUpdatedStatusSummary"
    />
  </div>
</template>

<script>
import AdminOrderTable from "@/components/Admin/AdminOrderTable.vue";

export default {
  components: {
    AdminOrderTable,
  },

  data() {
    return {
      allOrders: [],
      confirmedCount: 0,
      deliveredCount: 0,
      shippedCount: 0,
      failedCount: 0,
    };
  },
  methods: {
    getAllOrders() {
      axiosInstance
        .get("orders/get-all-orders/")
        .then((response) => {
          this.allOrders = response.data;
          console.log("allOrders", this.allOrders);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleUpdatedStatusSummary(updatedStatusSummary) {
      // Handle the updated status summary
      this.confirmedCount = updatedStatusSummary.confirmed;
      this.deliveredCount = updatedStatusSummary.delivered;
      this.shippedCount = updatedStatusSummary.shipped;
      this.failedCount = updatedStatusSummary.failed;

      console.log(
        "Updated Status Summary in AdminOrderList:",
        updatedStatusSummary
      );
    },
  },
  mounted() {
    this.getAllOrders();
  },
};
</script>

<style scoped>
.container-wrapper {
  padding: 15px;
}
.breadcrumb {
  background-color: whitesmoke;
}
</style>
