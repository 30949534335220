<template>
  <div class="main">
    <ul>
      <li v-for="(step, index) in steps" :key="index">
        <div :class="['step', step.class, { active: index <= currentStep }]">
          <p v-if="index !== currentStep">{{ index + 1 }}</p>
          <i v-if="index < currentStep" class="bi bi-check2-circle" style="margin-left: 5%; color: whitesmoke;"></i>
        </div>
        
        <div class="step-info">
          <i :class="['bi', step.icon]"></i>
          <p class="label">{{ step.label }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>

/* .main {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

ul {
  display: flex;
}

ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 40px;
}

ul li .bi {
  font-size: 25px;
  color: #db4c5a;
  margin: 0 10px;
  margin-bottom: 10%; /* Reduced margin */
  
}

ul li .label {
  font-family: sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #db4c5a;
  white-space: nowrap; /* Ensure text remains on a single line */
}

ul li .step {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #db4c5a;
  margin: 16px 0 10px;
  display: grid;
  place-items: center;
  color: ghostwhite;
  position: relative;
  cursor: pointer;
}

.step::after {
  content: "";
  position: absolute;
  width: 220px;
  height: 3px;
  background-color:  #db4c5a;
  right: 30px;
}

.first::after {
  width: 0;
  height: 0;
}

ul li .step .bi {
  display: none;
}

ul li .step p {
  font-size: 18px;
}

ul li .active {
  background-color:  #db4c5a;
}

li .active::after {
  background-color:  #db4c5a;
}

ul li .active p {
  display: none;
}

ul li .active .bi {
  display: flex;
}

/* New styles */
.step-info {
  display: flex;
  align-items: center;
  
}
</style>
