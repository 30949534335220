<template>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="firstName">First Name *</label>
        <input
          type="text"
          class="form-control"
          id="firstName"
          v-model="editAddressForm.first_name"
          required
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="lastName">Last Name *</label>
        <input
          type="text"
          class="form-control"
          id="lastName"
          v-model="editAddressForm.last_name"
          required
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="phoneNumber">Phone Number*</label>
        <input
          type="text"
          class="form-control"
          id="phoneNumber"
          v-model="editAddressForm.phone_number"
          required
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="email">E-mail*</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="editAddressForm.email"
          required
        />
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="addressLine1">Address Line 1 *</label>
    <input
      type="text"
      class="form-control"
      id="addressLine1"
      v-model="editAddressForm.street_address_1"
      required
    />
  </div>
  <div class="form-group">
    <label for="addressLine2">Address Line 2</label>
    <input
      type="text"
      class="form-control"
      id="addressLine2"
      v-model="editAddressForm.street_address_2"
    />
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="city">City *</label>
        <input
          type="text"
          class="form-control"
          id="city"
          v-model="editAddressForm.city"
          required
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="state">State *</label>
        <input
          type="text"
          class="form-control"
          id="state"
          v-model="editAddressForm.state"
          required
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="AddressType">Postal Code*</label>
        <input
          type="text"
          class="form-control"
          id="postalCode"
          v-model="editAddressForm.postal_code"
          required
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="AddressType">Address Type*</label>
        <select
          class="form-control"
          id="addressType"
          v-model="editAddressForm.address_type"
        >
          <option value="Billing">Billing</option>
          <option value="Shipping">Shipping</option>
          <option value="Customer">Customer</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["editAddressChanged"],
  props: {
    selectedAddress: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      editAddressForm: {
        street_address_1: "",
        street_address_2: "",
        city: "",
        state: "",
        address_type: "Shipping",
        postal_code: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
      },
    };
  },
  watch: {
    selectedAddress: {
      immediate: true,
      deep: true,
      handler(newSelectedAddress) {
        this.editAddressForm = { ...newSelectedAddress };
      },
    },
    editAddressForm: {
      deep: true,
      handler(editAddress) {
        this.$emit("editAddressChanged", editAddress);
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>
