<template>
    <div class="container mt-5">
        <h4 class="mb-0">Your Saved Cards</h4>
        <button class="btn btn-outline-success " data-bs-toggle="modal" data-bs-target="#addSavedCardsModal" >+ Add Cards</button>

    </div>
    <div class="container mt-3">
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-end mb-3">
          <div class="row">
            <div class="col-6">
          <button class="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#editSavedCardsModal"><i class="bi bi-pencil-square"></i></button>
            </div>
            <div class="col-6">
              <button class="btn btn-outline-danger" ><i class="bi bi-trash"></i></button>
            </div>
            </div>
        </div>           
        
            <form @submit.prevent="submitForm">
            <div class="form-group">
                <div class="col-md-4">
                <label for="card-type">Card Type:</label>
                <select class="form-control" v-model="selectedCardType" id="card-type">
                    <option value="Debit Card">Debit Card</option>
                    <option value="Credit Card">Credit Card</option>
                </select>
            </div>
            </div>
            <div class="row">
                <div class="col-md-6">
            <div class="form-group"> 
                <label for="card-holder-name">Card Holder Name:</label>
                <input type="text" class="form-control" id="card-holder-name" v-model="cardHolderName">
            </div>
            </div>
            <div class="col-md-6">
            <div class="form-group">
                <label for="bank-name">Bank Name:</label>
                <input type="text" class="form-control" id="bank-name" v-model="bankName">
            </div>
            </div>
            </div>
            <div class="row">
                <div class="col-md-6">
            <div class="form-group">
                <label for="card-number">Card Number</label>
                <input type="text" class="form-control" id="card-number" v-model="cardNumber" @input="maskCardNumber">
            </div>
            </div>
            <div class="col-md-6">
            <div class="form-group">
                <label for="expiry-date">Expiry Date:</label>
                <input type="text" class="form-control" id="expiry-date" v-model="expiryDate">
            </div>
            </div>
            </div>
            <br>
            <button type="submit" class="btn btn-outline-success" >Submit</button>
            
        </form>
        </div>
    </div>
    </div>

  <div class="modal fade" id="addSavedCardsModal" tabindex="-1" aria-labelledby="addSavedCardsModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addSavedCardsModalLabel">Edit Address</h5>
          <button type="button" class="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form @submit.prevent="AddsubmitForm">
            <div class="form-group">
                <div class="col-md-4">
                <label for="card-type">Card Type:</label>
                <select class="form-control" v-model="selectedCardType" id="card-type">
                    <option value="Debit Card">Debit Card</option>
                    <option value="Credit Card">Credit Card</option>
                </select>
            </div>
            </div>
            <div class="row">
                <div class="col-md-6">
            <div class="form-group"> 
                <label for="card-holder-name">Card Holder Name:</label>
                <input type="text" class="form-control" id="card-holder-name" v-model="cardHolderName">
            </div>
            </div>
            <div class="col-md-6">
            <div class="form-group">
                <label for="bank-name">Bank Name:</label>
                <input type="text" class="form-control" id="bank-name" v-model="bankName">
            </div>
            </div>
            </div>
            <div class="row">
                <div class="col-md-6">
            <div class="form-group">
                <label for="card-number">Card Number</label>
                <input type="text" class="form-control" id="card-number" v-model="cardNumber" @input="maskCardNumber">
            </div>
            </div>
            <div class="col-md-6">
            <div class="form-group">
                <label for="expiry-date">Expiry Date:</label>
                <input type="text" class="form-control" id="expiry-date" v-model="expiryDate">
            </div>
            </div>
            </div>
            
            <button type="submit" class="btn btn-outline-success" data-bs-dismiss="modal">Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>

  
  <div class="modal fade" id="editSavedCardsModal" tabindex="-1" aria-labelledby="editSavedCardsModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editSavedCardsModalLabel">Edit Address</h5>
          <button type="button" class="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form @submit.prevent="">
            <div class="form-group">
                <div class="col-md-4">
                <label for="card-type">Card Type:</label>
                <select class="form-control" v-model="selectedCardType" id="card-type">
                    <option value="Debit Card">Debit Card</option>
                    <option value="Credit Card">Credit Card</option>
                </select>
            </div>
            </div>
            <div class="row">
                <div class="col-md-6">
            <div class="form-group"> 
                <label for="card-holder-name">Card Holder Name:</label>
                <input type="text" class="form-control" id="card-holder-name" v-model="cardHolderName">
            </div>
            </div>
            <div class="col-md-6">
            <div class="form-group">
                <label for="bank-name">Bank Name:</label>
                <input type="text" class="form-control" id="bank-name" v-model="bankName">
            </div>
            </div>
            </div>
            <div class="row">
                <div class="col-md-6">
            <div class="form-group">
                <label for="card-number">Card Number</label>
                <input type="text" class="form-control" id="card-number" v-model="cardNumber" @input="maskCardNumber">
            </div>
            </div>
            <div class="col-md-6">
            <div class="form-group">
                <label for="expiry-date">Expiry Date:</label>
                <input type="text" class="form-control" id="expiry-date" v-model="expiryDate">
            </div>
            </div>
            </div>
            
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            
            <button type="submit" class="btn btn-outline-success" data-bs-dismiss="modal">Save</button>
            </form>
            
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default{
    data(){
        return{
            selectedCardType: 'Debit Card',
            cardHolderName: '',
            bankName: '',
            cardNumber: '',
            expiryDate: ''
        };
    },
    methods: {
            submitForm() {
                
            },
            maskCardNumber() {
                this.cardNumber = this.cardNumber.replace(/\D/g, '').slice(-4);
            }
        }
        
}
</script>