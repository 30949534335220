<template>
  <div class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary" href="#">
            <b>
              <i class="bi bi-person-check"></i>
              My Account
            </b>
          </a>
        </li>
      </ol>
    </nav>
    <h1>My Account - {{ capitalize(selectedSection) }}</h1>
    <hr />
    <div v-if="$store.state.isLoading">
      <LoadingBar />
    </div>
    <div v-else>
      <div class="container-fluid">
        <div class="row">
          <nav class="col-md-3 col-lg-2 d-md-block">
            <div class="position-sticky mt-2">
              <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#"
                    @click="showSection('orders')"
                    :class="{ active: selectedSection == 'orders' }"
                  >
                    <i class="bi bi-box"></i>
                    Orders
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#"
                    @click="showSection('profile')"
                    :class="{ active: selectedSection == 'profile' }"
                  >
                    <i class="bi bi-person"></i>
                    Profile Settings
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#"
                    @click="showSection('addresses')"
                    :class="{ active: selectedSection == 'addresses' }"
                  >
                    <i class="bi bi-map"></i>
                    Addresses
                  </a>
                </li>
                <!-- <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  @click="showSection('cards')"
                  :class="{ active: selectedSection == 'cards' }"
                >
                  <i class="bi bi-credit-card"></i>
                  Saved Cards
                </a>
              </li> -->
              </ul>
            </div>
          </nav>
          <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div v-if="selectedSection == 'orders'">
              <OrderList
                :orders="orders"
                :defaultRatingSystem="defaultRatingSystem"
              />
            </div>
            <div v-if="selectedSection == 'profile'">
              <ProfileSetting
                :profile="profile"
                @profileUpdated="handleProfileUpdated"
              />
            </div>
            <div v-if="selectedSection == 'addresses'">
              <button
                class="btn btn-outline-success my-2"
                data-bs-toggle="modal"
                data-bs-target="#addAddressModal"
              >
                Add Address
              </button>

              <AddressListWithOptions
                @editAddress="handleEditAddress"
                @deleteAddress="handleDeleteAddress"
                :addresses="addresses"
              />
            </div>
            <!-- <div v-if="selectedSection == 'cards'">
              <SaveCards />
            </div> -->
          </main>
        </div>
      </div>
      <div
        class="modal fade"
        id="addAddressModal"
        tabindex="-1"
        aria-labelledby="addAddressModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="addAddressModalLabel">
                Add Address
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <AddAddressForm @newAddressChanged="handleNewAddressChanged" />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                @click="saveNewAddress"
                type="button"
                class="btn btn-success"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="editAddressModal"
        tabindex="-1"
        aria-labelledby="editAddressModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="editAddressModalLabel">
                Edit Address
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <EditAddressForm
                :selected-address="selectedAddress"
                @editAddressChanged="handleEditAddressChanged"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                @click="saveEditAddress"
                type="button"
                class="btn btn-success"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="deleteAddressModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteAddressModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteAddressModalLabel">
                Delete Address
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete this address?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                @click="confirmDeleteAddress"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderList from "@/components/Orders/OrderList.vue";
import ProfileSetting from "@/components/Account/ProfileSetting.vue";
import SaveCards from "@/components/Orders/SaveCards.vue";
import AddAddress from "@/components/Account/AddAddress.vue";

import LoadingBar from "@/components/layout/LoadingBar.vue";
import AddressListWithOptions from "@/components/Account/AddressListWithOptions.vue";
import AddAddressForm from "@/components/Account/AddAddressForm.vue";
import EditAddressForm from "@/components/Account/EditAddressForm.vue";
export default {
  components: {
    OrderList,
    ProfileSetting,
    SaveCards,
    AddAddress,
    LoadingBar,
    AddressListWithOptions,
    AddAddressForm,
    EditAddressForm,
  },
  data() {
    return {
      showList: false,
      selectedSection: "orders",
      orders: [],
      addresses: [],
      profile: "",
      defaultRatingSystem: {},
      newAddress: {
        street_address_1: "",
        street_address_2: "",
        city: "",
        state: "",
        address_type: "",
        postal_code: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
      },
      editAddress: {
        street_address_1: "",
        street_address_2: "",
        city: "",
        state: "",
        address_type: "",
        postal_code: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
      },
      selectedAddress: {},
    };
  },
  mounted() {
    this.getMyAccountDetails();
  },
  methods: {
    async getMyAccountDetails() {
      this.$store.state.isLoading = true;
      await axiosInstance
        .get("accounts/get-my-account-details/")
        .then((response) => {
          console.log("response.data");
          console.log(response.data);
          this.orders = response.data.orders;
          this.profile = response.data.profile;
          this.addresses = response.data.user_addresses;
          this.defaultRatingSystem = response.data.default_rating_system;
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          // Check if the error status code is 401, 403, or 418
          if ([401, 403, 418].includes(error.response.status)) {
            // Do nothing, as we don't want to show error notification for these status codes
          } else {
            // For other status codes, show error notification
            this.$notify({
              title: "Something went wrong. Please try again",
              type: "bg-danger-subtle text-danger",
              duration: "5000",
            });
          }

          this.$store.state.isLoading = false;
        });
    },
    toggleList() {
      this.showList = !this.showList;
    },

    showSection(section) {
      this.selectedSection = section;
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    handleProfileUpdated(profile) {
      this.profile = profile;
      localStorage.setItem("firstName", profile.first_name);
      localStorage.setItem("lastName", profile.last_name);
      this.$store.commit("setUserDetails", profile);
    },
    handleNewAddressChanged(address) {
      this.newAddress = address;
    },
    handleEditAddressChanged(address) {
      this.editAddress = address;
    },
    async saveNewAddress() {
      this.$store.state.isLoading = true;

      await axiosInstance
        .post(`/accounts/create-user-address/`, this.newAddress)
        .then((response) => {
          console.log("Address created:", response.data);
          this.addresses.push(response.data.user_address);
          this.$store.state.isLoading = false;

          this.$notify({
            title: "Address added successfully",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
        })
        .catch((error) => {
          this.$store.state.isLoading = false;

          console.error("Error creating address:", error);
          this.$notify({
            title: "An error occured, please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },
    handleDeleteAddress(address) {
      this.selectedAddress = address;
    },
    handleEditAddress(address) {
      this.selectedAddress = address;
    },
    async saveEditAddress() {
      this.$store.state.isLoading = true;

      await axiosInstance
        .put(
          `/accounts/update-user-address/${this.editAddress.user_address_id}/`,
          this.editAddress
        )
        .then((response) => {
          console.log("Address edited:", response.data);
          this.addresses = response.data.user_addresses;
          this.selectedAddress = {};
          this.$store.state.isLoading = false;

          this.$notify({
            title: "Address updated successfully",
            type: "bg-secondary-subtle text-secondary",
            duration: "5000",
          });
        })
        .catch((error) => {
          this.$store.state.isLoading = false;

          console.error("Error creating address:", error);
          this.$notify({
            title: "An error occured, please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },
    async confirmDeleteAddress() {
      this.$store.state.isLoading = true;

      await axiosInstance
        .delete(
          `/accounts/delete-user-address/${this.selectedAddress.user_address_id}/`
        )
        .then((response) => {
          this.addresses = response.data.user_addresses;
          this.selectedAddress = {};
          this.$store.state.isLoading = false;

          this.$notify({
            title: "Address deleted successfully",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        })
        .catch((error) => {
          this.$store.state.isLoading = false;

          console.error("Error creating address:", error);
          this.$notify({
            title: "An error occured, please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },
  },
};
</script>
<style scoped>
.nav-link.active {
  color: white !important;
  background-color: #dc3545 !important;
}

.nav-link {
  color: black !important;
}

.nav-link:not(.active):hover {
  color: grey !important;
}
</style>
