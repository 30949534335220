<template>
  <div v-if="this.$store.state.user.isAuthenticated">
    <div class="container">
      <div class="row">
        <div class="col-3">Item</div>
        <div class="col-3"></div>
        <div class="col-3">Quantity</div>
        <div class="col-3">Price</div>
      </div>
      <hr />
      <div class="container">
        <div v-for="orderItem in order_items" :key="orderItem.order_item_id">
          <div class="row">
            <div class="col-3">
              <img
                v-if="orderItem.product && orderItem.product.product_image_path"
                class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                width="200"
                height="200"
                :src="orderItem.product.product_image_path"
                role="img"
                aria-label="Product Image"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              />
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="var(--bs-secondary-bg)" />
              <text
                x="50%"
                y="50%"
                fill="var(--bs-secondary-color)"
                dy=".3em"
              ></text>
            </div>
            <div class="col-3">
              <a
                v-if="orderItem.customization_id"
                class="h4"
                :href="`/products/${orderItem.product.product_category}/${orderItem.product.product_id}?customizationId=${orderItem.customization_id}`"
              >
                {{ orderItem.product.name }}
              </a>
              <a
                v-else
                class="h4"
                :href="`/products/${orderItem.product.product_category}/${orderItem.product.product_id}`"
              >
                {{ orderItem.product.name }}
              </a>
            </div>
            <div class="col-3">
              <div class="input-group">
                <button
                  v-if="getQuantity(orderItem) === 1"
                  @click="deleteItem(orderItem)"
                  type="button"
                  class="btn btn-outline-secondary"
                >
                  <i class="bi bi-trash"></i>
                </button>
                <button
                  v-else
                  @click="decrementQuantity(orderItem)"
                  type="button"
                  class="btn btn-outline-secondary"
                >
                  -
                </button>
                <span class="mx-2 my-2">{{ orderItem.quantity }}</span>
                <button
                  @click="incrementQuantity(orderItem)"
                  type="button"
                  class="btn btn-outline-secondary"
                >
                  +
                </button>
              </div>
            </div>

            <div class="col-3">
              ₹{{
                $parsePriceToString(
                  orderItem.product &&
                    parseFloat(orderItem.price) * orderItem.quantity
                )
              }}
            </div>
          </div>
          <div class="conatiner pt-4">
            <div
              class="accordion"
              :id="'accordionExampleTechSpecs' + orderItem.order_item_id"
              style="padding-left: 25%"
            >
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  :id="'headingTechSpecs' + orderItem.order_item_id"
                >
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="
                      '#collapseTechSpecs' + orderItem.order_item_id
                    "
                    aria-expanded="true"
                    :aria-controls="
                      'collapseTechSpecs' + orderItem.order_item_id
                    "
                  >
                    <span class="accordion-focus">
                      <b> Tech Specs </b>
                    </span>
                  </button>
                </h2>
                <div
                  :id="'collapseTechSpecs' + orderItem.order_item_id"
                  class="accordion-collapse collapse"
                  :aria-labelledby="
                    'headingTechSpecs' + orderItem.order_item_id
                  "
                  :data-bs-parent="
                    '#accordionExampleTechSpecs' + orderItem.order_item_id
                  "
                >
                  <div class="accordion-body" style="text-align: left">
                    <div
                      v-for="(
                        customizationOption, index
                      ) in orderItem.order_item_customization_options"
                      :key="index"
                    >
                      <p
                        v-if="
                          customizationOption.customization_option &&
                          customizationOption.customization_option
                            .customization_category
                        "
                      >
                        <b>{{
                          customizationOption.customization_option
                            .customization_category.name
                        }}</b>
                      </p>
                      <ul>
                        <li v-if="customizationOption.customization_option">
                          {{ customizationOption.customization_option.name }}
                          <span v-if="customizationOption.quantity > 1">
                            ( Qty: {{ customizationOption.quantity }})</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="container">
      <div class="row">
        <div class="col-3">Item</div>
        <div class="col-3"></div>
        <div class="col-3">Quantity</div>
        <div class="col-3">Price</div>
      </div>
      <hr />
      <div class="container">
        <div v-for="(orderItem, index) in order_items" :key="index">
          <div class="row">
            <div class="col-3">
              <img
                v-if="orderItem.product && orderItem.product.product_image_path"
                class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                width="200"
                height="200"
                :src="orderItem.product.product_image_path"
                role="img"
                aria-label="Product Image"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              />
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="var(--bs-secondary-bg)" />
              <text
                x="50%"
                y="50%"
                fill="var(--bs-secondary-color)"
                dy=".3em"
              ></text>
            </div>
            <div class="col-3">
              <a
                class="h4"
                :href="`/products/${orderItem.product.product_category}/${orderItem.product.product_id}?customizationId=${orderItem.customization_id}`"
              >
                {{ orderItem.product.name }}
              </a>
            </div>
            <div class="col-3">
              <div class="input-group">
                <button
                  v-if="getQuantity(orderItem) === 1"
                  @click="deleteItem(orderItem)"
                  type="button"
                  class="btn btn-outline-secondary"
                >
                  <i class="bi bi-trash"></i>
                </button>
                <button
                  v-else
                  @click="decrementQuantity(orderItem)"
                  type="button"
                  class="btn btn-outline-secondary"
                >
                  -
                </button>
                <span class="mx-2 my-2">{{ orderItem.quantity }}</span>
                <button
                  @click="incrementQuantity(orderItem)"
                  type="button"
                  class="btn btn-outline-secondary"
                >
                  +
                </button>
              </div>
            </div>

            <div class="col-3">
              ₹{{
                $parsePriceToString(
                  orderItem.product &&
                    parseFloat(orderItem.customizedPrice) * orderItem.quantity
                )
              }}
            </div>
          </div>
          <div class="conatiner pt-4">
            <div
              class="accordion"
              :id="'accordionExampleTechSpecs' + index"
              style="padding-left: 25%"
            >
              <div class="accordion-item">
                <h2 class="accordion-header" :id="'headingTechSpecs' + index">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapseTechSpecs' + index"
                    aria-expanded="true"
                    :aria-controls="'collapseTechSpecs' + index"
                  >
                    <span class="accordion-focus">
                      <b> Tech Specs </b>
                    </span>
                  </button>
                </h2>
                <div
                  :id="'collapseTechSpecs' + index"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'headingTechSpecs' + index"
                  :data-bs-parent="'#accordionExampleTechSpecs' + index"
                >
                  <div class="accordion-body" style="text-align: left">
                    <div
                      class="bg-light"
                      v-for="(
                        customizationOption, customizationIndex
                      ) in orderItem.customizations"
                      :key="customizationIndex"
                    >
                      <template
                        v-if="
                          customizationOption.option.customization_option
                            .customization_category_hierarchy == 3
                        "
                      >
                        <b>
                          {{
                            customizationOption.option.customization_option
                              .customization_category
                              .parent_customization_category.name
                          }}
                        </b>
                      </template>
                      <template v-else>
                        <b>
                          {{
                            customizationOption.option.customization_option
                              .customization_category.name
                          }}
                        </b>
                      </template>
                      <ul>
                        <template
                          v-if="
                            customizationOption.option.customization_option
                              .customization_category_hierarchy == 3
                          "
                        >
                          <li>
                            <b>
                              {{
                                customizationOption.option.customization_option
                                  .customization_category.name
                              }}
                            </b>
                            {{
                              customizationOption.option.customization_option
                                .name
                            }}
                            <span
                              v-if="customizationOption.selected_quantity > 1"
                            >
                              ( Qty:
                              {{ customizationOption.selected_quantity }})</span
                            >
                          </li>
                        </template>
                        <template v-else>
                          <li>
                            {{
                              customizationOption.option.customization_option
                                .name
                            }}
                            <span
                              v-if="customizationOption.selected_quantity > 1"
                            >
                              ( Qty:
                              {{ customizationOption.selected_quantity }})</span
                            >
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order_items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      quantityMap: {
        "paragraph-1": 1,
        "paragraph-2": 2,
      },
      orderItemID: null,

      productImageURL: "",
    };
  },
  mounted() {
    console.log("Hello", this.order_items);
  },

  methods: {
    decrementQuantity(orderItem) {
      if (orderItem.quantity > 1) {
        orderItem.quantity--;
        this.updateOrderItem(orderItem);
      }
    },
    incrementQuantity(orderItem) {
      orderItem.quantity++;
      this.updateOrderItem(orderItem);
    },
    getQuantity(orderItem) {
      return orderItem.quantity || 0;
    },

    async deleteItem(orderItem) {
      const orderItemID = orderItem.order_item_id;
      if (this.$store.state.user.isAuthenticated) {
        this.$store.commit("setIsLoading", true);
        await axiosInstance
          .delete(`orders/delete-order-item/${orderItemID}/`)
          .then((response) => {
            console.log(response.data);
            this.$emit("delete-item", orderItemID);
            this.$notify({
              title: "Cart Updated Successfuly",
              type: "bg-success-subtle text-success",
              duration: "5000",
            });
          })
          .catch((error) => {
            console.error("Error:", error);
            this.$notify({
              title: "An error occured, please try again later",
              type: "bg-danger-subtle text-danger",
              duration: "5000",
            });
          });
        this.$store.commit("setIsLoading", true);
      } else {
        this.$emit("delete-item", orderItemID);
        this.$notify({
          title: "Cart Updated Successfuly",
          type: "bg-success-subtle text-success",
          duration: "5000",
        });
      }
    },

    async updateOrderItem(orderItem) {
      if (this.$store.state.user.isAuthenticated) {
        this.$store.commit("setIsLoading", true);
        const orderItemID = orderItem.order_item_id;
        let data = {
          quantity: orderItem.quantity,
        };
        await axiosInstance
          .put(`orders/update-order-item/${orderItemID}/`, data)
          .then((response) => {
            console.log(response.data);
            localStorage.setItem("userCart", JSON.stringify(this.order_items));
            this.$store.commit("updateUserCart", this.order_items);
            this.$notify({
              title: "Cart Updated Successfuly",
              type: "bg-success-subtle text-success",
              duration: "5000",
            });
          })
          .catch((error) => {
            console.error("Error:", error);
            this.$notify({
              title: "An error occured, please try again later",
              type: "bg-danger-subtle text-danger",
              duration: "5000",
            });
          });
        this.$store.commit("setIsLoading", true);
      }
      sessionStorage.setItem("cart", JSON.stringify(this.order_items));
      this.$store.commit("updateCart", this.order_items);
      if (this.$store.state.user.isAuthenticated == false) {
        this.$notify({
          title: "Cart Updated Successfuly",
          type: "bg-success-subtle text-success",
          duration: "5000",
        });
      }
    },
  },
};
</script>
