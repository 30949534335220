<template>
  <div class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary">
            <b>
              <i class="bi bi-person"></i>
              Create Guest Accounts
            </b>
          </a>
        </li>
      </ol>
    </nav>
    <div class="row mt-3">
      <div class="col-7">
        <h3>Created Guest List</h3>
      </div>
      <div class="col-5">
        <div class="row">
          <div class="col-md-7">
            <input
              type="text"
              v-model="searchTerm"
              class="form-control"
              placeholder="Filter by name"
            />
          </div>
          <div class="col-md-5 d-flex justify-content-end">
            <button
              class="btn btn-outline-danger"
              @click="
                isEditMode = false;
                UpdateToUser(userId);
              "
              data-bs-toggle="modal"
              data-bs-target="#createAccountModal"
            >
              Create Account
            </button>
          </div>
        </div>
      </div>

      <div class="container mt-3">
        <ul class="list-unstyled">
          <li
            v-for="(account, index) in paginatedUsers"
            :key="index"
            class="mb-4"
          >
            <div class="card">
              <button
                @click="deleteUser(account.id)"
                class="btn btn-outline-danger"
                style="position: absolute; top: 10px; right: 10px"
              >
                <i class="bi bi-trash3"></i>
              </button>
              <button
                class="btn btn-outline-success"
                style="position: absolute; top: 10px; right: 60px"
                @click="
                  this.currentUserID = account.id;
                  isEditMode = true;
                  UpdateToUser(account.id);
                "
                data-bs-toggle="modal"
                data-bs-target="#createAccountModal"
              >
                <i class="bi bi-pencil-square"></i>
              </button>

              <div class="card-body">
                <h5 class="card-title">
                  {{ account.first_name }} {{ account.last_name }}
                </h5>
                <p class="card-text">
                  <strong>Email:</strong> {{ account.email }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div class="pagination mt-3">
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <button class="btn btn-outline-danger" @click="prevPage">
                <i class="bi bi-arrow-left"></i>
              </button>
            </li>
            <li
              class="page-item"
              v-for="page in pageCount"
              :key="page"
              :class="{ active: page === currentPage }"
            >
              <a
                class="page-link"
                href="#"
                @click.prevent="currentPage = page"
                >{{ page }}</a
              >
            </li>
            <li
              class="page-item"
              :class="{ disabled: currentPage === pageCount }"
            >
              <button class="btn btn-outline-danger" @click="nextPage">
                <i class="bi bi-arrow-right"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="modal fade"
        id="createAccountModal"
        tabindex="-1"
        aria-labelledby="createAccountModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title" id="createAccountModalLabel">
                {{ isEditMode ? "Edit Guest Account" : "Create Guest Account" }}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                class="row d-flex justify-content-center align-items-center h-100"
              >
                <div class="col-lg-12 col-xl-11">
                  <div class="card border-0" style="border-radius: 25px">
                    <div class="card-body md-9">
                      <div class="row justify-content-center">
                        <form class="mx-1 mx-md-4 needs-validation" novalidate>
                          <div>
                            <i class="fas fa-user fa-lg me-3 fa-fw"></i>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="input-group mb-3">
                                  <div class="form-floating">
                                    <input
                                      v-model="signUpForm.firstName"
                                      type="text"
                                      id="first_name"
                                      class="form-control"
                                      required
                                    />
                                    <label class="form-label" for="first_name"
                                      >First Name</label
                                    >
                                    <div class="invalid-feedback">
                                      Please provide your first name.
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="input-group mb-3">
                                  <div class="form-floating">
                                    <input
                                      v-model="signUpForm.lastName"
                                      type="text"
                                      class="form-control"
                                    />
                                    <label class="form-label">Last Name</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <i class="fas fa-envelope fa-lg me-3 fa-fw"></i>
                            <div class="form-floating mb-3">
                              <input
                                v-model="signUpForm.email"
                                type="email"
                                id="email"
                                class="form-control"
                                required
                              />
                              <label class="form-label" for="email"
                                >Your Email</label
                              >
                              <div class="invalid-feedback">
                                Please provide a valid email address.
                              </div>
                            </div>
                          </div>

                          <div
                            class="mb-3 form-check"
                            v-if="isEditMode === false"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="signUpForm.generatePassword"
                              id="generatePassword"
                            />
                            <label
                              class="form-check-label"
                              for="generatePassword"
                            >
                              Generate Auto-generated Password
                            </label>
                            <small class="text-muted ml-2">
                              (Uncheck to create password manually)
                            </small>
                          </div>

                          <div
                            v-if="
                              !signUpForm.generatePassword &&
                              isEditMode === false
                            "
                          >
                            <div class="mb-3">
                              <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                              <div class="form-floating mb-3">
                                <input
                                  v-model="signUpForm.password"
                                  type="password"
                                  id="password"
                                  class="form-control"
                                  required
                                />
                                <label
                                  class="form-label"
                                  for="password"
                                  autocomplete="off"
                                  >Password</label
                                >
                                <div class="invalid-feedback">
                                  Password is required.
                                </div>
                              </div>
                            </div>

                            <div class="mb-4">
                              <i class="fas fa-key fa-lg me-3 fa-fw"></i>
                              <div class="form-floating mb-3">
                                <input
                                  v-model="signUpForm.repeatPassword"
                                  type="password"
                                  id="repeat_pass"
                                  class="form-control"
                                  required
                                />
                                <label class="form-label" for="repeat_pass"
                                  >Repeat your password</label
                                >
                                <div
                                  :class="{
                                    'd-block':
                                      signUpForm.password !==
                                      signUpForm.repeatPassword,
                                  }"
                                  class="invalid-feedback"
                                >
                                  Passwords must match.
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="mb-4" v-if="isEditMode === false">
                            <div class="form-check">
                              <input
                                class="form-check-input me-2"
                                type="checkbox"
                                v-model="signUpForm.agreeTerms"
                                id="terms"
                                required
                              />
                              <label class="form-check-label" for="terms">
                                I agree to all statements in
                                <a href="#!">Terms of service</a>
                              </label>
                              <div class="invalid-feedback">
                                You must agree to the terms.
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      v-if="isEditMode"
                      type="button"
                      class="btn btn-outline-success"
                      @click="updateUserAccount(currentUserID)"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                    <button
                      v-else
                      type="button"
                      class="btn btn-outline-success"
                      @click="submitForm"
                      data-bs-dismiss="modal"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      signUpForm: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repeatPassword: "",
        generatePassword: true,
        agreeTerms: false,
      },
      userDetails: [],
      searchTerm: "",
      isEditMode: false,
      currentUserID: null,
      currentPage: 1,
      perPage: 7,
    };
  },
  computed: {
    filteredUsers() {
      const searchTerm = this.searchTerm.toLowerCase();
      if (this.userDetails.users) {
        return this.userDetails.users.filter(
          (user) =>
            user.first_name.toLowerCase().includes(searchTerm) ||
            user.last_name.toLowerCase().includes(searchTerm) ||
            user.email.toLowerCase().includes(searchTerm)
        );
      } else {
        return [];
      }
    },
    pageCount() {
      return Math.ceil(this.filteredUsers.length / this.perPage);
    },
    paginatedUsers() {
      const startIdx = (this.currentPage - 1) * this.perPage;
      const endIdx = startIdx + this.perPage;
      return this.filteredUsers.slice(startIdx, endIdx);
    },
  },

  methods: {
    UpdateToUser(userId) {
      if (this.isEditMode) {
        const userToUpdate = this.userDetails.users.find(
          (user) => user.id === userId
        );
        this.signUpForm = {
          firstName: userToUpdate.first_name,
          lastName: userToUpdate.last_name,
          email: userToUpdate.email,
        };
      } else {
        this.signUpForm = {
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          repeatPassword: "",
          generatePassword: true,
          agreeTerms: false,
        };
      }
    },

    submitForm() {
      const generatedPassword = this.signUpForm.generatePassword
        ? Math.random().toString(36).slice(-8)
        : this.signUpForm.password;

      const requestData = {
        email: this.signUpForm.email,
        password: generatedPassword,
        firstName: this.signUpForm.firstName,
        lastName: this.signUpForm.lastName,
        updated_by: this.$store.state.user.email,
      };

      axiosInstance
        .post("accounts/create-user/", requestData)
        .then((response) => {
          console.log("Account created:", response.data);
          window.location.reload();
          this.$notify({
            title:
              "Guest Account created successfully. Please log in to continue",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Something went wrong. Please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },
    getUser() {
      axiosInstance
        .get("accounts/get-guest-users-detail/")
        .then((response) => {
          this.userDetails = response.data;
          console.log("userDetail", this.userDetails);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateUserAccount(userId) {
      const updateData = {
        email: this.signUpForm.email,
        first_name: this.signUpForm.firstName,
        last_name: this.signUpForm.lastName,
      };
      axiosInstance
        .put(`accounts/update-user-account/${userId}/`, updateData)
        .then((response) => {
          console.log("User account updated:", response.data);
          this.currentUserID = null;
          window.location.reload();
          this.$notify({
            title:
              "Guest Account updated successfully. Please log in to continue",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
        })
        .catch((error) => {
          console.error("Error updating user account:", error);
          this.$notify({
            title: "Something went wrong. Please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },

    deleteUser(userId) {
      axiosInstance
        .delete(`accounts/delete-guest-user/${userId}/`)
        .then((response) => {
          window.location.reload();
          console.log("User deleted successfully", response.data);
          this.$notify({
            title:
              "Guest Account deleted successfully. Please log in to continue",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Something went wrong. Please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },
    setPage(pageNumber) {
      this.currentPage = pageNumber;
    },

    nextPage() {
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  mounted() {
    this.getUser();
  },

  watch: {
    accounts(newAccounts) {
      console.log("Accounts updated:", newAccounts);
    },
  },
};
</script>
