<template>
  <div class="list-group list-group-flush">
    <div
      v-for="(item, itemIndex) in orderItems"
      :key="itemIndex"
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      <div class="row">
        <div class="col">
          <img
            v-if="item.product.product_image_path"
            class="img-fluid"
            :src="item.product.product_image_path"
            :alt="item.product.name"
            width="100"
            height="100"
          />
        </div>
        <div class="col">
          <p class="mb-0 mt-4">
            <strong> {{ item.product.name }} </strong>
          </p>
        </div>
      </div>
      <div>
        <button
          class="btn btn-outline-secondary btn-sm"
          @click="$emit('orderItemSelected', item)"
          data-bs-toggle="modal"
          data-bs-target="#orderCustomizationModal"
        >
          View Details
        </button>
        <button class="btn btn-outline-warning btn-sm ms-1">Track</button>
        <button
          class="btn btn-outline-success btn-sm ms-1"
          data-bs-toggle="modal"
          data-bs-target="#addReviewModal"
          @click="$emit('orderItemSelected', item)"
        >
          Add Review
        </button>
        <button class="btn btn-outline-dark btn-sm ms-1">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  components: {},
};
</script>
