<template>
  <div class="container">
    <div class="row align-items-center py-2">
      <div class="col md-6">
        <div class="d-flex align-items-center">
          <h2 class="mb-0">Order Details</h2>
          <span class="ms-3 fs-4 text-muted"> | </span>
          <nav
            style="--bs-breadcrumb-divider: '>'"
            aria-label="breadcrumb"
            class="d-inline-block ms-3"
          >
            <ol class="breadcrumb m-0 p-0">
              <li class="breadcrumb-item">
                <a class="text-secondary" href="/">
                  <i class="bi bi-house"></i>
                  Home
                </a>
              </li>
              <li class="breadcrumb-item">
                <a class="text-secondary" href="/admin/orderlist">
                  <i class="bi bi-list-ul"></i>
                  Order List
                </a>
              </li>
              <li class="breadcrumb-item">
                <a class="text-secondary" href="#">
                  <b> Order Details</b>
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <div class="container mb-3 ms-1">
          <div
            v-for="orderItem in ordersItems.order_items"
            :key="orderItem.order_item_id"
            class="row"
          >
            <div class="card mb-3">
              <div class="row g-0">
                <div class="col-md-3">
                  <h4 class="fs-5">#{{ ordersItems.order_number }}</h4>
                  <br />
                  <img
                    v-if="
                      orderItem.product && orderItem.product.product_image_path
                    "
                    class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                    width="100"
                    height="100"
                    :src="orderItem.product.product_image_path"
                    role="img"
                    aria-label="Product Image"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false"
                  />
                </div>
                <div class="col-md-9">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <h5 class="card-title">
                          {{ orderItem.product.name }}
                        </h5>
                      </div>
                      <div class="col-md-4">
                        <p class="card-text">
                          Quantity: {{ orderItem.quantity }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p class="card-text">
                          ₹
                          {{
                            $parsePriceToString(
                              orderItem.price * orderItem.quantity
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="conatiner pt-3"
                  style="width: 30vw; margin-left: 20%; margin-bottom: 3%"
                >
                  <div
                    class="accordion"
                    :id="'accordionExampleTechSpecs' + orderItem.order_item_id"
                  >
                    <div
                      class="accordion-item"
                      v-for="(
                        customizationOption, index
                      ) in orderItem.order_item_customization_options"
                      :key="index"
                    >
                      <h2
                        class="accordion-header"
                        :id="
                          'headingTechSpecs' +
                          orderItem.order_item_id +
                          '-' +
                          index
                        "
                      >
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="
                            '#collapseTechSpecs' +
                            orderItem.order_item_id +
                            '-' +
                            index
                          "
                          aria-expanded="true"
                          :aria-controls="
                            'collapseTechSpecs' +
                            orderItem.order_item_id +
                            '-' +
                            index
                          "
                        >
                          <span class="accordion-focus">
                            <b>Tech Specs</b>
                          </span>
                        </button>
                      </h2>
                      <div
                        :id="
                          'collapseTechSpecs' +
                          orderItem.order_item_id +
                          '-' +
                          index
                        "
                        class="accordion-collapse collapse"
                        :aria-labelledby="
                          'headingTechSpecs' +
                          orderItem.order_item_id +
                          '-' +
                          index
                        "
                        :data-bs-parent="
                          '#accordionExampleTechSpecs' + orderItem.order_item_id
                        "
                      >
                        <div class="accordion-body" style="text-align: left">
                          <div class="bg-light">
                            <b
                              v-if="
                                customizationOption.customization_option
                                  .customization_category_hierarchy == 3
                              "
                            >
                              {{
                                customizationOption.customization_option
                                  .customization_category
                                  .parent_customization_category.name
                              }}
                            </b>
                            <b v-else>
                              {{
                                customizationOption.customization_option
                                  .customization_category.name
                              }}
                            </b>
                            <ul>
                              <li>
                                <b
                                  v-if="
                                    customizationOption.customization_option
                                      .customization_category_hierarchy == 3
                                  "
                                >
                                  {{
                                    customizationOption.customization_option
                                      .customization_category.name
                                  }}
                                </b>
                                {{
                                  customizationOption.customization_option.name
                                }}
                                <span
                                  v-if="
                                    customizationOption.selected_quantity > 1
                                  "
                                >
                                  ( Qty:
                                  {{ customizationOption.selected_quantity }})
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4 ms-1">
          <div class="card-body">
            <h4 class="fs-5">Shipping activity :</h4>
            <div class="container mt-5">
              <AdminShippingTimeline :timelineArray="shippingArray" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="fs-5 mb-2">Customer details :</h4>

            <template v-if="this.ordersItems.user_account">
              <p class="fs-6">
                <b>Name:</b> {{ this.ordersItems.user_account.first_name }}
                {{ this.ordersItems.user_account.last_name }}
              </p>
              <p class="fs-6">
                <b>Email:</b> {{ this.ordersItems.user_account.email }}
              </p>
            </template>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body">
            <h4 class="fs-5">Shipping address :</h4>
            <template
              v-for="(address, index) in this.ordersItems.order_addresses"
              :key="index"
            >
              <p class="fs-6" v-if="address.address_type === 'Shipping'">
                {{ address.street_address_1 }} {{ address.street_address_2 }}
                <br />
                {{ address.city }} , {{ address.state }}
                <br />
                {{ address.postal_code }}
              </p>
            </template>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body">
            <h4 class="fs-5">Billing address :</h4>

            <template
              v-for="(address, index) in this.ordersItems.order_addresses"
              :key="index"
            >
              <p class="fs-6" v-if="address.address_type === 'Billing'">
                {{ address.street_address_1 }} {{ address.street_address_2 }}
                <br />
                {{ address.city }} , {{ address.state }}
                <br />
                {{ address.postal_code }}
              </p>
            </template>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body">
            <h4 class="mb-3 fs-5">Order Billing :</h4>
            <template
              v-for="(billing, index) in this.ordersItems.order_bills"
              :key="index"
            >
              <!-- <p><b>Billing Id:</b> {{ billing.order_bill_id }}</p> -->

              <p><b>Tax Percentage:</b> {{ billing.tax_percentage }}%</p>

              <p><b>Tax Amount:</b> {{ billing.tax_amount }}</p>

              <p><b>Sub Total:</b> {{ billing.sub_total }}</p>

              <p><b>Deliver Carge:</b> {{ billing.shipping_handling }}</p>

              <p><b>Total Price:</b> {{ billing.total }}</p>

              <p>
                <b>Billing Date:</b>
                {{
                  new Date(billing.updated_at).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminShippingTimeline from "./AdminShippingTimeline.vue";

export default {
  data() {
    return {
      ordersItems: {},
      shippingArray: [
        {
          heading: "Our company starts its operations",
          date: "11 March 2020",
          description: "This is the first entry description.",
        },
        {
          heading: "First customer",
          date: "19 March 2020",
          description: "This is the second entry description.",
        },
        {
          heading: "Our team exceeds 10 people",
          date: "24 June 2020",
          description: "This is the third entry description",
        },
        {
          heading: "Earned the first million $!",
          date: "15 October 2020",
          description: "This is the fourth entry description",
        },
      ],
    };
  },
  components: {
    AdminShippingTimeline,
  },

  methods: {
    getAllOrders() {
      const order_id = this.$route.params.order_id;

      console.log("the order id", order_id);
      axiosInstance
        .get(`orders/get_orders_byId/${order_id}`)
        .then((response) => {
          this.ordersItems = response.data;
          console.log("Order Details:", this.ordersItems);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getAllOrders();
  },
  watch: {
    "$route.params.order_id": function (newOrderId) {
      this.filterOrderId = newOrderId;
      this.getAllOrders(newOrderId);
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  background-color: whitesmoke;
}
</style>
