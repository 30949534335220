<template>
  <div
    class="modal fade modal-dialog-scrollable"
    id="orderCustomizationModal"
    tabindex="-1"
    aria-labelledby="orderCustomizationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="orderCustomizationModalLabel">
            Order Item Details
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h4>Components</h4>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Option</th>
                <th scope="col">Selection</th>
                <th scope="col">Quantity</th>
              </tr>
            </thead>
            <tbody
              v-for="(selectedCustom, index) in this.orderItemOptions"
              :key="index"
            >
              <tr>
                <td>
                  <span
                    v-if="
                      selectedCustom.customization_option.customization_category
                        .parent_customization_category
                    "
                  >
                    <template
                      v-if="
                        selectedCustom.customization_option
                          .customization_category.parent_customization_category
                          .name !== 'Components'
                      "
                    >
                      {{
                        selectedCustom.customization_option
                          .customization_category.parent_customization_category
                          .name
                      }}
                    </template>
                    <template v-else>
                      {{
                        selectedCustom.customization_option
                          .customization_category.name
                      }}
                    </template>
                  </span>
                </td>
                <td>
                  <span
                    v-if="
                      selectedCustom.customization_option.customization_category
                        .parent_customization_category
                    "
                  >
                    <template
                      v-if="
                        selectedCustom.customization_option
                          .customization_category.parent_customization_category
                          .name !== 'Components'
                      "
                    >
                      <strong>{{
                        selectedCustom.customization_option
                          .customization_category.name
                      }}</strong>
                      {{ selectedCustom.customization_option.name }}
                    </template>
                    <template v-else>
                      {{ selectedCustom.customization_option.name }}
                    </template>
                  </span>
                  <span v-else>
                    {{ selectedCustom.customization_option.name }}
                  </span>
                </td>
                <td>{{ selectedCustom.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // data(){
  //   return{
  //     orderItemCustomization: [],
  //     filteredCustomizations: [],    }
  // },
  props: {
    orderItemOptions: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    console.log("orderItemOption", this.orderItemOptions);
  },
};
</script>
