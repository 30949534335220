<template>
  <ul class="list-group mb-3">
    <li
      v-for="(item, index) in cartItems"
      :key="index"
      class="list-group-item d-flex justify-content-between lh-sm"
    >
      <div>
        <h5 class="my-0">
          <a
            v-if="item.customization_id"
            :href="`/products/${item.product.product_category.product_category_id}/${item.product.product_id}?customizationId=${item.customization_id}`"
          >
            {{ item.product.name }}
          </a>
          <a
            v-else
            :href="`/products/${item.product.product_category.product_category_id}/${item.product.product_id}`"
          >
            {{ item.product.name }}
          </a>
        </h5>
        <small class="text-muted">Quantity: {{ item.quantity }}</small>
      </div>
      <span class="text-muted">₹ {{ item.price }}</span>
    </li>
    <li class="list-group-item d-flex justify-content-between">
      <span>Sub Total</span>
      ₹ {{ subtotalAmount }}
    </li>
    <li
      v-if="$store.state.user.userAppliedDiscountCode"
      class="list-group-item d-flex justify-content-between bg-light"
    >
      <div class="text-success">
        <h6 class="my-0">Discount code</h6>
        <small>{{
          $store.state.user.userAppliedDiscountCode.discount_code
        }}</small>
      </div>
      <span class="text-success">₹{{ totalAmount - subtotalAmount }}</span>
    </li>
    <li class="list-group-item d-flex justify-content-between">
      <span>Total</span>
      <strong>₹ {{ totalAmount }}</strong>
    </li>
  </ul>
</template>

<script>
//

export default {
  props: {
    cartItems: {
      type: Array,
      required: true,
    },
    subtotalAmount: {
      type: Number,
      required: true,
    },
    totalAmount: {
      type: Number,
      required: true,
    },
    savings: {
      type: Number,
      required: true,
    },
    appliedCode: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
