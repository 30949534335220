<template>
  <ul class="list-group">
    <li
      v-for="(address, index) in paginatedAddresses"
      :key="index"
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      <div>
        <h6>
          <b> {{ address.first_name }} {{ address.last_name }} </b>
        </h6>
        <h6>
          {{ address.phone_number }}
        </h6>
        <h6>
          {{ address.street_address_1 }}, {{ address.street_address_2 }},
          {{ address.city }}, {{ address.state }},
          {{ address.postal_code }}
        </h6>
      </div>
      <div>
        <button
          class="btn btn-outline-secondary me-2"
          data-bs-toggle="modal"
          data-bs-target="#editAddressModal"
          @click="$emit('editAddress', address)"
        >
          Edit
          <!-- <i class="bi bi-pencil-square"></i> -->
        </button>
        <button
          class="btn btn-outline-danger"
          data-bs-toggle="modal"
          data-bs-target="#deleteAddressModal"
          @click="$emit('deleteAddress', address)"
        >
          Delete
          <!-- <i class="bi bi-trash"></i> -->
        </button>
      </div>
    </li>
  </ul>
  <nav class="mt-2" aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <button
          class="page-link"
          @click="changePage(currentPage - 1)"
          aria-label="Previous"
        >
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>
      <li
        v-for="page in totalPages"
        :key="page"
        class="page-item"
        :class="{ active: currentPage === page }"
      >
        <button class="page-link" @click="changePage(page)">{{ page }}</button>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <button
          class="page-link"
          @click="changePage(currentPage + 1)"
          aria-label="Next"
        >
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    addresses: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      currentPage: 1,
      pageSize: 5, // Adjust the page size as needed
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.addresses.length / this.pageSize);
    },

    paginatedAddresses() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.addresses.slice(start, end);
    },
  },

  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
};
</script>
