<template>
  <ul
    v-if="order && order.order_bills && order.order_bills.length !== 0"
    class="list-group mb-3"
  >
    <li
      v-if="order.order_bills"
      class="list-group-item d-flex justify-content-between"
    >
      <span>Sub Total</span>
      ₹ {{ $parsePriceToString(order.order_bills[0].sub_total) }}
    </li>
    <li
      v-if="order.discount_code"
      class="list-group-item d-flex justify-content-between bg-light"
    >
      <div class="text-success">
        <h6 class="my-0">Discount code</h6>
        <small>{{ order.discount_code.discount_code }}</small>
      </div>
      <span v-if="order.order_bills" class="text-success"
        >- ₹{{
          $parsePriceToString(order.order_bills[0].discount_amount)
        }}</span
      >
    </li>
    <li class="list-group-item d-flex justify-content-between">
      <span v-if="order.order_bills">Total</span>
      <strong>₹ {{ $parsePriceToString(order.order_bills[0].total) }}</strong>
    </li>
  </ul>
</template>

<script>
//

export default {
  props: {
    order: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
