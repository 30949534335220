<template>
  <div class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>Home</a
          >
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <a class="text-secondary" href="#"
            ><b><i class="bi bi-person"></i>Forgot Password</b></a
          >
        </li>
      </ol>
    </nav>
    <div class="row justify-content-center">
      <div
        class="col-md-8 mt-2"
        style="
          background-color: rgba(255, 255, 255, 0.7);
          border-radius: 10px;
          padding: 20px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        "
      >
        <form @submit.prevent="handleSubmit">
          <h3>Forgot Password</h3>

          <div class="input-group mt-4">
            <span class="input-group-text"><i class="bi bi-envelope"></i></span>
            <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Enter Email"
              required
            />
            <div class="invalid-feedback">Please provide a valid email.</div>
          </div>

          <button class="btn btn-outline-success mt-4">Submit</button>
          <button class="btn btn-outline-secondary mt-4 ml-2" @click="signin">
            Cancel
          </button>
          <div
            v-if="showSuccessMessage"
            class="alert alert-success mt-3"
            role="alert"
          >
            Check your inbox. We've emailed you instructions for setting your
            password. You should receive the email shortly!
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ForgotPassword",

  data() {
    return {
      email: "",
      showSuccessMessage: false,
    };
  },

  methods: {
    signin() {
      this.$router.push("/sign-in");
    },
    async handleSubmit() {
      await axios
        .post("accounts/forgot_password/", {
          email: this.email,
        })
        .then((response) => {
          console.log(response);

          this.showSuccessMessage = true;

          setTimeout(() => {
            this.$router.push("/sign-in");
          }, 7000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
