<template>
  <div
    id="carouselExampleAutoplaying"
    class="carousel slide carousel-fade"
    data-bs-ride="carousel"
    v-if="homePageImages.length !== 0"
  >
    <div class="carousel-inner">
      <div
        class="carousel-item"
        v-for="(image, index) in homePageImages"
        :key="index"
        :class="{ active: index === 0 }"
      >
        <img :src="image.image_file_path" class="d-block w-100" alt="" />
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleAutoplaying"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleAutoplaying"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <div class="container">
    <div>
      <ul class="nav nav-pills me-3" id="v-pills-tab" role="tablist">
        <li
          v-for="(category, index) in categories"
          :key="index"
          class="nav-item"
        >
          <a
            class="nav-link"
            :class="{ active: selectedCategory === category }"
            type="button"
            role="tab"
            :aria-selected="selectedCategory === category"
            @click="selectCategory(category)"
          >
            <span v-if="category.parent_product_category === null">{{
              category.name
            }}</span>
          </a>
        </li>
      </ul>
    </div>
    <br />
    <div v-if="selectedCategory" :key="selectedCategory.category_id">
      <div class="products-list">
        <div
          v-for="childCategory in selectedCategory.children_product_categories"
          :key="childCategory.category_id"
        >
          <div v-if="childCategory.products.length > 0">
            <div class="row featurette">
              <div
                v-for="(product, index) in childCategory.products"
                :key="product.product_id"
              >
                <div class="mb-4 product-card" v-if="index % 2 === 0">
                  <div class="row">
                    <div class="col-md-7">
                      <h2 class="featurette-heading fw-normal lh-1">
                        {{ product.name }}
                      </h2>
                      <p class="lead">{{ product.product_description }}</p>
                      <div class="buttons">
                        <a
                          :href="`/products/${product.product_category}/${product.product_id}`"
                          style="margin-right: 2%"
                        >
                          <button class="btn btn-outline-danger">Shop</button>
                        </a>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <img
                        v-if="product.product_image_path"
                        class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                        width="500"
                        height="500"
                        :src="product.product_image_path"
                        alt="Product Thumbnail"
                      />
                      <img
                        v-else
                        class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                        width="500"
                        height="500"
                        src="https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
                        alt="Placeholder Thumbnail"
                      />
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="mb-4 product-card" v-else>
                  <div class="row">
                    <div class="col-md-5">
                      <img
                        v-if="product.product_image_path"
                        class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                        width="500"
                        height="500"
                        :src="product.product_image_path"
                        alt="Product Thumbnail"
                      />
                      <img
                        v-else
                        class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                        width="500"
                        height="500"
                        src="https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
                        alt="Placeholder Thumbnail"
                      />
                    </div>
                    <div class="col-md-7">
                      <h2 class="featurette-heading fw-normal lh-1">
                        {{ product.name }}
                      </h2>
                      <p class="lead">{{ product.product_description }}</p>
                      <div class="buttons">
                        <a
                          :href="`/products/${$route.params.category_id}/${product.product_id}`"
                          style="margin-right: 2%"
                        >
                          <button class="btn btn-outline-danger">Shop</button>
                        </a>
                        <router-link
                          :to="{
                            name: 'ProductCatalogue',
                            params: { category_id: product.product_category },
                          }"
                        >
                          <button class="btn btn-outline-success">
                            Explore
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [],
      selectedCategory: null,
      homePageImages: [],
      // currentPage: 0,
    };
  },
  // computed: {
  //   totalPages() {
  //     if (this.selectedCategory) {
  //       const products =
  //         this.selectedCategory.children_product_categories.flatMap(
  //           (childCategory) => childCategory.products
  //         );
  //       return Math.ceil(products.length / 6); // 6 products per page (2 products in each column)
  //     }
  //     return 0;
  //   },
  // },

  methods: {
    async getCategories() {
      try {
        const response = await axiosInstance.get(
          "products/get-product-categories/"
        );
        this.categories = response.data.product_categories;
        this.homePageImages = response.data.homepage_images;
        console.log(response.data);
        if (this.categories.length > 0) {
          this.selectCategory(this.categories[0]);
        }
        console.log("Category:", this.categories);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    },
    selectCategory(category) {
      this.selectedCategory = category;
      // this.currentPage = 0;
    },

    // changePage(page) {
    //   this.currentPage = page;
    // },
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style scoped>
.nav-pills .nav-item .active {
  background-color: #dc3545;
}

.nav-item {
  flex: 1;
  text-align: center;
  padding: 10px;
}

.nav-link {
  color: #333;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #e74c3c;
}

.nav-icon {
  font-size: 32px;
  margin-bottom: 8px;
}
</style>
