<template>
  <div class="wrapper">
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"
            ><i class="fas fa-bars"></i
          ></a>
        </li>

        <!-- <li class="nav-item d-none d-sm-inline-block">
        <a href="index3.html" class="nav-link">Home</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link">Contact</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Help
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">FAQ</a>
          <a class="dropdown-item" href="#">Support</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Contact</a>
        </div>
      </li> -->
      </ul>

      <!-- SEARCH FORM -->
      <!-- <form class="form-inline ml-3">
      <div class="input-group input-group-sm">
        <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
        <div class="input-group-append">
          <button class="btn btn-navbar" type="submit">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form> -->

      <div class="navbar-nav ml-auto me-2">
        <button class="btn btn-outline-danger" @click="signOut">Logout</button>
      </div>
    </nav>

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="index3.html" class="brand-link">
        <img
          src="https://prysmelectronics.net/images/Prysm-technology.jpg"
          alt="Logo"
          class="brand-image elevation-3"
        />
        <span class="brand-text font-weight-light"><h6>Dashboard</h6></span>
      </a>

      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <!-- <div class="image" v-if="img">
          <img src="" class="img-circle elevation-2" alt="User Image">
        </div> -->

          <div class="info">
            <a href="#" class="d-block">
              {{ $store.state.user.firstName }}
              {{ $store.state.user.lastName }}</a
            >
          </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
          >
            <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
            <li class="nav-item has-treeview menu-open">
              <a href="#" class="nav-link">
                <i class="fas fa-cart-arrow-down"></i>
                <p>Orders</p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <a href="/admin/orderlist" class="nav-link">
                    <i class="fa fa-genderless nav-icon" aria-hidden="true"></i>

                    <p>Order List</p>
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a href="/admin/user-details" class="nav-link">
                <i class="fa fa-user nav-icon"></i>
                <p>Users List</p>
              </a>
            </li>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
    <div class="content-wrapper">
      <div class="container">
        <router-view> </router-view>
      </div>
    </div>
  </div>
</template>
<script>
import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/plugins/jquery/jquery.min.js";
import "admin-lte/dist/js/adminlte.min.js";
import "@fortawesome/fontawesome-free/css/all.css";

export default {
  methods: {
    signOut() {
      axiosInstance.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("userRole");
      localStorage.removeItem("userCart");
      sessionStorage.removeItem("cart");
      this.$store.commit("removeAccessToken");
      this.$store.commit("removeRefreshToken");
      this.$store.commit("removeUserDetails");
      this.$store.commit("removeCart");
      this.$store.commit("removeUserCart");
      this.$router.push("/admin");
    },
  },
};
</script>
