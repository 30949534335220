<template>
  <form>
    <div class="mb-3">
      <label for="old_password" class="form-label">Old Pasword</label>
      <input
        type="password"
        class="form-control"
        id="old_password"
        v-model="changePassword.oldPassword"
      />
    </div>
    <div class="mb-3">
      <label for="new_password" class="form-label">New Password</label>
      <input
        type="password"
        class="form-control"
        id="new_password"
        v-model="changePassword.newPassword"
      />
    </div>

    <div class="mb-3">
      <label for="new_password2" class="form-label">Confirm New Password</label>
      <input
        type="password"
        class="form-control"
        id="new_password2"
        v-model="changePassword.newPassword2"
      />
    </div>
  </form>
</template>

<script>
export default {
  props: {
    modelValue: Object,
  },
  data() {
    return {
      changePassword: { ...this.modelValue },
    };
  },
  watch: {
    changePassword: {
      handler(newVal) {
        this.$emit("update:modelValue", newVal);
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {},
  components: {},
};
</script>
