<template>
  <div class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home</a
          >
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <b>
            <a class="text-secondary" herf="#">
              <i class="bi bi-check"></i>
              Order Confirmation
            </a>
          </b>
        </li>
      </ol>
    </nav>
    <h1>Order Confirmation</h1>
    <hr />
    <div v-if="$store.state.isLoading" class="conatiner">
      <LoadingBar />
    </div>
    <div v-else class="container">
      <div class="text-center">
        <strong>
          <h3>
            <i class="text-success bi bi-bag-check-fill"></i>
            Thank You for placing an order
          </h3>
        </strong>
        <h6 class="text-muted">
          We'll send you another email when your order ships.
        </h6>
      </div>
      <div class="row">
        <div class="col-9">
          <h4 class="d-flex justify-content-between align-items-center">
            Order Details
          </h4>
          <ConfirmedOrderItemsList v-if="order" :order="order" />
          <div class="row">
            <div class="col-6">
              <h5>Shipping Info</h5>
              <div>
                <strong>
                  {{ shippingAddress.first_name }}
                  {{ shippingAddress.last_name }}
                </strong>
              </div>
              <div>
                {{ shippingAddress.email }}
              </div>
              <div>
                {{ shippingAddress.phone_number }}
              </div>
              <div>
                {{ shippingAddress.street_address_1 }}
              </div>
              <div>
                {{ shippingAddress.street_address_2 }}
              </div>
              <div>
                {{ shippingAddress.city }}
              </div>
              <div>
                {{ shippingAddress.state }}
              </div>
              <div>
                {{ shippingAddress.postal_code }}
              </div>
            </div>
            <div class="col-6">
              <h5>Billing Info</h5>
              <div>
                <strong>
                  {{ billingAddress.first_name }}
                  {{ billingAddress.last_name }}
                </strong>
              </div>
              <div>
                {{ billingAddress.email }}
              </div>
              <div>
                {{ billingAddress.phone_number }}
              </div>
              <div>
                {{ billingAddress.street_address_1 }}
              </div>
              <div>
                {{ billingAddress.street_address_2 }}
              </div>
              <div>
                {{ billingAddress.city }}
              </div>
              <div>
                {{ billingAddress.state }}
              </div>
              <div>
                {{ billingAddress.postal_code }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <h4 class="d-flex justify-content-between align-items-center">
            <span>Order Summary</span>
          </h4>
          <ConfirmedOrderBillSummary v-if="order" :order="order" />
          <h4
            v-if="paymentInfo"
            class="d-flex justify-content-between align-items-center"
          >
            <span>Payment Information</span>
          </h4>
          <div>
            <div v-if="paymentInfo.method">
              Payment Method:
              {{ capitalize(paymentInfo.method) }}
            </div>
            <div v-if="paymentInfo.card">
              {{ capitalize(paymentInfo.card.network) }} card ending in
              {{ paymentInfo.card.last4 }}
            </div>
            <div v-if="paymentInfo.vpa">VPA: {{ paymentInfo.vpa }}</div>
            <div v-if="paymentInfo.wallet">
              Wallet: {{ capitalize(paymentInfo.wallet) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmedOrderBillSummary from "@/components/Orders/ConfirmedOrderBillSummary.vue";
import ConfirmedOrderItemsList from "@/components/Orders/ConfirmedOrderItemsList.vue";
import LoadingBar from "@/components/layout/LoadingBar.vue";

export default {
  data() {
    return {
      order: "",
      shippingAddress: "",
      billingAddress: "",
      paymentInfo: "",
    };
  },
  mounted() {
    this.getOrderConfirmation();
  },
  components: {
    ConfirmedOrderItemsList,
    ConfirmedOrderBillSummary,
    LoadingBar,
  },
  computed: {},
  methods: {
    async getOrderConfirmation() {
      if (this.$store.state.user.isAuthenticated) {
        this.$store.commit("setIsLoading", true);

        await axiosInstance
          .get(
            `orders/get-order-confirmation/${this.$route.params.order_number}/`
          )
          .then((response) => {
            console.log(response.data);
            this.order = response.data.order;
            this.shippingAddress = this.order.order_addresses.filter(
              (address) => address.address_type == "Shipping"
            )[0];
            this.billingAddress = this.order.order_addresses.filter(
              (address) => address.address_type == "Billing"
            )[0];
            this.paymentInfo = response.data.payment_info;
            this.$store.commit("setIsLoading", false);
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });
      }
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>
