import { createStore } from "vuex";
import axios from "axios";

const store = createStore({
  state: {
    user: {
      accessToken: "",
      refreshToken: "",
      isAuthenticated: false,
      email: "",
      firstName: "",
      lastName: "",
      userRole: "",
      userCart: [],
      userCartTotal: 0,
      userAppliedDiscountCode: "",
      isStaff: false,
      isSuperUser: false,
    },
    isLoading: false,
    cart: [],
    discountedTotal: 0,
    appliedCoupon: "",
    appliedDiscountCode: "",
    selectedCountry: "",
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("accessToken")) {
        if (localStorage.getItem("accessToken").toString() != "") {
          state.user.accessToken = localStorage.getItem("accessToken");
          state.user.refreshToken = localStorage.getItem("refreshToken");
          state.user.isAuthenticated = true;
          state.user.firstName = localStorage.getItem("firstName");
          state.user.lastName = localStorage.getItem("lastName");
          state.user.isStaff =
            localStorage.getItem("isStaff") == 1 ? true : false;
          state.user.isSuperUser =
            localStorage.getItem("isSuperUser") == 1 ? true : false;
          state.user.email = localStorage.getItem("email");
          state.user.userRole = localStorage.getItem("userRole");
          state.user.userCart = JSON.parse(localStorage.getItem("userCart"));
          state.user.userCartTotal = localStorage.getItem("userCartTotal");
          state.user.userAppliedDiscountCode = JSON.parse(
            localStorage.getItem("userAppliedDiscountCode")
          );
        } else {
          console.log("not auth");
        }
      } else {
        state.user.accessToken = "";
        state.user.refreshToken = "";
        state.user.isAuthenticated = false;
      }
      state.cart = JSON.parse(sessionStorage.getItem("cart")) || [];
      state.cartTotal = sessionStorage.getItem("cartTotal") || 0;
      state.appliedDiscountCode =
        JSON.parse(sessionStorage.getItem("appliedDiscountCode")) || "";
      state.selectedCountry =
        JSON.parse(localStorage.getItem("selectedCountry")) || "";
    },
    setSelectedCountry(state, country) {
      state.selectedCountry = country;
    },
    setUserDetails(state, userDetails) {
      console.log("set user details", userDetails.is_staff);
      console.log("user details");
      state.user.firstName = userDetails.first_name;
      state.user.lastName = userDetails.last_name;
      state.user.userRole = userDetails.role;
      state.user.isStaff = userDetails.is_staff == 1 ? true : false;
      state.user.isSuperUser = userDetails.is_superuser === 1 ? true : false;
      state.user.isAuthenticated = true;
    },
    setAccessToken(state, accessToken) {
      state.user.accessToken = accessToken;
      state.user.isAuthenticated = true;
    },
    setRefreshToken(state, refreshToken) {
      state.user.refreshToken = refreshToken;
      state.user.isAuthenticated = false;
    },
    removeAccessToken(state) {
      state.user.accessToken = "";
      state.user.isAuthenticated = false;
    },
    removeRefreshToken(state) {
      state.user.refreshToken = "";
      state.user.isAuthenticated = false;
    },
    removeUserDetails(state) {
      state.user.firstName = "";
      state.user.lastName = "";
      state.user.userRole = "";
      state.user.isAuthenticated = false;
      state.user.isStaff = false;
      state.user.isSuperUser = false;
    },
    // async refreshAccessToken(state) {
    //   await axios
    //     .post("jwt/refresh/", { refresh: state.user.refreshToken })
    //     .then((response) => {
    //       console.log(response.data);
    //       localStorage.setItem("accessToken", response.data.access);
    //       state.user.accessToken = response.data.access;
    //       localStorage.setItem("accessToken", response.data.access);
    //       state.user.isAuthenticated = true;
    //       axios.defaults.headers.common["Authorization"] =
    //         "JWT " + this.$store.state.user.accessToken;
    //     })
    //     .catch((error) => {
    //       console.log("refresh token invalid");
    //       axios.defaults.headers.common["Authorization"] = "";
    //       localStorage.removeItem("accessToken");
    //       localStorage.removeItem("refreshToken");
    //       localStorage.removeItem("firstName");
    //       localStorage.removeItem("lastName");
    //       localStorage.removeItem("userRole");
    //       localStorage.removeItem("userCart");
    //       sessionStorage.removeItem("cart");
    //       this.commit("removeAccessToken");
    //       this.commit("removeRefreshToken");
    //       this.commit("removeUserDetails");
    //       this.commit("removeCart");
    //       this.commit("removeUserCart");
    //       window.location.href = "/";
    //     });
    // },
    async refreshAccessToken(state) {
      console.log("Calling refreshAccessToken");

      try {
        const response = await axios.post("token-jwt/refresh/", {
          refresh: state.user.refreshToken,
        });
        console.log("Response from token-jwt/refresh:", response.data);

        const newAccessToken = response.data.access;
        console.log("New access token received:", newAccessToken);

        localStorage.setItem("accessToken", newAccessToken);
        state.user.accessToken = newAccessToken;
        state.user.isAuthenticated = true;
        axios.defaults.headers.common["Authorization"] =
          "JWT " + newAccessToken;
      } catch (error) {
        console.log("Error refreshing token:", error);

        axios.defaults.headers.common["Authorization"] = "";
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("userRole");
        localStorage.removeItem("userCart");
        sessionStorage.removeItem("cart");

        this.commit("removeAccessToken");
        this.commit("removeRefreshToken");
        this.commit("removeUserDetails");
        this.commit("removeCart");
        this.commit("removeUserCart");

        window.location.href = "/";
      }
    },

    setIsLoading(state, status) {
      state.isLoading = status;
    },
    updateCart(state, newCart) {
      state.cart = newCart;
    },
    updateAppliedDiscountCode(state, appliedDiscountCode) {
      state.appliedDiscountCode = appliedDiscountCode;
    },
    updateUserCart(state, newCart) {
      state.user.userCart = newCart;
    },
    updateUserCartTotal(state, newUserCartTotal) {
      state.user.userCartTotal = newUserCartTotal;
    },
    updateUserAppliedDiscountCode(state, newUserAppliedDiscountCode) {
      state.user.userAppliedDiscountCode = newUserAppliedDiscountCode;
    },
    updateCartTotal(state, cartTotal) {
      state.cartTotal = cartTotal;
    },
    removeUserCart(state) {
      state.user.userCart = [];
      state.user.userCartTotal = 0;
    },
    removeCart(state) {
      state.cart = [];
      state.cartTotal = 0;
    },
  },
  actions: {},
  modules: {},
});

export default store;
