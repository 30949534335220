<template>
  <div class="container">
    <!-- 
        <ul class="nav nav-pills  mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link " id="pills-AllOrders-tab" data-bs-toggle="pill" data-bs-target="#pills-AllOrders"
                    type="button" role="tab" aria-controls="pills-AllOrders" aria-selected="true">All Orders</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link " id="pills-pending-tab" data-bs-toggle="pill" data-bs-target="#pills-pending"
                    type="button" role="tab" aria-controls="pills-pending" aria-selected="false">Pending</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link " id="pills-confirmed-tab" data-bs-toggle="pill" data-bs-target="#pills-confirmed"
                    type="button" role="tab" aria-controls="pills-confirmed" aria-selected="false">Confirmed</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link " id="pills-delivered-tab" data-bs-toggle="pill" data-bs-target="#pills-delivered"
                    type="button" role="tab" aria-controls="pills-delivered" aria-selected="false">Delivered</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link " id="pills-cancelled-tab" data-bs-toggle="pill" data-bs-target="#pills-cancelled"
                    type="button" role="tab" aria-controls="pills-cancelled" aria-selected="false">Cancelled</button>
            </li>
        </ul> -->

    <div
      v-for="(order, index) in paginatedOrders"
      :key="index"
      class="card mb-4"
    >
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-md-6">
            <h4 class="mb-0">
              Order #{{ order.order_number }} | {{ order.fulfillment_status }}
            </h4>
            <p class="text-muted">
              Placed On: {{ order.order_bills[0].created_at }}
            </p>
          </div>
          <div class="col-md-6 text-md-end text-muted">
            <p class="mb-0" v-if="order.order_bills">
              Total Amount:
              <strong> ₹ {{ $parsePriceToString(order.total_amount) }} </strong>
            </p>
            <button
              class="btn btn-outline-info btn-sm mr-1"
              @click="downloadInvoiceFile"
              style="color: black"
            >
              <!-- @click="getInvoice(order.order_id)" -->

              <i class="bi bi-download"></i> Invoice
            </button>
            <button
              class="btn btn-outline-secondary btn-sm ms-1"
              v-if="$store.state.user.isStaff"
              @click="$router.push(`/order-processing/${order.order_id}`)"
            >
              View Payment Details
            </button>
            <button
              class="btn btn-outline-success btn-sm ms-1"
              data-bs-toggle="modal"
              data-bs-target="#orderDetailModal"
              @click="activeOrder = order"
            >
              View Order Details
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Product</th>
              <th>Status</th>
              <th>Quantity</th>
              <!-- <th>Unit Price</th> -->
              <!-- <th>Total Price</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, itemIndex) in order.order_items" :key="itemIndex">
              <td>
                <img
                  v-if="item.product.product_image_path"
                  class="img-fluid rounded"
                  :src="item.product.product_image_path"
                  width="50"
                  height="50"
                />
                <router-link
                  :to="`/products/${item.product.product_category}/${item.product.product_id}`"
                  style="text-decoration: none; color: black"
                >
                  {{ item.product.name }}
                </router-link>
              </td>
              <td>{{ item.fulfillment_status }}</td>
              <td>{{ item.quantity }}</td>
              <!-- <td>₹ {{ $parsePriceToString(item.price) }}</td>
              <td>
                ₹
                {{ $parsePriceToString(item.quantity * item.price) }}
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="modal fade"
        :id="'orderDetailModal'"
        tabindex="-1"
        :aria-labelledby="'orderDetailModalLabel'"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" :id="'orderDetailModalLabel'">
                Details for Order # {{ activeOrder.order_number }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <OrderDetails
                v-if="activeOrder"
                @orderItemSelected="handleOrderItemSelected"
                :order="activeOrder"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade modal-dialog-scrollable"
      id="addReviewModal"
      tabindex="-1"
      aria-labelledby="addReviewModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addReviewModalLabel">Add Review</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <AddReview
              :order-item="activeOrderItem"
              :defaultRatingSystem="defaultRatingSystem"
              v-model="review"
              @review-found="handleReviewFound"
              @clear-images="handleClearImages"
              @remove-image="handleRemoveImage"
            />
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              data-bs-target="#orderDetailModal"
              data-bs-toggle="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              @click="addReview"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <OrderCustomizations :orderItemOptions="selectedOrderItemOptions" /> -->

    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" v-if="currentPage > 1">
          <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          class="page-item"
          v-for="page in totalPages"
          :key="page"
          :class="{ active: page === currentPage }"
        >
          <a class="page-link" href="#" @click="gotoPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" v-if="currentPage < totalPages">
          <a class="page-link" href="#" aria-label="Next" @click="nextPage">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <div
    class="modal fade modal-dialog-scrollable"
    id="orderCustomizationModal"
    tabindex="-1"
    aria-labelledby="orderCustomizationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="orderCustomizationModalLabel">
            Order Item Details
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <OrderItemCustomizations :order-item="activeOrderItem" />
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            data-bs-target="#orderDetailModal"
            data-bs-toggle="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderCustomizations from "./OrderCustomizations.vue";
import OrderDetails from "./OrderDetails.vue";
import OrderItemCustomizations from "./OrderItemCustomizations.vue";
import AddReview from "./AddReview.vue";
import EditReview from "./EditReview.vue";

export default {
  props: {
    orders: {
      type: Array,
      required: true,
    },
    defaultRatingSystem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      ordersPerPage: 5,
      selectedOrder: {},
      activeOrder: "",
      activeOrderItem: {},
      isReviewPresent: false,
      review: {
        comment: "",
        selectedRating: 0,
        selectedImages: [],
      },
      deletedImages: [],
      productReviews: "",
    };
  },
  components: {
    OrderCustomizations,
    OrderDetails,
    OrderItemCustomizations,
    AddReview,
  },
  mounted() {
    // this.getOrders();
    // console.log("Orders sdfsfs:", this.orders[0].order_bills[0].invoice_path);
  },
  computed: {
    totalPages() {
      return Math.ceil(this.orders.length / this.ordersPerPage);
    },
    paginatedOrders() {
      const startIndex = (this.currentPage - 1) * this.ordersPerPage;
      const endIndex = startIndex + this.ordersPerPage;
      return this.orders.slice(startIndex, endIndex);
    },
  },
  methods: {
    async getOrders() {
      try {
        const response = await axiosInstance.get(`/orders/get_orders/`);
        this.orders = response.data;
        console.log("Orders:", this.orders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    gotoPage(page) {
      this.currentPage = page;
    },
    handleOrderItemSelected(orderItem) {
      console.log("active orderItem", orderItem);
      this.activeOrderItem = orderItem;
    },
    handleReviewFound(isFound) {
      this.isReviewPresent = isFound;
      // You can add more logic here based on whether the review is found or not
    },
    handleClearImages() {
      this.review.selectedImages.forEach((image) => {
        if (image.file) {
          // Remove newly uploaded images from selectedImages array
          const index = this.review.selectedImages.indexOf(image);
          if (index > -1) {
            this.review.selectedImages.splice(index, 1);
          }
        } else {
          // Move existing images to deletedImages array
          this.review.selectedImages = [];
          this.deletedImages.push(image);
        }
      });
      console.log(
        "this.review.selectedImages if cleared ",
        this.review.selectedImages
      );
      console.log("this.deletedImages if cleared", this.deletedImages);

      // this.$refs.fileInput.value = ""; // Clear the file input
    },

    handleRemoveImage(index) {
      console.log("index of image going to be removed", index);
      console.log(
        "this.review.selectedImages if before crossing",
        this.review.selectedImages
      );

      console.log();

      const image = this.review.selectedImages[index];

      console.log("iamgeobject", image);

      if (image && image.file) {
        // Remove newly uploaded image from selectedImages array
        this.review.selectedImages.splice(index, 1);
      } else if (image) {
        // Check if image exists
        // Move existing image to deletedImages array
        this.review.selectedImages.splice(index, 1);

        this.deletedImages.push(image);
      }
      console.log(
        "this.review.selectedImages after crossing",
        this.review.selectedImages
      );
      console.log("this.deletedImages if crossed ", this.deletedImages);
    },

    async editReview() {
      try {
        // Assuming you have axiosInstance installed and imported
        const productID = this.activeOrderItem.product.product_id;
        const response = await axiosInstance.put(
          `/products/get-product-review/${productID}`
        );
        // Assuming your backend API returns product reviews data
        const productReviews = response.data;

        // Now you can use the productReviews data as needed
        console.log(productReviews);
      } catch (error) {
        // Handle error
        console.error("Error fetching product reviews:", error);
      }
    },

    async addReview() {
      console.log("api called");

      const productID = this.activeOrderItem.product.product_id;
      console.log(productID);

      const selectedRatingValue = this.defaultRatingSystem.values.find(
        (item) => item.display_order === this.review.selectedRating
      );
      console.log(selectedRatingValue);

      if (!selectedRatingValue) {
        console.error("Selected rating value not found.");
        return;
      }
      console.log("this.review.selectedImages=", this?.review?.selectedImages);

      const formData = new FormData();
      formData.append("comment", this.review.comment);
      formData.append("rating", selectedRatingValue.rating_value_id);

      // if (this.review.selectedImages.length > 0) {
      //   this.review.selectedImages.forEach((file) => {
      //     formData.append("image[]", file.file);
      //   });
      // }

      if (this?.review?.selectedImages.length > 0) {
        this?.review?.selectedImages.forEach((image) => {
          if (image.file) {
            formData.append("uploaded_images[]", image.file);
          } else {
            formData.append("existing_images[]", image.review_image_id); // Adjust 'image_url' to match your data structure
          }
        });
      }

      if (this?.deletedImages?.length > 0) {
        this.deletedImages.forEach((deletedImage) => {
          formData.append("deleted_images[]", deletedImage.review_image_id); // Adjust 'id' to match your data structure
        });
      }

      const formDataObject = {};
      for (let [key, value] of formData.entries()) {
        formDataObject[key] = value;
      }

      // Print the FormData object
      console.log("FormData:", formDataObject);
      // console.log9;
      // this.review.selectedImages.forEach((file) => {
      //   formData.append("image[]", file.file);
      // });
      console.log("is review present value", this.isReviewPresent);

      if (this.isReviewPresent) {
        // If review is present, make an edit request
        console.log("put");
        await axiosInstance
          .put(`products/get-product-review/${productID}/`, formData)
          .then((response) => {
            console.log("Product review edited:", response.data);
            this.$notify({
              title: "Review edited successfully",
              type: "bg-success-subtle text-success",
              duration: "5000",
            });
          })
          .catch((error) => {
            console.error("Error editing product review:", error.response.data);
          });
      } else {
        // If review is not present, make a POST request to add a new review
        console.log("post");
        await axiosInstance
          .post(`products/add_product_review/${productID}/`, formData)
          .then((response) => {
            console.log("Product review added:", response.data);
            this.$notify({
              title: "Review added successfully",
              type: "bg-success-subtle text-success",
              duration: "5000",
            });
          })
          .catch((error) => {
            console.error("Error adding product review:", error.response.data);
          });
      }
    },

    async getInvoice(orderId) {
      this.$store.commit("setIsLoading", true);

      try {
        const response = await axios.get(`/orders/get_invoice/${orderId}`, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice_${orderId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading invoice:", error);
      }

      this.$store.commit("setIsLoading", false);
    },

    downloadInvoiceFile() {
      if (this.orders.length > 0 && this.orders[0].order_bills.length > 0) {
        const invoicePath = this.orders[0].order_bills[0].invoice_path;
        if (invoicePath) {
          const link = document.createElement("a");
          link.href = invoicePath;
          link.target = "_blank"; // Open in a new tab
          link.click();
        } else {
          console.log("Invoice File Path Not Found");
        }
      } else {
        console.log("No Order Bills Found");
      }
    },
  },
};
</script>

<style scoped>
.nav-link {
  color: black;
}

.nav-link:hover {
  background-color: rgb(201, 64, 64);
  color: whitesmoke;
}

.nav-link.active {
  background-color: rgb(201, 64, 64);
}

.pagination .active .page-link {
  background-color: #dc3545;
  border-color: #dc3545;
}
</style>
