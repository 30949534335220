<template>
  <div>
    <div class="row">
      <nav class="card col-md-3 col-lg-2 d-md-block">
        <div class="position-sticky my-2">
          <ul class="nav nav-pills flex-column mb-auto">
            <li
              v-for="(customization, index) in customizations"
              :key="index"
              class="nav-item"
            >
              <a
                class="nav-link"
                @click="showCategory(customization)"
                :class="{ active: selectedCategory == customization }"
              >
                {{ customization.name }}
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <main v-if="!isEditing" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div class="accordion">
          <div
            v-for="(
              customization, index
            ) in selectedCategory.children_customization_categories"
            :key="index"
          >
            <div
              v-if="
                selectedOptions.filter((opt) => {
                  return (
                    opt.option.customization_option.customization_category
                      .customization_category_id ===
                      customization.customization_category_id ||
                    customization.children_customization_categories.some(
                      (childCategory) => {
                        return (
                          opt.option.customization_option.customization_category
                            .customization_category_id ===
                          childCategory.customization_category_id
                        );
                      }
                    )
                  );
                }).length !== 0
              "
              class="accordion-item"
            >
              <h2 class="accordion-header">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#panelsStayOpen-collapse' + index"
                  :aria-controls="'panelsStayOpen-collapse' + index"
                  data-bs-bs-toggle="collapse"
                >
                  <strong>
                    <span class="accordion-focus">
                      {{ customization.name }}
                    </span>
                  </strong>
                </button>
              </h2>
              <div
                :id="'panelsStayOpen-collapse' + index"
                class="accordion-collapse collapse show"
              >
                <div class="accordion-body">
                  <div
                    v-if="
                      customization.children_customization_categories &&
                      customization.children_customization_categories.length > 0
                    "
                  >
                    <div
                      v-for="(
                        childCustomization, childCustomizationIndex
                      ) in customization.children_customization_categories"
                      :key="childCustomizationIndex"
                    >
                      <strong
                        v-if="
                          selectedOptions.filter(
                            (opt) =>
                              opt.option.customization_option
                                .customization_category
                                .customization_category_id ===
                              childCustomization.customization_category_id
                          ).length !== 0
                        "
                      >
                        {{ childCustomization.name }}
                      </strong>
                      <ul class="list-group mb-2">
                        <template
                          v-for="(
                            childOption, childOptionIndex
                          ) in childCustomization.options"
                          :key="childOptionIndex"
                        >
                          <li
                            class="list-group-item"
                            v-if="isOptionSelected(childOption)"
                          >
                            {{ childOption.customization_option.name }}
                            <template
                              v-if="
                                childOption.customization_option.has_quantity !=
                                  0 &&
                                childOption.customization_option.has_quantity !=
                                  2
                              "
                            >
                              ( Qty:
                              {{
                                selectedOptions.filter(
                                  (opt) =>
                                    opt.option
                                      .product_customization_default_quantity_id ==
                                    childOption.product_customization_default_quantity_id
                                )[0].selected_quantity
                              }}
                              )
                            </template>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                  <ul class="list-group">
                    <template
                      v-for="(option, optionIndex) in customization.options"
                      :key="optionIndex"
                    >
                      <li
                        class="list-group-item"
                        v-if="isOptionSelected(option)"
                      >
                        {{ option.customization_option.name }}
                        <template
                          v-if="
                            option.customization_option.has_quantity != 0 &&
                            option.customization_option.has_quantity != 2
                          "
                        >
                          ( Qty:
                          {{
                            selectedOptions.filter(
                              (opt) =>
                                opt.option
                                  .product_customization_default_quantity_id ==
                                option.product_customization_default_quantity_id
                            )[0].selected_quantity
                          }}
                          )
                        </template>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <main v-if="isEditing" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div class="accordion">
          <div
            v-for="(
              customization, index
            ) in selectedCategory.children_customization_categories"
            :key="index"
          >
            <div class="accordion-item" v-if="checkForOptions(customization)">
              <h2 class="accordion-header">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#panelsStayOpen-collapse' + index"
                  :aria-controls="'panelsStayOpen-collapse' + index"
                  data-bs-bs-toggle="collapse"
                >
                  <strong>
                    <span class="accordion-focus">{{
                      customization.name
                    }}</span>
                  </strong>
                </button>
              </h2>
              <div
                :id="'panelsStayOpen-collapse' + index"
                class="accordion-collapse collapse show"
              >
                <div class="accordion-body">
                  <div
                    v-if="
                      customization.children_customization_categories &&
                      customization.children_customization_categories.length > 0
                    "
                  >
                    <div
                      v-for="(
                        childCustomization, childCustomizationIndex
                      ) in customization.children_customization_categories"
                      :key="childCustomizationIndex"
                    >
                      <strong>
                        {{ childCustomization.name }}
                      </strong>
                      <div v-if="childCustomization.is_multiselect == 1">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Customization</th>
                              <th>Quantity</th>
                              <th>Unit Price</th>
                              <th>Total Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                option, optionIndex
                              ) in childCustomization.options"
                              :key="optionIndex"
                            >
                              <td>
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :name="
                                    'option' + index + childCustomizationIndex
                                  "
                                  :id="
                                    'option' +
                                    index +
                                    childCustomizationIndex +
                                    '-' +
                                    optionIndex
                                  "
                                  :value="option"
                                  :checked="isOptionSelected(option)"
                                  @change="
                                    updateSelectedOptions(
                                      option,
                                      index,
                                      optionIndex,
                                      $event,
                                      childCustomizationIndex
                                    )
                                  "
                                  ref="quantityInput"
                                />
                                <label
                                  class="ms-2"
                                  :for="
                                    'option' +
                                    index +
                                    childCustomizationIndex +
                                    '-' +
                                    optionIndex
                                  "
                                >
                                  {{ option.customization_option.name }}
                                </label>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    option.customization_option.has_quantity
                                  "
                                >
                                  <input
                                    type="number"
                                    v-model="
                                      quantities[
                                        `m-${index}-${childCustomizationIndex}-${optionIndex}`
                                      ]
                                    "
                                    @input="updateQuantity(option, $event)"
                                    class="form-control"
                                    style="width: 70px"
                                    :min="option.min_quantity"
                                    :max="option.max_quantity"
                                  />
                                </span>
                                <span v-else>1</span>
                              </td>
                              <td>₹ {{ option.customization_option.price }}</td>
                              <td>
                                ₹
                                {{
                                  option.customization_option.price *
                                  quantities[
                                    `m-${index}-${childCustomizationIndex}-${optionIndex}`
                                  ]
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else>
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Customization</th>
                              <th>Quantity</th>
                              <th>Unit Price</th>
                              <th>Total Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                option, optionIndex
                              ) in childCustomization.options"
                              :key="optionIndex"
                            >
                              <td>
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  :name="
                                    'option' + index + childCustomizationIndex
                                  "
                                  :id="
                                    'option' +
                                    index +
                                    childCustomizationIndex +
                                    '-' +
                                    optionIndex
                                  "
                                  :value="option"
                                  :checked="isOptionSelected(option)"
                                  @change="
                                    updateSelectedOptions(
                                      option,
                                      index,
                                      optionIndex,
                                      $event,
                                      childCustomizationIndex
                                    )
                                  "
                                  ref="quantityInput"
                                />
                                <label
                                  class="ms-2"
                                  :for="
                                    'option' +
                                    index +
                                    childCustomizationIndex +
                                    '-' +
                                    optionIndex
                                  "
                                >
                                  {{ option.customization_option.name }}
                                </label>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    option.customization_option.has_quantity
                                  "
                                >
                                  <input
                                    type="number"
                                    v-model="
                                      quantities[
                                        `s-${index}-${childCustomizationIndex}-${optionIndex}`
                                      ]
                                    "
                                    @input="updateQuantity(option, $event)"
                                    class="form-control"
                                    style="width: 70px"
                                    :min="option.min_quantity"
                                    :max="option.max_quantity"
                                  />
                                </span>
                                <span v-else>1</span>
                              </td>
                              <td>₹ {{ option.customization_option.price }}</td>
                              <td>
                                ₹
                                {{
                                  option.customization_option.price *
                                  quantities[
                                    `s-${index}-${childCustomizationIndex}-${optionIndex}`
                                  ]
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="customization.is_multiselect == 1">
                      <table
                        class="table"
                        v-if="
                          customization.options &&
                          customization.options.length > 0
                        "
                      >
                        <thead>
                          <tr>
                            <th>Customization</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              option, optionIndex
                            ) in customization.options"
                            :key="optionIndex"
                          >
                            <td>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                :name="'option' + index"
                                :id="'option' + index + '-' + optionIndex"
                                :value="option"
                                :checked="isOptionSelected(option)"
                                @change="
                                  updateSelectedOptions(
                                    option,
                                    index,
                                    optionIndex,
                                    $event,
                                    null
                                  )
                                "
                                ref="quantityInput"
                              />
                              <label
                                class="ms-2"
                                :for="'option' + index + '-' + optionIndex"
                              >
                                {{ option.customization_option.name }}
                              </label>
                            </td>
                            <td>
                              <span
                                v-if="option.customization_option.has_quantity"
                              >
                                <input
                                  type="number"
                                  v-model="
                                    quantities[`m-${index}-${optionIndex}`]
                                  "
                                  @input="updateQuantity(option, $event)"
                                  class="form-control"
                                  style="width: 70px"
                                  :min="option.min_quantity"
                                  :max="option.max_quantity"
                                />
                              </span>
                              <span v-else>1</span>
                            </td>
                            <td>₹ {{ option.customization_option.price }}</td>
                            <td>
                              ₹
                              {{
                                option.customization_option.price *
                                quantities[`m-${index}-${optionIndex}`]
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-else>
                      <table
                        class="table"
                        v-if="
                          customization.options &&
                          customization.options.length > 0
                        "
                      >
                        <thead>
                          <tr>
                            <th>Customization</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              option, optionIndex
                            ) in customization.options"
                            :key="optionIndex"
                          >
                            <td>
                              <input
                                class="form-check-input"
                                type="radio"
                                :name="'option' + index"
                                :id="'option' + index + '-' + optionIndex"
                                :value="option"
                                :checked="isOptionSelected(option)"
                                @change="
                                  updateSelectedOptions(
                                    option,
                                    index,
                                    optionIndex,
                                    $event,
                                    null
                                  )
                                "
                                ref="quantityInput"
                              />
                              <label
                                class="ms-2"
                                :for="'option' + index + '-' + optionIndex"
                              >
                                {{ option.customization_option.name }}
                              </label>
                            </td>
                            <td>
                              <span
                                v-if="option.customization_option.has_quantity"
                              >
                                <input
                                  type="number"
                                  v-model="
                                    quantities[`s-${index}-${optionIndex}`]
                                  "
                                  @input="updateQuantity(option, $event)"
                                  class="form-control"
                                  style="width: 70px"
                                  :min="option.min_quantity"
                                  :max="option.max_quantity"
                                />
                              </span>
                              <span v-else>1</span>
                            </td>
                            <td>₹ {{ option.customization_option.price }}</td>
                            <td>
                              ₹
                              {{
                                option.customization_option.price *
                                quantities[`s-${index}-${optionIndex}`]
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customizations: {
      type: Array,
      default: () => [],
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {
      selectedCategory: {},
      selectedOptions: [],
      quantities: {},
      isSelectedOption: false,
      oldSelectedOptions: [],
    };
  },
  computed: {},
  watch: {
    customizations: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          let isSession = false;
          const customizationQueryParam = this.$route.query.customizationId;
          if (customizationQueryParam) {
            isSession = true;
            if (
              sessionStorage.getItem(
                `customization_${this.$route.params.product_id}_${customizationQueryParam}`
              )
            ) {
              this.selectedOptions = JSON.parse(
                sessionStorage.getItem(
                  `customization_${this.$route.params.product_id}_${customizationQueryParam}`
                )
              );
              console.log("options from session", this.selectedOptions);
              this.$emit("selectedOptions", this.selectedOptions);
            }
          }
          for (let value of newVal) {
            this.selectedCategory = value;
            this.selectedCategory.children_customization_categories.forEach(
              (customization, parentIndex) => {
                // changed variable name to avoid confusion with inner index
                if (
                  customization.children_customization_categories &&
                  customization.children_customization_categories.length > 0
                ) {
                  customization.children_customization_categories.forEach(
                    (childCustomization, index) => {
                      // changed variable name to avoid confusion with outer index
                      childCustomization.options.forEach(
                        (option, optionIndex) => {
                          const keyPrefix =
                            childCustomization.is_multiselect == 1 ? "m" : "s";
                          const key = `${keyPrefix}-${parentIndex}-${index}-${optionIndex}`; // changed the key generation
                          if (isSession == false) {
                            this.quantities[key] = option.default_quantity;
                          } else {
                            if (
                              this.selectedOptions.find(
                                (opt) =>
                                  opt.option
                                    .product_customization_default_quantity_id ===
                                  option.product_customization_default_quantity_id
                              )
                            ) {
                              this.quantities[key] = this.selectedOptions.find(
                                (opt) =>
                                  opt.option
                                    .product_customization_default_quantity_id ===
                                  option.product_customization_default_quantity_id
                              ).selected_quantity;
                            } else {
                              this.quantities[key] = option.default_quantity;
                            }
                          }

                          if (option.is_default == 1 && isSession == false) {
                            this.selectedOptions.push({
                              option: option,
                              selected_quantity: option.default_quantity,
                            });
                            this.$emit("selectedOptions", this.selectedOptions);
                          }
                        }
                      );
                    }
                  );
                } else {
                  customization.options.forEach((option, optionIndex) => {
                    const keyPrefix =
                      customization.is_multiselect == 1 ? "m" : "s";
                    const key = `${keyPrefix}-${parentIndex}-${optionIndex}`; // changed the key generation
                    if (isSession == false) {
                      this.quantities[key] = option.default_quantity;
                    } else {
                      if (
                        this.selectedOptions.find(
                          (opt) =>
                            opt.option
                              .product_customization_default_quantity_id ===
                            option.product_customization_default_quantity_id
                        )
                      ) {
                        this.quantities[key] = this.selectedOptions.find(
                          (opt) =>
                            opt.option
                              .product_customization_default_quantity_id ===
                            option.product_customization_default_quantity_id
                        ).selected_quantity;
                      } else {
                        this.quantities[key] = option.default_quantity;
                      }
                    }

                    if (option.is_default == 1 && isSession == false) {
                      this.selectedOptions.push({
                        option: option,
                        selected_quantity: option.default_quantity,
                      });
                      this.$emit("selectedOptions", this.selectedOptions);
                    }
                  });
                }
              }
            );
          }
          this.selectedCategory = newVal[0];
        }
      },
    },
  },
  methods: {
    showCategory(category) {
      this.selectedCategory = category;
    },
    hasDefaultValues(options) {
      return options.some((option) => option.is_default === 1);
    },
    verifyConfiguration(option, quantity, eventType) {
      console.log("option", option);
      console.log("qty", quantity);
      if (eventType) {
        if (
          option.customization_option &&
          option.customization_option.customization_category
        ) {
          if (
            option.customization_option.customization_category.name == "RAM"
          ) {
            let ram_count = 0;
            let selected_rams = this.selectedOptions.filter(
              (option) =>
                option.option.customization_option.customization_category
                  .name == "RAM"
            );
            console.log(selected_rams);
            for (let ram of selected_rams) {
              ram_count += ram.selected_quantity;
            }
            console.log("ram and quant", ram_count + quantity);
            let processor = this.selectedOptions.find(
              (option) =>
                option.option.customization_option &&
                option.option.customization_option.customization_category &&
                option.option.customization_option.customization_category
                  .parent_customization_category &&
                option.option.customization_option.customization_category
                  .parent_customization_category.name === "CPU"
            );
            if (processor) {
              if (processor.selected_quantity == 1) {
                if (ram_count + quantity > 16) {
                  this.$notify({
                    title: "RAM Count cannot be more than 16",
                    type: "bg-danger-subtle text-danger",
                    duration: "5000",
                  });
                  return false;
                } else {
                  return true;
                }
              } else if (processor.selected_quantity >= 2) {
                if (ram_count + quantity > 32) {
                  this.$notify({
                    title: "RAM Count cannot be more than 32 for 2 processors",
                    type: "bg-danger-subtle text-danger",
                    duration: "5000",
                  });
                }
                if ((ram_count + quantity) % 2 !== 0) {
                  {
                    this.$notify({
                      title:
                        "RAM Count should be a multiple of 2 for 2 processors",
                      type: "bg-danger-subtle text-danger",
                      duration: "5000",
                    });
                  }
                  return false;
                } else {
                  return true;
                }
              }
            }
            if (processor) {
              if (processor.selected_quantity == 1) {
                if (ram_count + quantity > 16) {
                  this.$notify({
                    title: "RAM Count cannot be more than 16",
                    type: "bg-danger-subtle text-danger",
                    duration: "5000",
                  });
                  return false;
                } else {
                  return true;
                }
              } else if (processor.selected_quantity >= 2) {
                if (ram_count + quantity > 32) {
                  this.$notify({
                    title: "RAM Count cannot be more than 32 for 2 processors",
                    type: "bg-danger-subtle text-danger",
                    duration: "5000",
                  });
                }
                if ((ram_count + quantity) % 2 !== 0) {
                  {
                    this.$notify({
                      title:
                        "RAM Count should be a multiple of 2 for 2 processors",
                      type: "bg-danger-subtle text-danger",
                      duration: "5000",
                    });
                  }
                  return false;
                } else {
                  return true;
                }
              }
            }
          } else if (
            option.customization_option.customization_category
              .parent_customization_category &&
            option.customization_option.customization_category
              .parent_customization_category.name == "Storage"
          ) {
            console.log("opt", option);
            let storage_count = 0;
            let sata_count = 0;
            let u2_count = 0;

            let selected_storage = this.selectedOptions.filter(
              (option) =>
                option.option.customization_option.customization_category
                  .parent_customization_category.name == "Storage"
            );
            console.log(selected_storage);

            for (let ssd of selected_storage) {
              storage_count += ssd.selected_quantity;
            }

            let selected_u2 = this.selectedOptions.filter(
              (option) =>
                option.option.customization_option.customization_category
                  .name == "U.2 SSD"
            );
            console.log(selected_u2);
            for (let ssd of selected_u2) {
              u2_count += ssd.selected_quantity;
            }

            if (
              option.customization_option.customization_category.name ==
              "U.2 SSD"
            ) {
              if (u2_count + quantity > 6) {
                this.$notify({
                  title: "U.2 Storage Drives cannot be more than 6",
                  type: "bg-danger-subtle text-danger",
                  duration: "5000",
                });
                return false;
              }
            }

            if (storage_count + quantity > 24) {
              this.$notify({
                title: "Storage Drives cannot be more than 24",
                type: "bg-danger-subtle text-danger",
                duration: "5000",
              });
              return false;
            }
            console.log("sata and quant", sata_count + quantity);
            console.log("u2 and quant", u2_count + quantity);
            return true;
          } else if (
            option.customization_option.customization_category
              .parent_customization_category &&
            option.customization_option.customization_category
              .parent_customization_category.name == "CPU"
          ) {
            console.log("cpu", option);
            console.log("quant", quantity);
            let ram_count = 0;
            let selected_rams = this.selectedOptions.filter(
              (option) =>
                option.option.customization_option.customization_category
                  .name == "RAM"
            );
            console.log(selected_rams);
            for (let ram of selected_rams) {
              ram_count += ram.selected_quantity;
            }
            if (quantity == 1) {
              if (ram_count > 16) {
                this.$notify({
                  title: "RAM Count cannot be more than 16",
                  type: "bg-danger-subtle text-danger",
                  duration: "5000",
                });
                return false;
              } else {
                return true;
              }
            } else if (quantity == 2) {
              if (ram_count > 32) {
                this.$notify({
                  title: "RAM Count cannot be more than 32 for 2 processors",
                  type: "bg-danger-subtle text-danger",
                  duration: "5000",
                });
              }
              if (ram_count % 2 !== 0) {
                {
                  this.$notify({
                    title:
                      "RAM Count should be a multiple of 2 for 2 processors",
                    type: "bg-danger-subtle text-danger",
                    duration: "5000",
                  });
                }
                return false;
              } else {
                return true;
              }
            } else {
              this.$notify({
                title: "There cannot be more than 2 processors",
                type: "bg-danger-subtle text-danger",
                duration: "5000",
              });
            }
          }
        }
      } else {
        if (
          option.customization_option &&
          option.customization_option.customization_category
        ) {
          if (
            option.customization_option.customization_category.name == "RAM"
          ) {
            let ram_count = 0;
            let selected_rams = this.selectedOptions.filter(
              (option) =>
                option.option.customization_option.customization_category
                  .name == "RAM"
            );
            console.log(selected_rams);
            for (let ram of selected_rams) {
              ram_count += ram.selected_quantity;
            }

            let processor = this.selectedOptions.find(
              (option) =>
                option.option.customization_option &&
                option.option.customization_option.customization_category &&
                option.option.customization_option.customization_category
                  .parent_customization_category &&
                option.option.customization_option.customization_category
                  .parent_customization_category.name === "CPU"
            );

            if (processor) {
              if (processor.selected_quantity == 1) {
                if (ram_count - quantity < 1) {
                  this.$notify({
                    title: "RAM Count cannot be less than 1",
                    type: "bg-danger-subtle text-danger",
                    duration: "5000",
                  });
                  return false;
                } else {
                  return true;
                }
              } else if (processor.selected_quantity >= 2) {
                if (ram_count - quantity < 2) {
                  this.$notify({
                    title: "RAM Count cannot be less than 2",
                    type: "bg-danger-subtle text-danger",
                    duration: "5000",
                  });
                  return false;
                }
                if ((ram_count - quantity) % 2 !== 0) {
                  this.$notify({
                    title: "RAM Count should be a multiple of 2",
                    type: "bg-danger-subtle text-danger",
                    duration: "5000",
                  });
                  return false;
                }
                return true;
              }
            }
          } else if (
            option.customization_option.customization_category
              .parent_customization_category &&
            option.customization_option.customization_category
              .parent_customization_category.name == "Storage"
          ) {
            console.log("opt", option);
            let storage_count = 0;
            let sata_count = 0;
            let u2_count = 0;
            let selected_storage = this.selectedOptions.filter(
              (option) =>
                option.option.customization_option.customization_category
                  .parent_customization_category.name == "Storage"
            );
            console.log(selected_storage);
            for (let ssd of selected_storage) {
              storage_count += ssd.selected_quantity;
            }

            let selected_u2 = this.selectedOptions.filter(
              (option) =>
                option.option.customization_option.customization_category
                  .name == "U.2 SSD"
            );
            console.log(selected_u2);
            for (let ssd of selected_u2) {
              u2_count += ssd.selected_quantity;
            }

            if (storage_count - quantity < 1) {
              this.$notify({
                title: "Storage Drives cannot be less than 1",
                type: "bg-danger-subtle text-danger",
                duration: "5000",
              });
              return false;
            }
            console.log("sata and quant", sata_count + quantity);
            console.log("u2 and quant", u2_count + quantity);
            return true;
          }
        }
      }
    },
    verifyQuantityConfiguration(selectedOption, value) {
      console.log("hellooooo");
      console.log(selectedOption);
      console.log(value);
      let option = selectedOption.option;
      if (
        option.customization_option &&
        option.customization_option.customization_category
      ) {
        if (option.customization_option.customization_category.name == "RAM") {
          let ram_count = 0;
          let selected_rams = this.selectedOptions.filter(
            (option) =>
              option.option.customization_option.customization_category.name ==
              "RAM"
          );
          console.log(selected_rams);
          for (let ram of selected_rams) {
            ram_count += ram.selected_quantity;
          }
          console.log(ram_count);
          let quant_diff = value - selectedOption.selected_quantity;
          console.log("ram and quantdiff", ram_count + quant_diff);
          let processor = this.selectedOptions.find(
            (option) =>
              option.option.customization_option &&
              option.option.customization_option.customization_category &&
              option.option.customization_option.customization_category
                .parent_customization_category &&
              option.option.customization_option.customization_category
                .parent_customization_category.name === "CPU"
          );

          if (processor) {
            if (processor.selected_quantity == 1) {
              if (ram_count + quant_diff > 16) {
                this.$notify({
                  title: "RAM Count cannot be more than 16",
                  type: "bg-danger-subtle text-danger",
                  duration: "5000",
                });
                return false;
              } else {
                return true;
              }
            } else if (processor.selected_quantity >= 2) {
              console.log("here bro");
              if (ram_count + quant_diff > 32) {
                this.$notify({
                  title: "RAM Count cannot be more than 32 for 2 processors",
                  type: "bg-danger-subtle text-danger",
                  duration: "5000",
                });
              }
              if ((ram_count + quant_diff) % 2 !== 0) {
                {
                  this.$notify({
                    title:
                      "RAM Count should be a multiple of 2 for 2 processors",
                    type: "bg-danger-subtle text-danger",
                    duration: "5000",
                  });
                }
                return false;
              } else {
                return true;
              }
            }
          }
        } else if (
          option.customization_option.customization_category
            .parent_customization_category &&
          option.customization_option.customization_category
            .parent_customization_category.name == "Storage"
        ) {
          console.log("opt", option);
          let storage_count = 0;
          let sata_count = 0;
          let u2_count = 0;
          let selected_storage = this.selectedOptions.filter(
            (option) =>
              option.option.customization_option.customization_category
                .parent_customization_category.name == "Storage"
          );

          console.log(selected_storage);

          for (let ssd of selected_storage) {
            storage_count += ssd.selected_quantity;
          }

          let selected_u2 = this.selectedOptions.filter(
            (option) =>
              option.option.customization_option.customization_category.name ==
              "U.2 SSD"
          );
          console.log(selected_u2);
          for (let ssd of selected_u2) {
            u2_count += ssd.selected_quantity;
          }

          let quant_diff = value - selectedOption.selected_quantity;

          if (
            option.customization_option.customization_category.name == "U.2 SSD"
          ) {
            if (u2_count + quant_diff > 6) {
              this.$notify({
                title: "U.2 Storage Drives cannot be more than 6",
                type: "bg-danger-subtle text-danger",
                duration: "5000",
              });
              return false;
            }
          }

          if (storage_count + quant_diff > 24) {
            this.$notify({
              title: "Storage Drives cannot be more than 24",
              type: "bg-danger-subtle text-danger",
              duration: "5000",
            });
            return false;
          }
          return true;
        } else if (
          option.customization_option.customization_category
            .parent_customization_category &&
          option.customization_option.customization_category
            .parent_customization_category.name == "CPU"
        ) {
          console.log("cpu", option);
          console.log("quant", value);
          let ram_count = 0;
          let selected_rams = this.selectedOptions.filter(
            (option) =>
              option.option.customization_option.customization_category.name ==
              "RAM"
          );
          console.log(selected_rams);
          for (let ram of selected_rams) {
            ram_count += ram.selected_quantity;
          }
          if (value == 1) {
            if (ram_count > 16) {
              this.$notify({
                title: "RAM Count cannot be more than 16",
                type: "bg-danger-subtle text-danger",
                duration: "5000",
              });
            }
          } else if (value == 2) {
            if (ram_count > 32) {
              this.$notify({
                title: "RAM Count cannot be more than 32 for 2 processors",
                type: "bg-danger-subtle text-danger",
                duration: "5000",
              });
            }
            if (ram_count % 2 !== 0) {
              {
                this.$notify({
                  title: "RAM Count should be a multiple of 2 for 2 processors",
                  type: "bg-danger-subtle text-danger",
                  duration: "5000",
                });
              }
            }

            return true;
          } else {
            this.$notify({
              title: "There cannot be more than 2 processors",
              type: "bg-danger-subtle text-danger",
              duration: "5000",
            });
          }
        }
      }
    },
    updateSelectedOptions(
      option,
      index,
      optionIndex,
      event,
      childCustomizationIndex
    ) {
      const isSelected = event.target.checked;
      const keyPrefix = event.target.type === "radio" ? "s" : "m";
      this.oldSelectedOptions = this.selectedOptions;
      let quantity;
      if (option.customization_option.has_quantity) {
        if (childCustomizationIndex !== null) {
          quantity =
            this.quantities[
              `${keyPrefix}-${index}-${childCustomizationIndex}-${optionIndex}`
            ];
        } else {
          quantity = this.quantities[`${keyPrefix}-${index}-${optionIndex}`];
        }
      } else {
        quantity = option.default_quantity;
      }
      this.verifyConfiguration(option, quantity, event.target.checked);
      if (event.target.type === "radio") {
        if (isSelected) {
          let existingOptionIndex = -1;
          if (
            option.customization_option.customization_category_hierarchy == 3
          ) {
            if (
              option.customization_option.customization_category
                .parent_customization_category
            ) {
              existingOptionIndex = this.selectedOptions.findIndex(
                (o) =>
                  o.option.customization_option.customization_category
                    .parent_customization_category.customization_category_id ===
                  option.customization_option.customization_category
                    .parent_customization_category.customization_category_id
              );
            }
          } else {
            existingOptionIndex = this.selectedOptions.findIndex(
              (o) =>
                o.option.customization_option.customization_category
                  .customization_category_id ===
                option.customization_option.customization_category
                  .customization_category_id
            );
          }
          if (existingOptionIndex !== -1) {
            let oldPrice =
              this.selectedOptions[existingOptionIndex].option
                .customization_option.price *
              this.selectedOptions[existingOptionIndex].selected_quantity;
            this.selectedOptions[existingOptionIndex] = {
              option: option,
              selected_quantity: quantity,
            };
            let newPrice = option.customization_option.price * quantity;
            let priceDiff = newPrice - oldPrice;
            this.$emit("increasePrice", priceDiff);
          } else {
            this.selectedOptions.push({
              option: option,
              selected_quantity: quantity,
            });
            this.$emit(
              "increasePrice",
              option.customization_option.price * quantity
            );
          }
        }
      } else {
        if (isSelected) {
          this.selectedOptions.push({
            option: option,
            selected_quantity: quantity,
          });
          this.$emit(
            "increasePrice",
            option.customization_option.price * quantity
          );
        } else {
          const optionIndex = this.selectedOptions.findIndex(
            (o) => o.option === option
          );
          if (optionIndex !== -1) {
            this.selectedOptions.splice(optionIndex, 1);
            this.$emit(
              "decreasePrice",
              option.customization_option.price * quantity
            );
          }
        }
      }
      this.$emit("selectedOptions", this.selectedOptions);
      this.isSelectedOption = true;
      // } else {
      //   event.target.checked = !event.target.checked;
      // }
    },
    updateQuantity(option, event) {
      const selectedOption = this.selectedOptions.find(
        (o) =>
          o.option.product_customization_default_quantity_id ===
          option.product_customization_default_quantity_id
      );
      if (selectedOption) {
        this.verifyQuantityConfiguration(selectedOption, event.target.value);

        let oldPrice =
          selectedOption.selected_quantity *
          selectedOption.option.customization_option.price;
        selectedOption.selected_quantity = parseInt(event.target.value, 10);
        let newPrice =
          parseInt(event.target.value, 10) *
          selectedOption.option.customization_option.price;
        let priceDiff = newPrice - oldPrice;
        this.$emit("increasePrice", priceDiff);
      }
      this.$emit("selectedOptions", this.selectedOptions);
    },
    isOptionSelected(option) {
      return this.selectedOptions.some(
        (selectedOption) =>
          selectedOption.option.product_customization_default_quantity_id ===
          option.product_customization_default_quantity_id
      );
    },
    checkForOptions(customization) {
      return (
        customization.options.length !== 0 ||
        (customization.children_customization_categories &&
          customization.children_customization_categories.some(
            (custom) => custom.options.length !== 0
          ))
      );
    },
  },
};
</script>

<style scoped>
.nav-link.active {
  color: white !important;
  background-color: #dc3545 !important;
}

.nav-link {
  color: black !important;
  cursor: pointer;
}

.nav-link:not(.active):hover {
  color: grey !important;
} /* Override the default Bootstrap styles for accordion button focus state */
.accordion-button:focus {
  /* box-shadow: none; */
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  /* outline: #dc3545; */
  /* background-color: #ffffff;   */
}

/* Adjust styles for the accordion button when it's not in the 'collapsed' state (opened) */
.accordion-button:not(.collapsed) {
  background-color: #ffffff;
  /* color: #000000;  */
  /* outline: #dc3545; */
}
.accordion .accordion-focus {
  color: black;
}
/* .accordion-header button:focus .accordion-focus {
        outline: 2px solid #0000ff;
        outline-offset: 2px;
    } */
</style>
