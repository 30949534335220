<template>
  <div class="container" style="text-align: left">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home</a
          >
        </li>

        <li class="breadcrumb-item" aria-current="page">
          <a class="text-secondary" herf="/my-cart">
            <b>
              <i class="bi bi-cart3"></i>
              My Cart
            </b>
          </a>
        </li>
      </ol>
    </nav>
    <div v-if="$store.state.isLoading" class="conatiner">
      <LoadingBar />
    </div>
    <div v-else>
      <h1>My Cart</h1>
      <hr />
      <div v-if="cartItems.length === 0" class="text-center">
        <div class="empty-cart-message-container">
          <div class="empty-cart-message">
            <div class="text-overlay">
              <i class="bi bi-cart-x" style="font-size: 64px"></i><br />
              <button
                class="btn btn-outline-success"
                @click="$router.push('/')"
              >
                View Products
              </button>
              <p class="mt-3">Oops! Your cart is empty.</p>
              <p>Looks like you haven't added anything yet.</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-md-8">
            <CartItems
              :cart-items="cartItems"
              @cartItemUpdated="handleCartItemUpdated"
            />
          </div>
          <div class="col-md-4">
            <h4 class="mb-1 d-flex justify-content-between">
              <span>Discount Code</span>
              <button
                class="btn btn-secondary mr-2"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#applyCouponCodes"
              >
                View All
              </button>
              <!-- v-if="this.$store.state.user.isStaff" -->
              <button
                v-if="this.$store.state.user.isStaff"
                class="btn btn-secondary"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#customDiscountModal"
              >
                Custom Discount
              </button>
            </h4>
            <span
              v-if="appliedCode"
              class="badge bg-success-subtle text-success mb-1"
            >
              {{ appliedCode.discount_code }} Applied
              <button
                class="btn-close btn-close-dark"
                @click="clearAppliedCode"
                aria-label="Clear Applied Code"
              ></button>
            </span>
            <div class="d-flex justify-content-between mb-3"></div>

            <ul class="list-group mb-3">
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <div>
                  <h6 class="my-0">Sub Total</h6>
                </div>
                <span class="text-muted">
                  ₹ {{ $parsePriceToString(subtotalAmount) }}
                </span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <div class="text-success">
                  <h6 class="my-0">Savings</h6>
                </div>
                <span class="text-success">₹ {{ savings }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <span><b>Total</b> </span>

                <strong>₹ {{ $parsePriceToString(totalAmount) }}</strong>
              </li>
            </ul>

            <!-- Button for staff users -->
            <button
              class="w-100 btn btn-success btn-lg"
              type="button"
              @click="$router.push('/customer-checkout')"
              v-if="
                $store.state.user.isAuthenticated && $store.state.user.isStaff
              "
            >
              Continue to Customer Checkout
            </button>

            <!-- Button for non-staff authenticated users -->
            <button
              class="w-100 btn btn-success btn-lg"
              type="button"
              @click="$router.push('/checkout')"
              v-else-if="$store.state.user.isAuthenticated"
            >
              Continue to Checkout
            </button>

            <!-- Button for unauthenticated users -->
            <button
              class="w-100 btn btn-success btn-lg"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#signUpModal"
              v-else
            >
              Continue to Checkout
            </button>
          </div>
        </div>

        <div
          class="modal fade"
          id="applyCouponCodes"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="applyCouponCodesLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="applyCouponCodes">
                  Discount Codes
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="container">
                  <input
                    type="text"
                    class="form-control mb-2"
                    v-model="searchTerm"
                    placeholder="Search Discount Codes"
                  />

                  <div
                    class="form-check"
                    v-for="(code, index) in filteredCodes"
                    :key="index"
                  >
                    <input
                      type="radio"
                      class="form-check-input"
                      :id="'discountCode' + index"
                      :value="code"
                      v-model="selectedCode"
                    />
                    <label
                      class="form-check-label"
                      :for="'discountCode' + index"
                    >
                      <b>
                        {{ code.discount_code }}
                      </b>
                    </label>
                    <div v-if="code">
                      <span v-if="code.note">
                        {{ code.note }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-outline-success"
                  data-bs-dismiss="modal"
                  @click="applyCoupon"
                >
                  Apply Coupon
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="signUpModal"
          tabindex="-1"
          aria-labelledby="signUpModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="signUpModalLabel">
                  You need to have an account to proceed
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <SignUpForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="customDiscountModal"
      tabindex="-1"
      aria-labelledby="customDiscountModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="customDiscountModalLabel">
              Custom Discount Code
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <input
              type="text"
              class="form-control"
              placeholder="Enter Discount amount"
              v-model="customCode"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              @click="createCustomDiscount"
            >
              Request Approval
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="customDiscountModal"
      tabindex="-1"
      aria-labelledby="customDiscountModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="customDiscountModalLabel">
              Custom Discount Code
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <input
              type="text"
              class="form-control"
              placeholder="Enter Discount amount"
              v-model="customCode"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              @click="createCustomDiscount"
            >
              Request Approval
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartItems from "@/components/Orders/CartItems.vue";
import SignUpForm from "@/components/Account/SignUpForm.vue";
import LoadingBar from "@/components/layout/LoadingBar.vue";
export default {
  components: {
    CartItems,
    SignUpForm,
    LoadingBar,
  },
  data() {
    return {
      cartItems: [],
      savings: 0,
      discountCodes: [],
      searchTerm: "",
      selectedCode: "",
      appliedCode: "",
      customCode: "",
      orderId: "",
    };
  },
  computed: {
    subtotalAmount() {
      let subTotal = 0;
      for (let cartItem of this.cartItems) {
        if (cartItem.customizedPrice) {
          subTotal += cartItem.customizedPrice * cartItem.quantity;
        } else {
          subTotal += cartItem.price * cartItem.quantity;
        }
      }
      return subTotal;
    },
    totalAmount() {
      return this.subtotalAmount - this.savings;
    },
    filteredCodes() {
      const searchTermLower = this.searchTerm.toLowerCase();
      return this.discountCodes.filter((code) =>
        code.discount_code.toLowerCase().includes(searchTermLower)
      );
    },
  },
  watch: {
    totalAmount(cartTotal) {
      if (this.$store.state.user.isAuthenticated) {
        localStorage.setItem("userCartTotal", cartTotal);
        this.$store.commit("updateUserCartTotal", cartTotal);
      } else {
        sessionStorage.setItem("cartTotal", cartTotal);
        this.$store.commit("updateCartTotal", cartTotal);
      }
    },
  },
  mounted() {
    console.log(" the user", this.$store.state.user.isStaff);
    this.getMyCart();
  },

  methods: {
    async createCustomDiscount() {
      const requestData = {
        amount: this.customCode,
        order_id: this.orderId,
        // discount_code: null,
      };

      await axios
        .post("orders/create_custom_discount/", requestData)
        .then((response) => {
          console.log("Custom discount created:", response.data);
          this.$notify({
            title: " Approval request send successfully.",
            type: "bg-danger-subtle text-success",
            duration: "5000",
          });
        })
        .catch((error) => {
          console.error("Error creating custom discount:", error);
        });
    },

    async getMyCart() {
      if (this.$store.state.user.isAuthenticated) {
        this.$store.commit("setIsLoading", true);

        await axiosInstance
          .get("orders/get-cart/")
          .then((response) => {
            this.cartItems = response.data.cart.order_items;
            this.orderId = response.data.cart.order_id;
            this.discountCodes = response.data.discount_codes;
            console.log("Cart Data from API:", this.cartItems);
            if (this.$store.state.user?.userAppliedDiscountCode.id) {
              this.appliedCode = this.$store.state.user.userAppliedDiscountCode;
              this.selectedCode =
                this.$store.state.user.userAppliedDiscountCode;
              this.applyCoupon();
            }
          })
          .catch((error) => {
            console.log(error);
          });

        this.$store.commit("setIsLoading", false);
      } else {
        this.cartItems = this.$store.state.cart;

        this.$store.commit("setIsLoading", true);

        await axiosInstance
          .get("orders/get-discount-codes/")
          .then((response) => {
            this.discountCodes = response.data.discount_codes;
          })
          .catch((error) => {
            console.log(error);
          });

        this.$store.commit("setIsLoading", false);
        console.log("vue store cart", this.cartItems);
        if (this.$store.state.appliedDiscountCode) {
          this.appliedCode = this.$store.state.appliedDiscountCode;
          this.selectedCode = this.$store.state.appliedDiscountCode;
          this.applyCoupon();
        }
      }
    },
    async handleCartItemUpdated(cartItemData) {
      let cartItem = cartItemData.cartItem;
      let type = cartItemData.type;
      let updatedCartItem = this.cartItems.filter(
        (item) => item == cartItem
      )[0];
      if (type == "increase") {
        if (this.$store.state.user.isAuthenticated) {
          await this.updateCartItem(updatedCartItem, "increase");
        } else {
          updatedCartItem.quantity += 1;
        }
      } else if (type == "decrease") {
        if (this.$store.state.user.isAuthenticated) {
          await this.updateCartItem(updatedCartItem, "decrease");
        } else {
          updatedCartItem.quantity -= 1;
        }
      } else {
        if (this.$store.state.user.isAuthenticated) {
          await this.deleteCartItem(cartItem);
        } else {
          if (updatedCartItem) {
            let index = this.cartItems.indexOf(updatedCartItem);
            if (index !== -1) {
              this.cartItems.splice(index, 1);
            }
          }
        }
      }
      if (this.$store.state.user.isAuthenticated) {
        localStorage.setItem("userCart", JSON.stringify(this.cartItems));
        this.$store.commit("updateUserCart", this.cartItems);
      } else {
        sessionStorage.setItem("cart", JSON.stringify(this.cartItems));
        this.$store.commit("updateCart", this.cartItems);
        this.$notify({
          title: "Cart Updated Successfuly",
          type: "bg-success-subtle text-success",
          duration: "5000",
        });
      }
      this.handleDiscountChange();
    },
    async deleteCartItem(cartItem) {
      this.$store.commit("setIsLoading", true);
      await axiosInstance
        .delete(`orders/delete-cart-item/${cartItem.order_item_id}/`)
        .then((response) => {
          console.log(response.data);
          this.cartItems = response.data.cart_items;
          localStorage.setItem("userCart", JSON.stringify(this.cartItems));
          this.$store.commit("updateUserCart", this.cartItems);
          this.$notify({
            title: "Cart Updated Successfuly",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$notify({
            title: "An error occured, please try again later",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
          this.$store.commit("setIsLoading", false);
        });
    },
    async updateCartItem(cartItem, type) {
      this.$store.commit("setIsLoading", true);
      const orderItemID = cartItem.order_item_id;
      let data = {};
      if (type == "increase") {
        data = {
          quantity: cartItem.quantity + 1,
        };
      } else if (type == "decrease") {
        data = {
          quantity: cartItem.quantity - 1,
        };
      }
      await axiosInstance
        .put(`orders/update-cart-item/${orderItemID}/`, data)
        .then((response) => {
          console.log(response.data);
          this.cartItems = response.data.cart_items;
          localStorage.setItem("userCart", JSON.stringify(this.cartItems));
          this.$store.commit("updateUserCart", this.cartItems);
          this.$notify({
            title: "Cart Updated Successfuly",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$notify({
            title: "An error occured, please try again later",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
          this.$store.commit("setIsLoading", false);
        });
    },

    isCouponActive() {
      return this.selectedCode.is_active == 1;
    },
    hasCouponExpired() {
      const currentDate = new Date();
      const expirationDate = new Date(this.selectedCode.expiration_date);
      return currentDate > expirationDate;
    },
    hasMinimumCartAmount() {
      return (
        this.subtotalAmount >=
        parseFloat(this.selectedCode.min_amount_for_discount)
      );
    },
    isCouponApplicableToCart() {
      const applicableProducts = this.selectedCode.applicable_products;
      const cartProductIds = this.cartItems.map(
        (item) => item.product.product_id
      );
      const maxApplicableProducts =
        this.selectedCode.max_number_of_applicable_products;

      return (
        applicableProducts.some((product) =>
          cartProductIds.includes(product.product_id)
        ) && applicableProducts.length <= maxApplicableProducts
      );
    },
    async maxUsageCheck(discountCode) {
      if (this.$store.state.user.isAuthenticated) {
        await axiosInstance
          .get(
            `orders/check-discount-code-user-usage/${discountCode.discount_code_id}/`
          )
          .then((response) => {
            console.log(response.data);
            let checkPassed = response.data.check_passed;
            return checkPassed;
          })
          .catch((error) => {
            console.error("Error:", error);
            return false;
          });
      } else {
        return true;
      }
    },
    applyDiscount() {
      const discountPercentage = parseFloat(
        this.selectedCode.discount_percentage
      );
      const maxDiscountAmount = parseFloat(
        this.selectedCode.max_discount_amount
      );
      const discount = parseFloat(this.selectedCode.discount_amount);
      var discountAmount = 0;
      if (discountPercentage) {
        console.log("subtotal", this.subtotalAmount);
        discountAmount += (this.subtotalAmount * discountPercentage) / 100;
      }
      if (discount) {
        discountAmount += discount;
      }
      console.log("discount amount", discountAmount);
      return Math.min(discountAmount, maxDiscountAmount);
    },
    applyCoupon() {
      if (!this.isCouponActive()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: "Coupon is not active.",
        });
        return;
      }

      if (this.hasCouponExpired()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: "Coupon has expired.",
        });
        return;
      }

      if (!this.hasMinimumCartAmount()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: `Minimum cart total of ${this.selectedCode.min_amount_for_discount} is required for this coupon.`,
        });
        return;
      }

      if (!this.isCouponApplicableToCart()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: `Coupon is not applicable to more than ${this.selectedCode.max_number_of_applicable_products} products in the cart.`,
        });
        return;
      }

      if (!this.maxUsageCheck(this.selectedCode)) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: "You have already reached the maximum usage for this coupon.",
        });
        return;
      }

      const discountAmount = this.applyDiscount();

      this.savings = discountAmount;
      this.appliedCode = this.selectedCode;
      if (this.$store.state.user.isAuthenticated) {
        localStorage.setItem(
          "userAppliedDiscountCode",
          JSON.stringify(this.appliedCode)
        );
        this.$store.commit("updateUserAppliedDiscountCode", this.appliedCode);
      } else {
        sessionStorage.setItem(
          "appliedDiscountCode",
          JSON.stringify(this.appliedCode)
        );
        this.$store.commit("updateAppliedDiscountCode", this.appliedCode);
      }

      this.$notify({
        title: "Coupon Applied",
        type: "bg-success-subtle text-success",
        text: "Coupon applied successfully.",
        duration: 5000,
      });
    },
    handleDiscountChange() {
      if (this.appliedCode) {
        if (!this.isCouponActive()) {
          this.appliedCode = "";
          if (this.$store.state.user.isAuthenticated) {
            localStorage.setItem(
              "userAppliedDiscountCode",
              JSON.stringify(this.appliedCode)
            );
            this.$store.commit(
              "updateUserAppliedDiscountCode",
              this.appliedCode
            );
          } else {
            sessionStorage.setItem(
              "appliedDiscountCode",
              JSON.stringify(this.appliedCode)
            );
            this.$store.commit("updateAppliedDiscountCode", this.appliedCode);
          }

          this.savings = 0;
          this.$notify({
            title: "Coupon Error",
            type: "bg-danger-subtle text-danger",
            text: "Coupon is not active.",
          });
          return;
        }

        if (this.hasCouponExpired()) {
          this.appliedCode = "";
          if (this.$store.state.user.isAuthenticated) {
            localStorage.setItem(
              "userAppliedDiscountCode",
              JSON.stringify(this.appliedCode)
            );
            this.$store.commit(
              "updateUserAppliedDiscountCode",
              this.appliedCode
            );
          } else {
            sessionStorage.setItem(
              "appliedDiscountCode",
              JSON.stringify(this.appliedCode)
            );
            this.$store.commit("updateAppliedDiscountCode", this.appliedCode);
          }

          this.savings = 0;
          this.$notify({
            title: "Coupon Error",
            type: "bg-danger-subtle text-danger",
            text: "Coupon has expired.",
          });
          return;
        }

        if (!this.hasMinimumCartAmount()) {
          this.appliedCode = "";
          if (this.$store.state.user.isAuthenticated) {
            localStorage.setItem(
              "userAppliedDiscountCode",
              JSON.stringify(this.appliedCode)
            );
            this.$store.commit(
              "updateUserAppliedDiscountCode",
              this.appliedCode
            );
          } else {
            sessionStorage.setItem(
              "appliedDiscountCode",
              JSON.stringify(this.appliedCode)
            );
            this.$store.commit("updateAppliedDiscountCode", this.appliedCode);
          }

          this.savings = 0;
          this.$notify({
            title: "Coupon Error",
            type: "bg-danger-subtle text-danger",
            text: `Minimum cart total of ${this.selectedCode.min_amount_for_discount} is required for this coupon.`,
          });
          return;
        }

        if (!this.isCouponApplicableToCart()) {
          this.appliedCode = "";
          if (this.$store.state.user.isAuthenticated) {
            localStorage.setItem(
              "userAppliedDiscountCode",
              JSON.stringify(this.appliedCode)
            );
            this.$store.commit(
              "updateUserAppliedDiscountCode",
              this.appliedCode
            );
          } else {
            sessionStorage.setItem(
              "appliedDiscountCode",
              JSON.stringify(this.appliedCode)
            );
            this.$store.commit("updateAppliedDiscountCode", this.appliedCode);
          }

          this.savings = 0;
          this.$notify({
            title: "Coupon Error",
            type: "bg-danger-subtle text-danger",
            text: `Coupon is not applicable to more than ${this.selectedCode.max_number_of_applicable_products} products in the cart.`,
          });
          return;
        }

        if (!this.maxUsageCheck(this.appliedCode)) {
          this.appliedCode = "";
          if (this.$store.state.user.isAuthenticated) {
            localStorage.setItem(
              "userAppliedDiscountCode",
              JSON.stringify(this.appliedCode)
            );
            this.$store.commit(
              "updateUserAppliedDiscountCode",
              this.appliedCode
            );
          } else {
            sessionStorage.setItem(
              "appliedDiscountCode",
              JSON.stringify(this.appliedCode)
            );
            this.$store.commit("updateAppliedDiscountCode", this.appliedCode);
          }

          this.savings = 0;
          this.$notify({
            title: "Coupon Error",
            type: "bg-danger-subtle text-danger",
            text: "You have already reached the maximum usage for this coupon.",
          });
          return;
        }

        const discountAmount = this.applyDiscount();
        console.log("updated dis", discountAmount);
        this.savings = discountAmount;
      } else {
        this.savings = 0;
      }
    },
    clearAppliedCode() {
      this.appliedCode = "";
      this.selectedCode = "";
      this.savings = 0;
      if (this.$store.state.user.isAuthenticated) {
        localStorage.setItem(
          "userAppliedDiscountCode",
          JSON.stringify(this.appliedCode)
        );
        this.$store.commit("updateUserAppliedDiscountCode", this.appliedCode);
      } else {
        sessionStorage.setItem(
          "appliedDiscountCode",
          JSON.stringify(this.appliedCode)
        );
        this.$store.commit("updateAppliedDiscountCode", this.appliedCode);
      }
    },
  },
};
</script>

<style scoped>
.empty-cart-message-container {
  /* height: 120vh; */
  /* height: 60vh; */
  /* background-image: url("https://cdn.dribbble.com/users/3535615/screenshots/16002872/media/dd9639f3dc084102684565922d8cd5e1.png"); */
  background-size: 40%;
  /* background-size: cover, auto; */
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-cart-message {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.text-overlay {
  text-align: center;
}

.text-overlay p {
  font-size: 24px;
  color: #333;
}
</style>
