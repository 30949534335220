import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.js";
import axios from "axios";
import Notifications from "@kyvg/vue3-notification";
import axiosInstance from "./utils/axiosInstance";
import emitter from "./utils/eventBus"; // Correct path

// Set axiosInstance as a global variable
window.axiosInstance = axiosInstance;

const parsePriceToFloat = (inputString) => {
  const stringWithoutCommas = inputString.replace(/,/g, "");
  const floatNumber = parseFloat(stringWithoutCommas);
  return floatNumber;
};

const parsePriceToString = (inputString) => {
  const numericValue = parseFloat(inputString);
  if (!isNaN(numericValue)) {
    return numericValue.toLocaleString("en-IN");
  }
  return inputString;
};

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:8000/api/v1/";
} else {
  axios.defaults.baseURL = "https://prysmdev.xtractautomation.com/api/v1/";
}

const app = createApp(App);
// Set axiosInstance as the default Axios instance
app.config.globalProperties.$parsePriceToFloat = parsePriceToFloat;
app.config.globalProperties.$parsePriceToString = parsePriceToString;

app.use(store).use(router).use(Notifications);
// Listen for notification events
emitter.on("notify", (notification) => {
  app.config.globalProperties.$notify({
    title: notification.title,
    type: notification.type,
    duration: notification.duration,
  });
});

emitter.on("sessionExpired", () => {
  app.config.globalProperties.$notify({
    title: "Your session has expired. Please sign in again.",
    type: "bg-danger-subtle text-danger",
    duration: 5000,
  });
});

app.mount("#app");
