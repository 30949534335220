<template>
    <footer class="bg-light text-center py-3">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <h4>About Us</h4>
                    <!-- <p>We are a leading technology company dedicated to providing innovative solutions for your business
                        needs.</p> -->
                    <ul class="list-inline">
                        <li class="list-inline-item"><a href="https://prysmelectronics.net/our-company">Our Company</a></li>
                        <br>
                        <br>
                        <li class="list-inline-item"><a
                                href="https://prysmelectronics.net/prysm-manufacturing">Manufacturing</a></li>
                        <br>
                        <br>
                        <li class="list-inline-item"><a href="https://prysmelectronics.net/privacy-policy">Privacy and
                                Policy</a></li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <h4>Contact Us</h4>
                    <p>No. 43, Velankani Tech Park,
                        Electronics City Phase-1,
                        Hosur Road, Bengaluru- 560100,
                        Karnataka
                        +91 80 6614 5807 insidesales@velankanigroup.com</p>

                </div>
                <div class="col-md-4">
                    <h4>Follow Us</h4>
                    <ul class="list-inline">
                        <li class="list-inline-item"><a href="https://www.facebook.com/PrysmElectronics/"><i
                                    class="bi bi-facebook"></i></a></li>
                        <li class="list-inline-item"><a href="#"><i class="bi bi-twitter"></i></a></li>
                        <li class="list-inline-item"><a href="#"><i class="bi bi-linkedin"></i></a></li>
                        <li class="list-inline-item"><a href="#"><i class="bi bi-instagram"></i></a></li>
                        <li class="list-inline-item"><a href="https://www.youtube.com/channel/UC9lfP8tMvZdoSBfme5IeSpw"><i
                                    class="bi bi-youtube" style="color: red;"></i></a></li>
                    </ul>

                </div>
            </div>
        </div>
    </footer>
</template>