<template>
  <div class="container" v-if="activeMenu == 'signUp'">
    <h4>Sign Up</h4>
    <div class="mb-3">
      <label for="exampleInputFirstName" class="form-label">First Name</label>
      <input
        type="text"
        class="form-control"
        v-model="signUpForm.firstName"
        :class="{ 'is-invalid': !signUpForm.firstName && signUpSubmitted }"
      />
      <div
        class="invalid-feedback"
        v-if="!signUpForm.firstName && signUpSubmitted"
      >
        Please enter your first name.
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleInputLastName" class="form-label">Last Name</label>
      <input type="text" class="form-control" v-model="signUpForm.lastName" />
    </div>
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Email address</label>
      <input
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        v-model="signUpForm.email"
        :class="{ 'is-invalid': !signUpForm.email && signUpSubmitted }"
      />
      <div class="invalid-feedback" v-if="!signUpForm.email && signUpSubmitted">
        Please enter your email.
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Password</label>
      <input
        v-model="signUpForm.password"
        type="password"
        class="form-control"
        id="exampleInputPassword1"
        :class="{ 'is-invalid': !signUpForm.password && signUpSubmitted }"
      />
      <div
        class="invalid-feedback"
        v-if="!signUpForm.password && signUpSubmitted"
      >
        Please enter your password.
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label"
        >Repeat Password</label
      >
      <input
        v-model="signUpForm.repeatPassword"
        type="password"
        class="form-control"
        id="exampleInputPassword1"
        :class="{ 'is-invalid': !signUpForm.repeatPassword && signUpSubmitted }"
      />
      <div
        class="invalid-feedback"
        v-if="!signUpForm.repeatPassword && signUpSubmitted"
      >
        Please repeat your password.
      </div>
    </div>
    <div class="d-flex mt-2">
      <p class="text-muted mb-0">
        Have an account already?
        <button class="btn text-primary" @click="activeMenu = 'signIn'">
          Login here
        </button>
      </p>
    </div>
    <button @click="signUp" class="btn btn-success mt-4">Submit</button>
  </div>

  <div class="container" v-else-if="activeMenu == 'signIn'">
    <h4>Log In</h4>
    <div class="mb-3">
      <label for="exampleInputEmail2" class="form-label">Email address</label>
      <input
        v-model="logInForm.email"
        type="email"
        class="form-control"
        id="exampleInputEmail2"
        :class="{ 'is-invalid': !logInForm.email && logInSubmitted }"
      />
      <div class="invalid-feedback" v-if="!logInForm.email && logInSubmitted">
        Please enter your email.
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword2" class="form-label">Password</label>
      <input
        v-model="logInForm.password"
        type="password"
        class="form-control"
        id="exampleInputPassword2"
        :class="{ 'is-invalid': !logInForm.password && logInSubmitted }"
      />
      <div
        class="invalid-feedback"
        v-if="!logInForm.password && logInSubmitted"
      >
        Please enter your password.
      </div>
    </div>
    <div class="d-flex mt-2">
      <p class="text-muted mb-0">
        Don't have an account?
        <button class="btn text-primary" @click="activeMenu = 'signUp'">
          Sign Up here
        </button>
      </p>
    </div>
    <button @click="logIn" class="btn btn-success mt-4">Submit</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      signUpForm: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repeatPassword: "",
        agreeTerms: true,
      },
      logInForm: {
        email: "",
        password: "",
      },
      activeMenu: "signUp",
      signUpSubmitted: false,
      logInSubmitted: false,
    };
  },
  mounted() {},
  components: {},
  computed: {},
  methods: {
    async signUp() {
      this.signUpSubmitted = true;

      if (
        !this.signUpForm.firstName ||
        !this.signUpForm.lastName ||
        !this.signUpForm.email ||
        !this.signUpForm.password ||
        !this.signUpForm.repeatPassword ||
        this.signUpForm.password !== this.signUpForm.repeatPassword
      ) {
        this.$notify({
          title: "Please fill in all required fields correctly.",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
        return;
      }

      // Check if passwords match
      if (this.signUpForm.password !== this.signUpForm.repeatPassword) {
        this.$notify({
          title: "Passwords do not match.",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
        return;
      }

      await axios
        .post("accounts/create-user/", this.signUpForm)
        .then((response) => {
          console.log(response.data);
          this.$notify({
            title: "Account created Successfully",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
          this.logInForm.email = this.signUpForm.email;
          this.logInForm.password = this.signUpForm.password;
          this.logIn();
          // this.$router.push("/sign-in");
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Something went wrong. Please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },

    async logIn() {
      this.logInSubmitted = true;

      if (!this.logInForm.email || !this.logInForm.password) {
        this.$notify({
          title: "Please fill in all required fields.",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
        return;
      }
      this.$store.commit("setIsLoading", true);
      await axiosInstance
        .post("accounts/token-jwt/create-new/", this.logInForm)
        .then((response) => {
          console.log(response.data);
          const accessToken = response.data.access;
          const refreshToken = response.data.refresh;
          this.$store.commit("setAccessToken", accessToken);
          this.$store.commit("setRefreshToken", refreshToken);
          axiosInstance.defaults.headers.common["Authorization"] =
            "JWT " + accessToken;
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          this.getUserAndUpdateCart();
          this.$notify({
            title: "Logged In Successfully",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            if (error.response.status == 401 || error.response.status == 400) {
              for (const property in error.response.data) {
                this.$notify({
                  title: error.response.data[property],
                  type: "bg-danger-subtle text-danger",
                  duration: "5000",
                });
              }
            } else {
              this.$notify({
                title: "Something went wrong. Please try again",
                type: "bg-danger-subtle text-danger",
                duration: "5000",
              });
            }
          } else {
            this.$notify({
              title: "Something went wrong. Please try again",
              type: "bg-danger-subtle text-danger",
              duration: "5000",
            });
          }
          this.$store.commit("setIsLoading", false);
        });
    },
    async getUserAndUpdateCart() {
      this.$store.commit("setIsLoading", true);
      console.log("already stored code", this.$store.state.appliedDiscountCode);
      let data = {
        cart: this.$store.state.cart,
        cartTotal: this.$store.state.cartTotal,
        appliedDiscountCode: this.$store.state.appliedDiscountCode,
      };
      await axiosInstance
        .post("accounts/get-user-update-cart/", data)
        .then((response) => {
          console.log(response.data);
          localStorage.setItem("firstName", response.data.user.first_name);
          localStorage.setItem("lastName", response.data.user.last_name);
          localStorage.setItem("userRole", response.data.user.role);
          localStorage.setItem("isStaff", response.data.user.is_staff);
          localStorage.setItem(
            "userCart",
            JSON.stringify(response.data.cart.order_items)
          );
          localStorage.setItem(
            "userCartTotal",
            response.data.cart.total_amount
          );
          localStorage.setItem(
            "userAppliedDiscountCode",
            JSON.stringify(response.data.discount_code)
          );

          this.$store.commit("setUserDetails", response.data.user);
          this.$store.commit("updateUserCart", response.data.cart.order_items);
          this.$store.commit(
            "updateUserCartTotal",
            response.data.cart.total_amount
          );
          this.$store.commit(
            "updateUserAppliedDiscountCode",
            response.data.discount_code
          );
          this.$store.commit("setIsLoading", false);
          // window.location.href = "/checkout";
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Something went wrong. Please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
          this.$store.commit("setIsLoading", false);
        });
    },
  },
};
</script>
