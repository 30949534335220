<template>
  <div>
    <label for="utrNumber" class="form-label">Enter UTR number</label>
    <input
      type="text"
      class="form-control"
      id="utrNumber"
      v-model="utrNumber"
    />

    <label for="fileUpload" class="form-label">Upload UTR file</label>
    <input
      type="file"
      class="form-control"
      id="fileUpload"
      @change="onFileChange"
    />

    <br />
    <br />

    <button
      class="btn btn-primary mt-2"
      data-bs-dismiss="modal"
      @click="submitUTR"
      style="margin-left: 80%"
    >
      Submit
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      utrNumber: "",
      utrFile: null,
    };
  },
  methods: {
    onFileChange(event) {
      this.utrFile = event.target.files[0];
    },
    async submitUTR() {
      const orderId = this.$route.params.orderId;
      const formData = new FormData();
      formData.append("utr_number", this.utrNumber);
      if (this.utrFile) {
        formData.append("utr_file", this.utrFile);
      }
      try {
        const response = await axiosInstance.post(
          `/orders/update_order_with_utr/${orderId}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.$notify({
          type: "bg-success-subtle text-success",
          title: "Success",
          text: " UTR Details Uploaded Successfully.",
        });
        console.log("Order updated successfully", response.data);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error("Error updating order:", error);
      }
    },
  },
};
</script>
