<template>
  <div id="app" class="container mt-2">
    <form v-if="activeSection === 'general'">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Customer Type</label>
        <div class="col-sm-10">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="customerTypes"
              id="businessType"
              value="Business"
              v-model="customerType"
            />
            <label class="form-check-label" for="businessType">Business</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="customerTypes"
              id="consumerType"
              value="Consumer"
              v-model="customerType"
            />
            <label class="form-check-label" for="consumerType"
              >Individual</label
            >
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="primaryContactFirstName" class="col-sm-2 col-form-label"
          >Primary Contact</label
        >
        <div class="col-sm-5">
          <input
            v-model="primaryContact.firstName"
            type="text"
            class="form-control"
            id="primaryContactFirstName"
            placeholder="First Name"
          />
          <small
            class="text-danger"
            v-if="showAllErrors && !primaryContact.firstName"
            >First name is required.</small
          >
        </div>
        <div class="col-sm-5">
          <input
            v-model="primaryContact.lastName"
            type="text"
            class="form-control"
            id="primaryContactLastName"
            placeholder="Last Name"
          />
          <small
            class="text-danger"
            v-if="showAllErrors && !primaryContact.lastName"
            >Last name is required.</small
          >
        </div>
      </div>

      <div class="form-group row">
        <label for="companyName" class="col-sm-2 col-form-label"
          >Customer Name</label
        >
        <div class="col-sm-10">
          <input
            v-model="companyName"
            type="text"
            class="form-control"
            id="companyName"
          />
          <small class="text-danger" v-if="showAllErrors && !companyName"
            >Company name is required.</small
          >
        </div>
      </div>
      <div class="form-group row">
        <label for="customerPhoneNumber" class="col-sm-2 col-form-label"
          >Customer Phone</label
        >
        <div class="col-sm-4">
          <input
            v-model="customerPhoneNumber"
            type="tel"
            class="form-control"
            id="customerPhoneNumber"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="customerEmail" class="col-sm-2 col-form-label"
          >Customer Email</label
        >
        <div class="col-sm-4">
          <input
            v-model="customerEmail"
            type="email"
            class="form-control"
            id="customerEmail"
          />
          <small class="text-danger" v-if="showAllErrors && !customerEmail"
            >Customer Email is required.</small
          >
        </div>
      </div>

      <div class="form-group row" v-if="!edit">
        <label for="emailPassword" class="col-sm-2 col-form-label"
          >Password</label
        >
        <div class="col-sm-4">
          <input
            v-model="emailPassword"
            @input="showConfirmPassword = !!emailPassword"
            type="password"
            class="form-control"
            id="emailPassword"
          />
          <small class="text-danger" v-if="showAllErrors && !emailPassword"
            >Add the password.</small
          >
        </div>
      </div>

      <div v-if="showConfirmPassword" class="form-group row">
        <label for="confirmEmailPassword" class="col-sm-2 col-form-label"
          >Confirm Password</label
        >
        <div class="col-sm-4">
          <input
            v-model="confirmEmailPassword"
            type="password"
            class="form-control"
            id="confirmEmailPassword"
          />
        </div>
      </div>
    </form>

    <div class="row">
      <div v-if="activeSection === 'content1'">
        <form>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">GST Treatment:</label>
            <div class="col-sm-5">
              <select v-model="formData.gstTreatment" class="form-control">
                <option value="" disabled>Select GST Treatment</option>
                <option value="Registered Business">Registered Business</option>
                <option value="Unregistered Business">
                  Unregistered Business
                </option>
                <option value="Consumer">Consumer</option>
              </select>
              <small
                class="text-danger"
                v-if="showAllErrors && !formData.gstTreatment"
                >Select the Gst Treatment type.</small
              >
            </div>
          </div>

          <div v-if="formData.gstTreatment === 'Registered Business'">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">GSTIN/UIN:</label>
              <div class="col-sm-5">
                <input
                  type="text"
                  v-model="formData.gstinUIN"
                  class="form-control"
                />
                <small
                  class="text-danger"
                  v-if="showAllErrors && !formData.gstinUIN"
                  >GstinUIN is required.</small
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Business Legal Name:</label
              >
              <div class="col-sm-5">
                <input
                  type="text"
                  v-model="formData.businessLegalName"
                  class="form-control"
                />
                <small
                  class="text-danger"
                  v-if="showAllErrors && !formData.businessLegalName"
                  >Business Legal Name is required.</small
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Business Trade Name:</label
              >
              <div class="col-sm-5">
                <input
                  type="text"
                  v-model="formData.businessTradeName"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">PAN:</label>
            <div class="col-sm-5">
              <input
                type="text"
                v-model="formData.pan"
                class="form-control"
                required
              />
              <small class="text-danger" v-if="showAllErrors && !formData.pan"
                >Pan is required.</small
              >
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Currency:</label>
            <div class="col-sm-5">
              <select v-model="formData.currency" class="form-control">
                <option
                  v-for="currency in currencies"
                  :value="currency.country_id"
                  :key="currency.country_id"
                >
                  {{ currency.name }} - {{ currency.currency_code }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Tax Preference:</label>
            <div class="col-sm-2 form-check">
              <input
                type="radio"
                v-model="formData.taxPreference"
                value="Taxable"
                id="taxable"
                name="taxPreference"
                class="form-check-input"
                checked
              />
              <label for="taxable" class="form-check-label">Taxable</label>
            </div>
            <div class="col-sm-3 form-check">
              <input
                type="radio"
                v-model="formData.taxPreference"
                value="Tax Exempt"
                id="taxExempt"
                name="taxPreference"
                class="form-check-input"
              />
              <label for="taxExempt" class="form-check-label">Tax Exempt</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Place of Supply:</label>
            <div class="col-sm-5">
              <select v-model="formData.placeOfSupply" class="form-control">
                <option
                  v-for="placeOfSupply in filteredPlaceOfSupplyOptions"
                  :value="placeOfSupply.region_id"
                  :key="placeOfSupply.region_id"
                >
                  {{ placeOfSupply.name }}
                </option>
              </select>
              <small
                class="text-danger"
                v-if="showAllErrors && !formData.placeOfSupply"
                >Select the Place of Supply.</small
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Payment Terms:</label>
            <div class="col-sm-5">
              <select v-model="selectedPaymentTerm" class="form-control">
                <option value="" disabled>Select Payment Terms</option>
                <option
                  v-for="term in paymentTerms"
                  :value="term.id"
                  :key="term"
                >
                  {{ parseInt(term.percentage) }}% {{ term.payment_type }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Attachments:</label>
            <div class="col-sm-5">
              <input
                type="file"
                multiple
                @change="handleFileChange"
                class="form-control"
              />
            </div>
          </div>
        </form>
      </div>

      <div v-else-if="activeSection === 'content2'">
        <form @submit.prevent>
          <!-- Billing Address -->
          <div class="form-group row">
            <div class="col-sm-6">
              <h5>Billing Address</h5>

              <div class="mb-3">
                <label for="billingCountry">Country/Region</label>
                <select
                  v-model="billingAddress.country"
                  class="form-control"
                  id="billingCountry"
                >
                  <option
                    v-for="currency in currencies"
                    :value="currency.country_id"
                    :key="currency.country_id"
                  >
                    {{ currency.name }}
                  </option>
                </select>
                <small
                  class="text-danger"
                  v-if="showAllErrors && !billingAddress.country"
                  >Select the country.</small
                >
              </div>

              <div class="mb-3">
                <label for="billingAddress">Address</label>
                <input
                  v-model="billingAddress.address"
                  type="text"
                  class="form-control"
                  id="billingAddress"
                />
                <small
                  class="text-danger"
                  v-if="showAllErrors && !billingAddress.address"
                  >Address is required.</small
                >
              </div>

              <div class="mb-3">
                <label for="billingCity">City</label>
                <input
                  v-model="billingAddress.city"
                  type="text"
                  class="form-control"
                  id="billingCity"
                />
                <small
                  class="text-danger"
                  v-if="showAllErrors && !billingAddress.city"
                  >City is required.</small
                >
              </div>

              <div class="mb-3">
                <label for="billingState">State</label>
                <select
                  v-model="billingAddress.state"
                  class="form-control"
                  id="billingState"
                >
                  <option
                    v-for="state in filteredBillingStates"
                    :value="state.name"
                    :key="state.id"
                  >
                    {{ state.name }}
                  </option>
                </select>
                <small
                  class="text-danger"
                  v-if="showAllErrors && !billingAddress.state"
                  >Select the state.</small
                >
              </div>

              <div class="mb-3">
                <label for="billingZipcode">Zipcode</label>
                <input
                  v-model="billingAddress.zipcode"
                  type="text"
                  class="form-control"
                  id="billingZipcode"
                />
                <small
                  class="text-danger"
                  v-if="showAllErrors && !billingAddress.zipcode"
                  >Zipcode is required.</small
                >
              </div>

              <div class="mb-3">
                <label for="billingPhoneNumber">Phone Number</label>
                <input
                  v-model="billingAddress.phoneNumber"
                  type="text"
                  class="form-control"
                  id="billingPhoneNumber"
                />
              </div>
            </div>

            <div class="col-sm-6">
              <div>
                <h5 class="d-inline-block">Shipping Address</h5>
                <button
                  type="button"
                  class="btn btn-link btn-sm ml-2"
                  @click="copyBillingToShipping"
                  style="text-decoration: none"
                >
                  Copy Address
                </button>
              </div>

              <div class="mb-3">
                <label for="shippingCountry">Country/Region</label>
                <select
                  v-model="shippingAddress.country"
                  class="form-control"
                  id="shippingCountry"
                >
                  <option
                    v-for="currency in currencies"
                    :value="currency.country_id"
                    :key="currency.country_id"
                  >
                    {{ currency.name }}
                  </option>
                </select>
                <small
                  class="text-danger"
                  v-if="showAllErrors && !shippingAddress.country"
                  >Select the country.</small
                >
              </div>

              <div class="mb-3">
                <label for="shippingAddress">Address</label>
                <input
                  v-model="shippingAddress.address"
                  type="text"
                  class="form-control"
                  id="shippingAddress"
                />
                <small
                  class="text-danger"
                  v-if="showAllErrors && !shippingAddress.address"
                  >Address is required.</small
                >
              </div>

              <div class="mb-3">
                <label for="shippingCity">City</label>
                <input
                  v-model="shippingAddress.city"
                  type="text"
                  class="form-control"
                  id="shippingCity"
                />
                <small
                  class="text-danger"
                  v-if="showAllErrors && !shippingAddress.city"
                  >City is required.</small
                >
              </div>

              <div class="mb-3">
                <label for="shippingState">State</label>
                <select
                  v-model="shippingAddress.state"
                  class="form-control"
                  id="shippingState"
                >
                  <option
                    v-for="state in filteredShippingStates"
                    :value="state.name"
                    :key="state.id"
                  >
                    {{ state.name }}
                  </option>
                </select>
                <small
                  class="text-danger"
                  v-if="showAllErrors && !shippingAddress.state"
                  >State is required.</small
                >
              </div>

              <div class="mb-3">
                <label for="shippingZipcode">Zipcode</label>
                <input
                  v-model="shippingAddress.zipcode"
                  type="text"
                  class="form-control"
                  id="shippingZipcode"
                />
                <small
                  class="text-danger"
                  v-if="showAllErrors && !shippingAddress.zipcode"
                  >Zipcode is required.</small
                >
              </div>

              <div class="mb-3">
                <label for="shippingPhoneNumber">Phone Number</label>
                <input
                  v-model="shippingAddress.phoneNumber"
                  type="text"
                  class="form-control"
                  id="shippingPhoneNumber"
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div v-else-if="activeSection === 'content3'">
        <ContactPersonGrid
          @grid-data-updated="handleGridDataUpdated"
          :contactPersonData="this.customerData?.customer.contact_persons"
          :edit="this.edit"
        />
      </div>
      <div v-else-if="activeSection === 'content4'">
        <div class="form-group row">
          <label for="remarks">Remarks</label>
          <div class="col-sm-10">
            <div class="border rounded p-3">
              <textarea
                v-model="remarks"
                class="form-control border-0"
                id="remarks"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <button
    v-if="!this.isModal"
    class="btn btn-outline-success"
    @click="submitForm"
  >
    Submit
  </button>
  <div v-if="this.isModal" class="modal-footer">
    <button
      class="btn btn-outline-success submit-button-right"
      @click="submitForm"
    >
      Submit
    </button>
  </div>
</template>

<script>
import ContactPersonGrid from "@/components/Account/ContactPersonGrid.vue";
export default {
  props: {
    activeSection: {
      type: String,
      required: true,
    },
    currencies: {
      type: Array,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
    paymentTerms: {
      type: Array,
      required: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },

    edit: Boolean,
    customerData: Object,
  },
  components: {
    ContactPersonGrid,
  },
  data() {
    return {
      primaryContact: {
        firstName: "",
        lastName: "",
      },
      shippingAddress: {
        attention: "",
        country: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        phoneNumber: "",
      },
      billingAddress: {
        attention: "",
        country: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        phoneNumber: "",
      },
      formData: {
        gstTreatment: "",
        gstinUIN: "",
        businessLegalName: "",
        businessTradeName: "",
        pan: "",
        placeOfSupply: "",
        taxPreference: "Taxable",
        currency: "",
        paymentTerms: "",
        file: null,
      },
      remarks: "",
      companyName: "",

      customerEmail: "",
      emailPassword: "",
      confirmEmailPassword: "",
      customerPhoneNumber: "",
      contactPersonData: [
        {
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          mobileNumber: "",
          skype: "",
          designation: "",
          department: "",
        },
      ],
      customerType: "Business",
      selectedPaymentTerm: "",
      advancePercentage: null,
      showAllErrors: false,
      allRequiredFieldsFilled: false,
    };
  },
  watch: {
    currencies: {
      immediate: true,
      handler(newCurrencies) {
        console.log("handler");
        if (newCurrencies && newCurrencies.length > 0) {
          this.formData.currency = newCurrencies[0]?.country_id;
        }
      },
    },
  },
  computed: {
    filteredPlaceOfSupplyOptions() {
      if (this.formData.currency) {
        return this.regions.filter(
          (region) => region.country.country_id === this.formData.currency
        );
      } else {
        return this.regions;
      }
    },
    filteredBillingStates() {
      if (this.billingAddress.country) {
        return this.regions.filter(
          (region) => region.country.country_id === this.billingAddress.country
        );
      } else {
        return this.regions;
      }
    },
    filteredShippingStates() {
      if (this.shippingAddress.country) {
        return this.regions.filter(
          (region) => region.country.country_id === this.shippingAddress.country
        );
      } else {
        return this.regions;
      }
    },
    billingAddressEdit() {
      return this.customerData.addresses.find(
        (address) => address.address_type === "billing"
      );
    },
    shippingAddressEdit() {
      return this.customerData.addresses.find(
        (address) => address.address_type === "shipping"
      );
    },
  },
  mounted() {
    console.log("THisCustomerData", this.customerData);

    if (this.edit && this.customerData) {
      this.prefillFormData();
      console.log("this is CustomerAddress", this.addresses);
    }
    if (this.edit && this.customerData) {
      const matchingPaymentTerm = this.paymentTerms.find(
        (term) => term.id === this.customerData.customer.payment_term.id
      );
      if (matchingPaymentTerm) {
        this.selectedPaymentTerm = matchingPaymentTerm.id;
      }
      console.log("matchingPayment", this.selectedPaymentTerm);
      const matchingCurrency = this.currencies.find(
        (currency) =>
          currency.country_id === this.customerData.customer.currency.country_id
      );
      if (matchingCurrency) {
        this.formData.currency = matchingCurrency.country_id;
        this.billingAddress.country = matchingCurrency.country_id;
        this.shippingAddress.country = matchingCurrency.country_id;
      }
      const matchingPlaceOfSupply = this.filteredPlaceOfSupplyOptions.find(
        (placeOfSupply) =>
          placeOfSupply.region_id ===
          this.customerData.customer.place_of_supply.region_id
      );
      if (matchingPlaceOfSupply) {
        this.formData.placeOfSupply = matchingPlaceOfSupply.region_id;
      }

      const billingState = this.billingAddressEdit.state;
      if (billingState) {
        const matchingRegion = this.regions.find(
          (region) =>
            region.country.country_id === this.shippingAddress.country &&
            region.name === billingState
        );
        if (matchingRegion) {
          this.shippingAddress.state = matchingRegion.name;
        }
      }
      const shippingState = this.shippingAddressEdit.state;
      if (billingState) {
        const matchingRegion = this.regions.find(
          (region) =>
            region.country.country_id === this.billingAddress.country &&
            region.name === shippingState
        );
        if (matchingRegion) {
          this.billingAddress.state = matchingRegion.name;
        }
      }
    }
  },

  methods: {
    handleFileChange(event) {
      this.formData.file = event.target.files;
    },

    handleGridDataUpdated(updatedData) {
      console.log("Grid Data Updated:", updatedData);
      this.contactPersonData = updatedData;
    },
    handleContactPersonsUpdated(newData) {
      this.contactPersons = newData;
    },

    checkAllRequiredFields() {
      if (
        !this.primaryContact.firstName ||
        !this.primaryContact.lastName ||
        !this.companyName ||
        !this.customerEmail ||
        !this.customerPhoneNumber ||
        !this.formData.placeOfSupply ||
        !this.formData.businessLegalName ||
        !this.formData.gstinUIN ||
        !this.formData.gstTreatment ||
        !this.formData.pan ||
        !this.billingAddress.country ||
        !this.billingAddress.address ||
        !this.billingAddress.state ||
        !this.billingAddress.zipcode ||
        !this.shippingAddress.country ||
        !this.shippingAddress.address ||
        !this.shippingAddress.state ||
        !this.shippingAddress.state
      ) {
        return false;
      }

      return true;
    },

    submitForm() {
      this.showAllErrors = true;

      if (!this.checkAllRequiredFields()) {
        this.$notify({
          title: "Please fill out all required fields.",
          type: "bg-danger-subtle text-danger",
          duration: "5000",
        });
        return;
      }
      const requestData = {
        primaryContact: this.primaryContact,
        shippingAddress: this.shippingAddress,
        billingAddress: this.billingAddress,
        remarks: this.remarks,
        formData: this.formData,
        customerType: this.customerType,
        companyName: this.companyName,
        customerEmail: this.customerEmail,
        emailPassword: this.emailPassword,
        confirmEmailPassword: this.confirmEmailPassword,
        customerPhoneNumber: this.customerPhoneNumber,
        contactPerson: this.contactPersonData,
        selectedPaymentTerm: this.selectedPaymentTerm,
        advancePercentage: this.advancePercentage,
      };
      this.showAllErrors = false;
      this.allErrors = [];

      // Check for errors in each section
      // const generalErrors = this.checkGeneralFields();
      // const content1Errors = this.checkContent1Fields();
      // const content2Errors = this.checkContent2Fields();

      // if (generalErrors.length > 0) {
      //   this.allErrors.push(...generalErrors);
      // }
      // if (content1Errors.length > 0) {
      //   this.allErrors.push(...content1Errors);
      // }
      // if (content2Errors.length > 0) {
      //   this.allErrors.push(...content2Errors);
      // }

      if (this.allErrors.length > 0) {
        this.showAllErrors = true;
        return;
      }
      this.$emit("save-form", requestData);
      console.log("Form submitted:", this.$data);
    },
    initializeVariables(userData) {
      userData.addresses.forEach((address) => {
        if (address.address_type === "billing") {
          this.billingAddress = { ...address };
        } else if (address.address_type === "shipping") {
          this.shippingAddress = { ...address };
        }
      });
    },
    copyBillingToShipping() {
      this.shippingAddress.attention = this.billingAddress.attention;
      this.shippingAddress.country = this.billingAddress.country;
      this.shippingAddress.address = this.billingAddress.address;
      this.shippingAddress.city = this.billingAddress.city;
      this.shippingAddress.state = this.billingAddress.state;
      this.shippingAddress.zipcode = this.billingAddress.zipcode;
      this.shippingAddress.phoneNumber = this.billingAddress.phoneNumber;
    },
    prefillFormData() {
      this.primaryContact.firstName =
        this.customerData.customer.contact_persons[0].first_name;
      this.primaryContact.lastName =
        this.customerData.customer.contact_persons[0].last_name;

      this.shippingAddress.address = this.shippingAddressEdit.street_address_1;
      this.shippingAddress.city = this.shippingAddressEdit.city;
      this.shippingAddress.zipcode = this.shippingAddressEdit.postal_code;
      this.shippingAddress.phoneNumber = this.shippingAddressEdit.phone_number;
      this.billingAddress.attention = "";

      this.billingAddress.address = this.billingAddressEdit.street_address_1;
      this.billingAddress.city = this.billingAddressEdit.city;

      this.billingAddress.zipcode = this.billingAddressEdit.postal_code;
      this.billingAddress.phoneNumber = this.billingAddressEdit.phone_number;
      this.formData.gstTreatment = this.customerData.customer.gst_treatment;
      this.formData.businessLegalName =
        this.customerData.customer.business_legal_name;
      this.formData.businessTradeName =
        this.customerData.customer.business_trade_name;
      this.formData.pan = this.customerData.customer.pan;
      this.formData.gstinUIN = this.customerData.customer.gstin_uin;
      this.formData.taxPreference = this.customerData.customer.tax_preference;
      // this.formData.currency = "";
      // this.formData.paymentTerms = "";
      this.formData.file = null;
      this.remarks = this.customerData.customer.remarks;
      this.companyName = this.customerData.customer.user.first_name;

      this.customerEmail = this.customerData.customer.user.email;
      this.customerPhoneNumber = this.customerData.customer.user.phone_number;
      this.contactPersonData = this.contactPersonData;
      this.customerType = this.customerData.customer.customer_type;
      (this.selectedPaymentTerm = this.selectedPaymentTerm),
        (this.advancePercentage = null);
    },
  },
};
</script>
