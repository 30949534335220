<template>
  <div class="container" style="text-align: left">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home</a
          >
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/my-cart">
            <i class="bi bi-cart3"></i>
            My Cart</a
          >
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <b>
            <a class="text-secondary" herf="#">
              <i class="bi bi-cart2"></i> Checkout
            </a>
          </b>
        </li>
      </ol>
    </nav>
    <h1>Checkout</h1>
    <hr />
    <div v-if="$store.state.isLoading" class="conatiner">
      <LoadingBar />
    </div>
    <div v-else class="container">
      <div class="row">
        <div class="col-9">
          <h4 class="d-flex justify-content-between align-items-center">
            Select Shipping Address
            <button
              class="btn btn-outline-success"
              data-bs-toggle="modal"
              data-bs-target="#addAddressModal"
            >
              <i class="bi bi-plus-lg"></i> Add Address
            </button>
          </h4>
          <AddressList
            @addressChanged="handleShippingAddressChanged"
            :addresses="addresses"
          />
          <h4 class="d-flex justify-content-between align-items-center">
            Select Billing Address

            <button
              class="btn btn-outline-success"
              data-bs-toggle="modal"
              data-bs-target="#addAddressModal"
              v-if="isBillingAddressSame === false"
            >
              <i class="bi bi-plus-lg"></i> Add Address
            </button>
          </h4>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="sameAddressCheckbox"
              v-model="isBillingAddressSame"
            />
            <label class="form-check-label" for="sameAddressCheckbox">
              Billing address is the same as shipping
            </label>
          </div>
          <AddressList
            @addressChanged="handleBillingAddressChanged"
            :addresses="addresses"
            v-if="isBillingAddressSame === false"
          />
        </div>
        <div class="col-3">
          <h4 class="d-flex justify-content-between align-items-center">
            <span>Your cart</span>
            <span
              v-if="$store.state.user.userCart"
              class="badge bg-success rounded-pill"
            >
              {{ getCartCount($store.state.user.userCart) }}
            </span>
          </h4>
          <OrderItemsList
            :cart-items="cartItems"
            :savings="savings"
            :totalAmount="totalAmount"
            :subtotalAmount="subtotalAmount"
            :addresses="addresses"
          />
          <div class="btn btn-success w-100" @click="checkout">
            Continue to Payment
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="addAddressModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addAddressModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addAddressModalLabel">
              Add New Address
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <AddAddressForm @newAddressChanged="handleNewAddressChanged" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              @click="saveNewAddress"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderItemsList from "@/components/Orders/OrderItemsList.vue";
import AddressList from "@/components/Account/AddressList.vue";
import AddAddressForm from "@/components/Account/AddAddressForm.vue";
import LoadingBar from "@/components/layout/LoadingBar.vue";

export default {
  data() {
    return {
      cartItems: [],
      appliedCode: "",
      selectedCode: "",
      savings: 0,
      addresses: [],
      selectedShippingAddress: "",
      selectedBillingAddress: "",
      newAddress: {
        street_address_1: "",
        street_address_2: "",
        city: "",
        state: "",
        address_type: "",
        postal_code: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
      },
      isBillingAddressSame: true,
    };
  },
  components: {
    OrderItemsList,
    AddressList,
    AddAddressForm,
    LoadingBar,
  },
  computed: {
    subtotalAmount() {
      let subTotal = 0;
      for (let cartItem of this.cartItems) {
        if (cartItem.customizedPrice) {
          subTotal += cartItem.customizedPrice * cartItem.quantity;
        } else {
          subTotal += cartItem.price * cartItem.quantity;
        }
      }
      return subTotal;
    },
    totalAmount() {
      return this.subtotalAmount - this.savings;
    },
  },
  watch: {
    selectedShippingAddress: {
      handler(newValue) {
        if (this.isBillingAddressSame) {
          this.selectedBillingAddress = newValue;
        }
      },
    },
    isBillingAddressSame: {
      handler(newValue) {
        if (newValue === false) {
          this.selectedBillingAddress = "";
        }
      },
    },
  },
  mounted() {
    this.getCheckoutInfo();
  },

  methods: {
    getCartCount(cart) {
      var cartCount = 0;
      for (let cartItem of cart) {
        cartCount += cartItem.quantity;
      }
      return cartCount;
    },
    async getCheckoutInfo() {
      if (this.$store.state.user.isAuthenticated) {
        this.$store.commit("setIsLoading", true);

        await axiosInstance
          .get("orders/get-checkout-info/")
          .then((response) => {
            this.cartItems = response.data.cart.order_items;
            this.addresses = response.data.user_addresses;
            console.log("add", this.addresses);
            // this.discountCodes = response.data.discount_codes;
            console.log("Cart Data from API:", response.data);
            if (this.$store.state.user?.userAppliedDiscountCode.id) {
              this.appliedCode = this.$store.state.user.userAppliedDiscountCode;
              this.selectedCode =
                this.$store.state.user.userAppliedDiscountCode;
              this.applyCoupon();
            }
          })
          .catch((error) => {
            console.log(error);
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    isCouponActive() {
      return this.selectedCode.is_active == 1;
    },
    hasCouponExpired() {
      const currentDate = new Date();
      const expirationDate = new Date(this.selectedCode.expiration_date);
      return currentDate > expirationDate;
    },
    hasMinimumCartAmount() {
      return (
        this.subtotalAmount >=
        parseFloat(this.selectedCode.min_amount_for_discount)
      );
    },
    isCouponApplicableToCart() {
      const applicableProducts = this.selectedCode.applicable_products;
      const cartProductIds = this.cartItems.map(
        (item) => item.product.product_id
      );
      const maxApplicableProducts =
        this.selectedCode.max_number_of_applicable_products;

      return (
        applicableProducts.some((product) =>
          cartProductIds.includes(product.product_id)
        ) && applicableProducts.length <= maxApplicableProducts
      );
    },
    async maxUsageCheck(discountCode) {
      if (this.$store.state.user.isAuthenticated) {
        await axiosInstance
          .get(
            `orders/check-discount-code-user-usage/${discountCode.discount_code_id}/`
          )
          .then((response) => {
            console.log(response.data);
            let checkPassed = response.data.check_passed;
            return checkPassed;
          })
          .catch((error) => {
            console.error("Error:", error);
            return false;
          });
      } else {
        return true;
      }
    },
    applyDiscount() {
      const discountPercentage = parseFloat(
        this.selectedCode.discount_percentage
      );
      const maxDiscountAmount = parseFloat(
        this.selectedCode.max_discount_amount
      );
      const discount = parseFloat(this.selectedCode.discount_amount);
      var discountAmount = 0;
      if (discountPercentage) {
        console.log("subtotal", this.subtotalAmount);
        discountAmount += (this.subtotalAmount * discountPercentage) / 100;
      }
      if (discount) {
        discountAmount += discount;
      }
      console.log("discount amount", discountAmount);
      return Math.min(discountAmount, maxDiscountAmount);
    },
    applyCoupon() {
      if (!this.isCouponActive()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: "Coupon is not active.",
        });
        return;
      }

      if (this.hasCouponExpired()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: "Coupon has expired.",
        });
        return;
      }

      if (!this.hasMinimumCartAmount()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: `Minimum cart total of ${this.selectedCode.min_amount_for_discount} is required for this coupon.`,
        });
        return;
      }

      if (!this.isCouponApplicableToCart()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: `Coupon is not applicable to more than ${this.selectedCode.max_number_of_applicable_products} products in the cart.`,
        });
        return;
      }

      if (!this.maxUsageCheck(this.selectedCode)) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: "You have already reached the maximum usage for this coupon.",
        });
        return;
      }

      const discountAmount = this.applyDiscount();

      this.savings = discountAmount;
      this.appliedCode = this.selectedCode;
      if (this.$store.state.user.isAuthenticated) {
        localStorage.setItem(
          "userAppliedDiscountCode",
          JSON.stringify(this.appliedCode)
        );
        this.$store.commit("updateUserAppliedDiscountCode", this.appliedCode);
      } else {
        sessionStorage.setItem(
          "appliedDiscountCode",
          JSON.stringify(this.appliedCode)
        );
        this.$store.commit("updateAppliedDiscountCode", this.appliedCode);
      }
    },
    handleShippingAddressChanged(address) {
      this.selectedShippingAddress = address;
    },
    handleBillingAddressChanged(address) {
      this.selectedBillingAddress = address;
    },
    handleNewAddressChanged(address) {
      this.newAddress = address;
    },
    async saveNewAddress() {
      await axiosInstance
        .post(`/accounts/create-user-address/`, this.newAddress)
        .then((response) => {
          console.log("Address created:", response.data);
          this.addresses.push(response.data.user_address);
          this.$notify({
            title: "Address added successfully",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
        })
        .catch((error) => {
          console.error("Error creating address:", error);
        });
    },
    async checkout() {
      if (!this.selectedShippingAddress) {
        this.$notify({
          title: "Shipping Address not selected",
          type: "bg-danger-subtle text-danger",
          text: "Please select the shipping addresss to continue",
          duration: "5000",
        });
        return;
      }
      if (!this.selectedBillingAddress && !this.isBillingAddressSame) {
        this.$notify({
          title: "Billing Address not selected",
          type: "bg-danger-subtle text-danger",
          text: "Please select the billing addresss to continue",
          duration: "5000",
        });
        return;
      }
      this.$store.state.isLoading = true;
      let data = {
        selectedBillingAddress: this.selectedBillingAddress,
        selectedShippingAddress: this.selectedShippingAddress,
        isBillingAddressSame: this.isBillingAddressSame,
        appliedCode: this.appliedCode,
        totalAmount: this.totalAmount,
      };
      await axiosInstance
        .post(`/orders/checkout/`, data)
        .then((response) => {
          console.log("response:", response.data);
          this.$store.state.isLoading = false;

          this.openRazorpay(response.data.razorpay_order);
        })
        .catch((error) => {
          this.$store.state.isLoading = false;

          console.error("Error:", error);
        });
    },
    openRazorpay(orderDetails) {
      const options = {
        key: "rzp_test_in3kWRxQxdCJSB",
        amount: orderDetails.amount,
        currency: "INR",
        name: "Prysm",
        description: "Test Transaction",
        image: "https://prysmelectronics.net/images/Prysm-technology.jpg",
        order_id: orderDetails.id,
        handler: (response) => {
          console.log(response);
          this.processPayment(response);
        },
        prefill: {
          name: this.$store.state.user.last_name
            ? this.$store.state.user.firstName +
              " " +
              this.$store.state.user.last_name
            : this.$store.state.user.firstName,
          email: this.selectedShippingAddress.email
            ? this.selectedShippingAddress.email
            : "",
          contact: this.selectedShippingAddress.phone_number
            ? this.selectedShippingAddress.phone_number
            : "",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#DC3545",
        },
      };
      var rzp = new Razorpay(options);
      rzp.on("payment.failed", (response) => {
        this.processPayment(response);
      });
      rzp.open();
    },
    async processPayment(paymentDetails) {
      this.$store.state.isLoading = true;
      if (this.isBillingAddressSame) {
        this.selectedBillingAddress = this.selectedShippingAddress;
      }
      let data = {
        selectedBillingAddress: this.selectedBillingAddress,
        selectedShippingAddress: this.selectedShippingAddress,
        discountCode: this.appliedCode,
        totalAmount: this.totalAmount,
        discountAmount: this.savings,
        paymentDetails: paymentDetails,
      };
      await axiosInstance
        .post(`/orders/process-payment/`, data)
        .then((response) => {
          console.log("response:", response.data);
          this.$store.state.isLoading = false;
          this.$store.commit("removeUserCart");
          this.$store.commit("removeCart");
          this.$store.commit("updateUserAppliedDiscountCode", "");
          this.$store.commit("updateAppliedDiscountCode", "");
          localStorage.removeItem("userCart");
          localStorage.removeItem("userAppliedDiscountCode");
          localStorage.removeItem("cart");
          localStorage.removeItem("cartTotal");
          localStorage.removeItem("appliedDiscountCode");

          this.$notify({
            title: "Payment made successfully",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
          this.$router.push(
            `/order-confirmation/${response.data.order.order_number}/`
          );
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          console.error("Error:", error);
          if (error.response && error.response.status === 400) {
            this.$notify({
              title: "Payment error",
              type: "bg-danger-subtle text-danger",
              text: "An error occurred with the payment. Please try again.",
              duration: "5000",
            });
          }
        });
    },
  },
};
</script>
<style scoped>
/* button {
        background-color: grey;
    } */
</style>
