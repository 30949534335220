<template>
  <div class="container" style="text-align: left">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/my-cart">
            <i class="bi bi-cart3"></i>
            My Cart
          </a>
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <b>
            <a class="text-secondary" href="#">
              <i class="bi bi-cart2"></i> Customer Checkout
            </a>
          </b>
        </li>
      </ol>
    </nav>

    <div
      class="header-container d-flex align-items-center justify-content-between"
    >
      <h1>Checkout</h1>
      <StepProgressBar
        :currentStep="activeStep"
        :steps="steps"
        class="small-step-progress-bar"
      />
    </div>
    <hr />

    <div v-if="$store.state.isLoading" class="container">
      <LoadingBar />
    </div>

    <div v-else class="container">
      <div class="row">
        <div class="col-9">
          <div v-if="activeStep === 0">
            <CustomerAgGrid
              :rowData="rowData"
              @customer-selected="handleCustomerSelection"
              :selectedCustomer="selectedCustomer"
            />
          </div>

          <div v-else-if="activeStep === 1">
            <CustomerAdress
              :addresses="addresses"
              @address-selected="handleAddressSelection"
              :selectedAddress="selectedAddress"
            />
          </div>

          <div v-else-if="activeStep === 2">
            <PaymentMethods
              :payment="payment_methods"
              @payment-selected="handlePaymentSelection"
              :selectedPayment="selectedPayment"
            />
          </div>
          <div v-else-if="activeStep === 3">
            <CustomerSummary
              :customerDetails="selectedCustomer"
              :addressDetails="selectedAddress"
              :paymentDetails="selectedPayment"
            />
          </div>
        </div>

        <!-- Sidebar -->
        <div class="col-3">
          <h4 class="d-flex justify-content-between align-items-center">
            <span>Your cart</span>
            <span
              v-if="$store.state.user.userCart"
              class="badge bg-success rounded-pill"
            >
              {{ getCartCount($store.state.user.userCart) }}
            </span>
          </h4>
          <OrderItemsList
            :cart-items="cartItems"
            :savings="savings"
            :totalAmount="totalAmount"
            :subtotalAmount="subtotalAmount"
            :addresses="addresses"
          />

          <!-- <OrderItemsList :cart-items="cartItems" :savings="savings" :totalAmount="totalAmount"
            :subtotalAmount="subtotalAmount" :addresses="addresses" /> -->

          <!-- Navigation buttons -->
          <div class="buttons">
            <button
              v-if="activeStep > 0"
              class="btn btn-danger"
              :class="{
                'w-50': activeStep !== steps.length - 1,
                'w-100': activeStep === steps.length - 1,
              }"
              @click="goToPreviousStep"
            >
              Back
            </button>
            <button
              v-if="activeStep < steps.length - 1"
              class="btn btn-success"
              :class="{ 'w-50': activeStep !== 0, 'w-100': activeStep === 0 }"
              @click="handleContinueClick"
            >
              Continue <i class="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBar from "@/components/layout/LoadingBar.vue";
import OrderItemsList from "@/components/Orders/OrderItemsList.vue";
import StepProgressBar from "@/components/Orders/StepProgressBar.vue";
import CustomerAgGrid from "@/components/Orders/CustomerAgGrid.vue";
import CustomerAdress from "@/components/Orders/CustomerAddress.vue";
import CustomerSummary from "@/components/Orders/CustomerSummary.vue";
import PaymentMethods from "@/components/Orders/PaymentMethods.vue";

export default {
  components: {
    OrderItemsList,
    LoadingBar,
    StepProgressBar,
    CustomerAgGrid,
    CustomerAdress,
    CustomerSummary,
    PaymentMethods,
  },

  data() {
    return {
      // steps: ['Select Customer', 'Shipping Information', 'Billing Information', 'Payment Confirmation'],

      activeStep: 0,
      progressBarWidth: "25%",

      rowData: [],
      selectedCustomer: null,

      selectedAddress: null,

      addresses: [],
      payment_methods: [],

      cartItems: [],
      savings: 0,

      steps: [
        { icon: "bi bi-person", class: "first", label: "Select Customer" },
        { icon: "bi bi-geo-alt", class: "second", label: "Select Address" },
        {
          icon: "bi bi-currency-dollar",
          class: "third",
          label: "Payment Method",
        },
        { icon: "bi bi-list", class: "fourth", label: "Review Summary" },
        {
          icon: "bi bi-check2-circle",
          class: "fourth",
          label: "Order Confirm",
        },
      ],
    };
  },

  computed: {
    subtotalAmount() {
      let subTotal = 0;
      for (let cartItem of this.cartItems) {
        if (cartItem.customizedPrice) {
          subTotal += cartItem.customizedPrice * cartItem.quantity;
        } else {
          subTotal += cartItem.price * cartItem.quantity;
        }
      }
      return subTotal;
    },
    totalAmount() {
      return this.subtotalAmount - this.savings;
    },
  },

  mounted() {
    this.getCustomers();
    this.getCheckoutInfo();
  },

  methods: {
    getCartCount(cart) {
      var cartCount = 0;
      for (let cartItem of cart) {
        cartCount += cartItem.quantity;
      }
      return cartCount;
    },
    async getCheckoutInfo() {
      if (this.$store.state.user.isAuthenticated) {
        this.$store.commit("setIsLoading", true);
        // , {
        //     params: {
        //       paymentMethod: this.selectedPayment,
        //     },
        //   }

        await axiosInstance
          .get("orders/get-checkout-info/")
          .then((response) => {
            this.cart = response.data;
            this.cartItems = response.data.cart.order_items;
            this.addresses = response.data.user_addresses;

            console.log("Cart Data from API:", this.cart);
            if (this.$store.state.user.userAppliedDiscountCode) {
              this.appliedCode = this.$store.state.user.userAppliedDiscountCode;
              this.selectedCode =
                this.$store.state.user.userAppliedDiscountCode;
              this.applyCoupon();
            }
          })
          .catch((error) => {
            console.log(error);
          });

        this.$store.commit("setIsLoading", false);
      }
    },

    isCouponActive() {
      return this.selectedCode.is_active;
    },
    hasCouponExpired() {
      const currentDate = new Date();
      const expirationDate = new Date(this.selectedCode.expiration_date);
      return currentDate > expirationDate;
    },
    hasMinimumCartAmount() {
      return (
        this.subtotalAmount >=
        parseFloat(this.selectedCode.min_amount_for_discount)
      );
    },
    isCouponApplicableToCart() {
      const applicableProducts = this.selectedCode.applicable_products;
      const cartProductIds = this.cartItems.map(
        (item) => item.product.product_id
      );
      const maxApplicableProducts =
        this.selectedCode.max_number_of_applicable_products;

      return (
        applicableProducts.some((product) =>
          cartProductIds.includes(product.product_id)
        ) && applicableProducts.length <= maxApplicableProducts
      );
    },
    async maxUsageCheck(discountCode) {
      if (this.$store.state.user.isAuthenticated) {
        await axiosInstance
          .get(
            `orders/check-discount-code-user-usage/${discountCode.discount_code_id}/`
          )
          .then((response) => {
            console.log(response.data);
            let checkPassed = response.data.check_passed;
            return checkPassed;
          })
          .catch((error) => {
            console.error("Error:", error);
            return false;
          });
      } else {
        return true;
      }
    },
    applyDiscount() {
      const discountPercentage = parseFloat(
        this.selectedCode.discount_percentage
      );
      const maxDiscountAmount = parseFloat(
        this.selectedCode.max_discount_amount
      );
      const discount = parseFloat(this.selectedCode.discount_amount);
      var discountAmount = 0;
      if (discountPercentage) {
        console.log("subtotal", this.subtotalAmount);
        discountAmount += (this.subtotalAmount * discountPercentage) / 100;
      }
      if (discount) {
        discountAmount += discount;
      }
      console.log("discount amount", discountAmount);
      return Math.min(discountAmount, maxDiscountAmount);
    },

    applyCoupon() {
      if (!this.isCouponActive()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: "Coupon is not active.",
        });
        return;
      }

      if (this.hasCouponExpired()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: "Coupon has expired.",
        });
        return;
      }

      if (!this.hasMinimumCartAmount()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: `Minimum cart total of ${this.selectedCode.min_amount_for_discount} is required for this coupon.`,
        });
        return;
      }

      if (!this.isCouponApplicableToCart()) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: `Coupon is not applicable to more than ${this.selectedCode.max_number_of_applicable_products} products in the cart.`,
        });
        return;
      }

      if (!this.maxUsageCheck(this.selectedCode)) {
        this.$notify({
          title: "Coupon Error",
          type: "bg-danger-subtle text-danger",
          text: "You have already reached the maximum usage for this coupon.",
        });
        return;
      }

      const discountAmount = this.applyDiscount();

      this.savings = discountAmount;
      this.appliedCode = this.selectedCode;
      if (this.$store.state.user.isAuthenticated) {
        localStorage.setItem(
          "userAppliedDiscountCode",
          JSON.stringify(this.appliedCode)
        );
        this.$store.commit("updateUserAppliedDiscountCode", this.appliedCode);
      } else {
        sessionStorage.setItem(
          "appliedDiscountCode",
          JSON.stringify(this.appliedCode)
        );
        this.$store.commit("updateAppliedDiscountCode", this.appliedCode);
      }
    },

    async handleContinueClick() {
      if (!this.selectedCustomer) {
        this.$notify({
          type: "bg-danger-subtle text-danger",
          title: "Error",
          text: "Please select a customer before continuing.",
        });
        return;
      }

      // If the active step is for selecting an address and no address is selected, show error message
      if (this.activeStep === 1 && !this.selectedAddress) {
        this.$notify({
          type: "bg-danger-subtle text-danger",
          title: "Error",
          text: "Please select an address before continuing.",
        });
        return;
      }

      if (this.activeStep === 2 && !this.selectedPayment) {
        this.$notify({
          type: "bg-danger-subtle text-danger",
          title: "Error",
          text: "Please select a Payment method before continuing.",
        });
        return;
      }

      if (this.activeStep === 3) {
        // If the selected payment method is 1, call the API to fetch and update the order status
        if (
          (this.selectedPayment &&
            this.selectedPayment.id === 1 &&
            this.activeStep === 3) ||
          (this.selectedPayment &&
            this.selectedPayment.id === 6 &&
            this.activeStep === 3)
        ) {
          const payload = {
            customer: this.selectedCustomer,
            address: this.selectedAddress,
            paymentMethod: this.selectedPayment,
            order_id: this.cart.cart.order_id,
            totalAmount: this.totalAmount,
            discountAmount: this.savings,
            discountCode: this.appliedCode,
            paymentMethod: this.selectedPayment.id,
          };

          // Call the API to fetch and update the order status
          try {
            const response = await axiosInstance.post(
              "orders/fetch_and_update_order_status/",
              payload
            );
            console.log(response.data);

            // Remove userCart data from local storage
            localStorage.removeItem("userCart");

            // If you are using Vuex, also commit a mutation to clear userCart from Vuex state
            this.$store.commit("clearUserCart"); // Log the response from the backend
            // Redirect to the order processing page if needed
            this.$router.push({
              name: "OrderProcessing",
              params: {
                orderId: this.cart.cart.order_id,
              },
            });
          } catch (error) {
            console.error(error); // Log any errors
            this.$notify({
              type: "bg-danger-subtle text-danger",
              title: "Error",
              text: "Failed to fetch or update order status.",
            });
          }
          return;
        }

        if (this.selectedPayment && this.selectedPayment.id === 2) {
          const payload = {
            customer: this.selectedCustomer,
            address: this.selectedAddress,
            paymentMethod: this.selectedPayment.id,
            order_id: this.cart.cart.order_id,
            totalAmount: this.totalAmount,
            discountAmount: this.savings,
            discountCode: this.appliedCode,
          };

          // Call the API to fetch and update the order status
          try {
            const response = await axiosInstance.post(
              "orders/fetch_and_update_order_status/",
              payload
            );
            console.log(response.data);

            // Remove userCart data from local storage
            localStorage.removeItem("userCart");

            // If you are using Vuex, also commit a mutation to clear userCart from Vuex state
            this.$store.commit("clearUserCart"); // Log the response from the backend
            // Redirect to the order processing page if needed
            this.$router.push({
              name: "OrderProcessing",
              params: {
                orderId: this.cart.cart.order_id,
              },
            });
          } catch (error) {
            console.error(error); // Log any errors
            this.$notify({
              type: "bg-danger-subtle text-danger",
              title: "Error",
              text: "Failed to fetch or update order status.",
            });
          }
          return;
        }
      }

      if (this.selectedPayment && this.selectedPayment.id === 3) {
        const modal = new bootstrap.Modal(
          document.getElementById("paymentModal")
        );
        modal.show();
      }
      // else if (this.activeStep === 3) {
      //   await this.goToPayment();
      // }
      else {
        this.goToNextStep();
      }
    },

    // async goToPayment() {
    //   try {

    //     const response = await axiosInstance.post('/your-api-endpoint', {
    //       customer: this.selectedCustomer,
    //       address: this.selectedAddress,
    //       payment: this.selectedPayment,
    //     });
    //     console.log('API response:', response.data);
    //     // Handle the API response as needed
    //   } catch (error) {
    //     console.error('Error calling API:', error);
    //     // Handle API call error
    //   }
    // },

    handleAddressSelection(selectedAddress) {
      this.selectedAddress = selectedAddress;
      this.goToNextStep();
    },

    handlePaymentSelection(selectedPayment) {
      this.selectedPayment = selectedPayment;
      this.goToNextStep();
    },

    goToNextStep() {
      if (this.activeStep < this.steps.length - 1) {
        this.activeStep++;
        this.progressBarWidth = `${
          (this.activeStep + 1) * (100 / this.steps.length)
        }%`;
      }
    },

    goToPreviousStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
        this.progressBarWidth = `${
          (this.activeStep + 1) * (100 / this.steps.length)
        }%`;
      }
    },

    async getCustomers() {
      this.$store.commit("setIsLoading", true);

      try {
        const response = await axiosInstance.get(
          `/accounts/get_all_customers/`
        );

        this.rowData = response.data.customers;
        // this.addresses = response.data.address_serializer
        this.payment_methods = response.data.payment_terms;

        console.log("Fetched customers:", this.rowData);
        // console.log("Fetched Address:", this.addresses);
        console.log("fetched payment methods", this.payment_methods);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }

      this.$store.commit("setIsLoading", false);
    },

    async handleCustomerSelection(selectedCustomer) {
      try {
        const response = await axiosInstance.get(
          `/accounts/get_customer_addresses/${selectedCustomer.id}`
        );
        this.addresses = response.data.addresses;
        this.selectedCustomer = selectedCustomer;
        console.log("Selected Customer:", this.selectedCustomer);
      } catch (error) {
        console.error("Error fetching customer addresses:", error);
      }
    },

    handleAddressSelection(selectedAddress) {
      this.selectedAddress = selectedAddress;
      console.log("Selected Address:", selectedAddress);
      // Handle selected address
    },

    handlePaymentSelection(selectedPayment) {
      this.selectedPayment = selectedPayment;
      console.log("Selected payment:", selectedPayment);
    },

    async goToPayment() {
      this.$store.state.isLoading = true;

      let data = {
        customer: this.selectedCustomer,
        address: this.selectedAddress,
        payment: this.selectedPayment,
        appliedCode: this.appliedCode,
        totalAmount: this.totalAmount,
      };
      await axiosInstance
        .post(`/orders/customer_checkout/`, data)
        .then((response) => {
          console.log("response:", response.data);
          this.$store.state.isLoading = false;

          this.openRazorpay(response.data.razorpay_order);
        })
        .catch((error) => {
          this.$store.state.isLoading = false;

          console.error("Error:", error);
        });
    },
    openRazorpay(orderDetails) {
      const options = {
        key: "rzp_test_in3kWRxQxdCJSB",
        amount: orderDetails.amount,
        currency: "INR",
        name: "Prysm",
        description: "Test Transaction",
        image: "https://prysmelectronics.net/images/Prysm-technology.jpg",
        order_id: orderDetails.id,
        handler: (response) => {
          console.log(response);
          this.processPayment(response);
        },
        // prefill: {
        //   name: this.$store.state.user.last_name
        //     ? this.$store.state.user.firstName +
        //       " " +
        //       this.$store.state.user.last_name
        //     : this.$store.state.user.firstName,
        //   email: this.selectedShippingAddress.email
        //     ? this.selectedShippingAddress.email
        //     : "",
        //   contact: this.selectedShippingAddress.phone_number
        //     ? this.selectedShippingAddress.phone_number
        //     : "",
        // },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#DC3545",
        },
      };
      var rzp = new Razorpay(options);
      rzp.on("payment.failed", (response) => {
        this.processPayment(response);
      });
      rzp.open();
    },

    async processPayment(paymentDetails) {
      this.$store.state.isLoading = true;
      if (this.isBillingAddressSame) {
        this.selectedBillingAddress = this.selectedShippingAddress;
      }
      let data = {
        customer: this.selectedCustomer,
        address: this.selectedAddress,
        payment: this.selectedPayment,
        appliedCode: this.appliedCode,
        totalAmount: this.totalAmount,
        discountAmount: this.savings,
        paymentDetails: paymentDetails,
      };
      await axiosInstance
        .post(`/orders/customer_process_payment/`, data)
        .then((response) => {
          console.log("response:", response.data);
          this.$store.state.isLoading = false;
          this.$store.commit("removeUserCart");
          this.$store.commit("removeCart");
          this.$store.commit("updateUserAppliedDiscountCode", "");
          this.$store.commit("updateAppliedDiscountCode", "");
          localStorage.removeItem("userCart");
          localStorage.removeItem("userAppliedDiscountCode");
          localStorage.removeItem("cart");
          localStorage.removeItem("cartTotal");
          localStorage.removeItem("appliedDiscountCode");

          this.$notify({
            title: "Payment made successfully",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
          this.$router.push(
            `/order-confirmation/${response.data.order.order_number}/`
          );
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          console.error("Error:", error);
          if (error.response && error.response.status === 400) {
            this.$notify({
              title: "Payment error",
              type: "bg-danger-subtle text-danger",
              text: "An error occurred with the payment. Please try again.",
              duration: "5000",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.small-step-progress-bar {
  transform: scale(0.6);
}
</style>
