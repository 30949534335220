<template>
  <form id="productReviewForm" enctype="multipart/form-data">
    <div class="form-group row">
      <label for="comment" class="col-sm-2 col-form-label">Review:</label>
      <div class="col-sm-10">
        <textarea
          class="form-control"
          v-model="review.comment"
          placeholder="Write a review...."
          required
        ></textarea>
      </div>
    </div>
    <div class="form-group row">
      <label for="comment" class="col-sm-2 col-form-label">Rating:</label>
      <div v-if="defaultRatingSystem.name == 'Stars'" class="col-sm-10">
        <star-rating
          v-model:rating="review.rating.value"
          :star-size="30"
          :show-rating="false"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="image" class="col-sm-2 col-form-label">Images:</label>
      <div class="col-sm-10">
        <div class="input-group mb-3">
          <input
            class="form-control"
            type="file"
            @change="handleFileChange"
            accept="image/*"
            multiple
          />
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="clearSelectedImages"
          >
            Clear
          </button>
        </div>

        <div
          v-if="review && review.selectedImages.length > 0"
          class="mt-3 d-flex justify-content-left"
        >
          <div
            v-for="(file, index) in review.selectedImages"
            :key="index"
            class="mx-2"
          >
            <img
              :src="file.url"
              alt="Selected Image"
              width="100"
              height="100"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  props: {
    orderItem: {
      type: Object,
      required: true,
    },
    defaultRatingSystem: {
      type: Object,
      required: true,
    },
    productReviews: {},
    modelValue: Object,
  },
  data() {
    return {
      ratingValues: [],
      review: {
        comment: this.modelValue.comment || "",
        rating: this.modelValue.rating || { value: 0 }, // Assuming rating value is an object with a 'value' property
        selectedImages: [], // You may need to populate this if you have existing images
      },
    };
  },
  mounted() {},
  watch: {
    review: {
      handler(newVal) {
        this.$emit("update:modelValue", newVal);
      },
      deep: true,
    },
  },
  methods: {
    setRating(value) {
      this.review.rating.value = value;
    },

    getReviewData() {},

    handleFileChange(event) {
      const files = event.target.files;
      this.review.selectedImages = Array.from(files).map((file) => ({
        url: URL.createObjectURL(file),
        file: file,
      }));
    },
    clearSelectedImages() {
      this.review.selectedImages = [];
    },
  },
  components: {
    StarRating,
  },
};
</script>

<style scoped>
.rating {
  font-size: 2em;
  display: flex;
  justify-content: flex-end;
}

.rating span {
  display: inline-block;
  padding: 0.1em;
  cursor: pointer;
  color: #000;
}

.rating span:hover ~ span {
  color: black;
}

.rating span.selected {
  color: #ffd700;
}
.selected-images {
  display: flex;
  flex-direction: row;
}
</style>
