<template>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Option</th>
        <th scope="col">Selection</th>
        <th scope="col">Quantity</th>
      </tr>
    </thead>
    <tbody
      v-for="(
        selectedCustom, index
      ) in orderItem.order_item_customization_options"
      :key="index"
    >
      <tr>
        <td>
          <span
            v-if="
              selectedCustom.customization_option &&
              selectedCustom.customization_option.customization_category &&
              selectedCustom.customization_option.customization_category
                .parent_customization_category
            "
          >
            <template
              v-if="
                selectedCustom.customization_option.customization_category
                  .parent_customization_category.name !== 'Components'
              "
            >
              {{
                selectedCustom.customization_option.customization_category
                  .parent_customization_category.name
              }}
            </template>
            <template v-else>
              {{
                selectedCustom.customization_option.customization_category.name
              }}
            </template>
          </span>
        </td>
        <td>
          <span
            v-if="
              selectedCustom.customization_option &&
              selectedCustom.customization_option.customization_category &&
              selectedCustom.customization_option.customization_category
                .parent_customization_category
            "
          >
            <template
              v-if="
                selectedCustom.customization_option.customization_category
                  .parent_customization_category.name !== 'Components'
              "
            >
              <strong
                v-if="
                  selectedCustom.customization_option &&
                  selectedCustom.customization_option.customization_category
                "
                >{{
                  selectedCustom.customization_option.customization_category
                    .name
                }}</strong
              >
              {{ selectedCustom.customization_option.name }}
            </template>
            <template v-else>
              {{ selectedCustom.customization_option.name }}
            </template>
          </span>
          <span v-else>
            <div v-if="selectedCustom.customization_option">
              {{ selectedCustom.customization_option.name }}
            </div>
          </span>
        </td>
        <td>{{ selectedCustom.quantity }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    orderItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
