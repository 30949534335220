<template>
  <div v-if="$store.state.isLoading">
    <loading-bar />
  </div>
  <div v-else class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary" href="#">
            <i class="bi bi-grid"></i>
            Products
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary">
            <i class="bi bi-box"></i>
            {{ parentProductCategory.name }}
          </a>
        </li>
        <li class="breadcrumb-item">
          <b>
            <a class="text-secondary" href="#">
              <i class="bi bi-box"></i>
              {{ productCategory.name }}
            </a>
          </b>
        </li>
      </ol>
    </nav>
    <h1>{{ productCategory.name }}</h1>
    <hr />
    <div class="row">
      <div class="col-12 col-md-2 d-md-block d-none">
        <!-- Desktop Filter: Visible on medium and larger screens -->
        <ProductFilter
          :tags="tagsData"
          @filterChanged="handleFilterChanged"
        ></ProductFilter>
      </div>
      <div class="col-12 col-md-10">
        <!-- Mobile Filter: Offcanvas component -->
        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="mobileFilter"
          aria-labelledby="mobileFilterLabel"
        >
          <div class="offcanvas-header">
            <h5 id="mobileFilterLabel">Filters</h5>
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <ProductFilter
              :tags="tagsData"
              @filterChanged="handleFilterChanged"
            ></ProductFilter>
          </div>
        </div>
        <!-- Product List -->
        <ProductList :products="filteredProducts"></ProductList>
      </div>
    </div>
  </div>
</template>

<script>
import ProductFilter from "@/components/Products/ProductFilter.vue";
import ProductList from "@/components/Products/ProductList.vue";
import LoadingBar from "@/components/layout/LoadingBar.vue";

export default {
  components: {
    ProductFilter,
    ProductList,
    LoadingBar,
  },
  data() {
    return {
      selectedFilters: [],
      showList: false,
      tagsData: [],
      productsData: [],
      productCategory: {},
      parentProductCategory: {},
    };
  },
  computed: {
    filteredProducts() {
      // Get an array of selected tag IDs
      const selectedTagIds = this.tagsData
        .filter((tag) => tag.checked)
        .map((tag) => tag.product_tag_id);
      console.log("selectedTags", selectedTagIds);

      if (selectedTagIds.length === 0) {
        return this.productsData;
      }
      return this.productsData.filter((product) => {
        if (product.tags.length != 0) {
          console.log("tags array", product.tags);
          return selectedTagIds.some((tagId) =>
            product.tags.some(
              (productTag) => productTag.product_tag_id == tagId
            )
          );
        }
        return false; // Or handle the case where product.tags is not an array
      });
    },
  },
  methods: {
    async getProducts() {
      this.$store.commit("setIsLoading", true);
      const category_id = this.$route.params.category_id;
      await axiosInstance
        .get(`/products/get_product_page/${category_id}/`)
        .then((response) => {
          this.tagsData = response.data.tags;
          this.productsData = response.data.products;
          this.productCategory = response.data.product_category;
          this.parentProductCategory =
            response.data.product_category.parent_product_category;
          console.log(response.data);
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          this.$store.commit("setIsLoading", false);
          console.error("Error", error);
          this.$notify({
            title: "Something went wrong. Please try again",
            type: "bg-danger-subtle text-danger",
            duration: "5000",
          });
        });
    },
    handleFilterChanged(tagItem) {
      console.log(tagItem);
    },
  },
  mounted() {
    this.getProducts();
  },
};
</script>

<style scoped>
.sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  height: 100vh;
  overflow-y: auto; /* Enable scrolling if the sidebar is too tall */
}
</style>
