<template>
  <div class="">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <span> Saved Addresses </span>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item"
            v-for="(address, index) in addresses"
            :key="index"
            @click="selectedAddress = address"
          >
            <input type="radio" v-model="selectedAddress" :value="address" />
            {{ address.first_name }} {{ address.last_name }},
            {{ address.street_address_1 }}, {{ address.street_address_2 }},
            {{ address.city }}, {{ address.state }},
            {{ address.postal_code }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
//

export default {
  props: {
    addresses: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedAddress: "",
    };
  },
  watch: {
    selectedAddress(newAddress) {
      this.$emit("addressChanged", newAddress);
    },
  },
  mounted() {},
  methods: {},
};
</script>
