<template>
  <div v-if="$store.state.isLoading">
    <LoadingBar />
  </div>
  <div v-else class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary">
            <i class="bi bi-grid"></i>
            Products
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary">
            <i class="bi bi-box"></i>
            {{
              product.product_category &&
              product.product_category.parent_product_category
                ? product.product_category.parent_product_category.name
                : ""
            }}
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary" href="#">
            <i class="bi bi-box"></i>
            {{ product.product_category ? product.product_category.name : "" }}
          </a>
        </li>
        <li class="breadcrumb-item">
          <b>
            <a class="text-secondary" href="#">
              <i class="bi bi-box"></i>
              {{ product.name }}
            </a>
          </b>
        </li>
      </ol>
    </nav>
    <div class="row">
      <h2 class="h1">{{ product.name }}</h2>
      <hr />
      <div class="col-md-6">
        <p>
          {{ product.product_description }}
        </p>
        <h5>Starting at ₹ {{ $parsePriceToString(product.price_with_tax) }}</h5>
        <button
          v-if="isEditing == false"
          @click="addToCart"
          class="btn btn-outline-success"
        >
          Add to Cart
        </button>
        <button
          v-else
          class="btn btn-outline-success"
          data-bs-toggle="modal"
          data-bs-target="#saveCustomizationsModal"
        >
          Add to Cart
        </button>
      </div>

      <div class="col-md-6">
        <div id="carouselIndicators" class="carousel slide">
          <div class="carousel-indicators">
            <button
              v-for="(image, index) in images"
              :key="index"
              type="button"
              data-bs-target="#carouselIndicators"
              :data-bs-slide-to="index"
              :class="{ active: index === 0 }"
              :aria-current="index === 0 ? 'true' : null"
              :aria-label="`Slide ${index + 1}`"
            ></button>
          </div>
          <div class="carousel-inner">
            <div
              v-for="(image, index) in images"
              :key="index"
              class="carousel-item"
              :class="{ active: index === 0 }"
            >
              <!-- <img
                :src="image"
                class="d-block w-100"
                :style="{ height: dynamicHeight(image) + 'px' }"
                alt=""
              /> -->
              <VueImageZoomer
                :regular="image"
                :zoom="image"
                :zoom-amount="3"
                class="d-block w-100"
                :style="{ height: dynamicHeight(image) + 'px' }"
                img-class="img-fluid"
                alt="image"
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <ul class="nav nav-pills me-3" id="v-pills-tab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="v-pills-laptop-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-laptop"
            type="button"
            role="tab"
            aria-controls="v-pills-laptop"
            aria-selected="true"
            aria-current="page"
            href="#"
          >
            <!-- <i class="bi bi-laptop nav-icon"></i><br> -->
            <span class="nav-text">Tech Specs & Customization</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="v-pills-desktop-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-desktop"
            type="button"
            role="tab"
            aria-controls="v-pills-desktop"
            aria-selected="false"
            href="#"
          >
            <!-- <i class="bi bi-pc-display-horizontal nav-icon"></i><br> -->
            <span class="nav-text">Features & Design</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="v-pills-server-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-server"
            type="button"
            role="tab"
            aria-controls="v-pills-server"
            aria-selected="false"
            href="#"
          >
            <!-- <i class="bi bi-server nav-icon"></i><br> -->
            <span class="nav-text">Ratings & Reviews</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="v-pills-workstation-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-workstation"
            type="button"
            role="tab"
            aria-controls="v-pills-workstation"
            aria-selected="false"
            href="#"
          >
            <!-- <i class="bi bi-pc nav-icon"></i><br> -->
            <span class="nav-text">Drivers, Manuals & Support</span>
          </a>
        </li>
      </ul>
      <div class="tab-content mt-4" id="v-pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="v-pills-laptop"
          role="tabpanel"
          aria-labelledby="v-pills-laptop-tab"
        >
          <div class="row">
            <div class="col-9">
              <ProductCustomizationSelection
                :customizations="this.customizations"
                :isEditing="isEditing"
                @selectedOptions="handleSelectedOptions"
                @increasePrice="handleIncreasePrice"
                @decreasePrice="handleDecreasePrice"
              />
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-text">
                    <strong>
                      Total Price : ₹
                      {{ $parsePriceToString(customizedPrice) }}
                    </strong>
                  </h5>
                  <p class="card-text text-muted small-text">
                    Price inclusive of GST. Free Delivery.
                    <span class="card-text text-muted small-text">
                      *Customizations may result in additional updates to the
                      overall product price.
                    </span>
                  </p>
                  <hr />
                  <h6 class="card-text">
                    <strong> Financing </strong>
                  </h6>
                  <p class="card-text text-muted small-text">
                    EMI starts from ₹
                    {{ $parsePriceToString((customizedPrice / 12).toFixed(2)) }}
                    /month
                  </p>
                  <hr />
                  <a
                    class="card-text"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deliveryFormModal"
                    >Estimated delivery date</a
                  >
                  <hr />
                  <div v-if="isEditing" class="row mb-2">
                    <button
                      class="btn btn-outline-dark"
                      @click="resetCustomizations"
                    >
                      Reset Customizations
                    </button>
                  </div>
                  <div class="row mb-2">
                    <button
                      v-if="isEditing"
                      class="btn btn-outline-secondary"
                      @click="saveCustomization"
                    >
                      Save Customization
                    </button>
                    <button
                      v-else
                      class="btn btn-outline-secondary"
                      @click="startCustomization"
                    >
                      Customize Product
                    </button>
                  </div>
                  <div class="row">
                    <button
                      href="#"
                      class="btn btn-outline-warning mb-2"
                      data-bs-toggle="modal"
                      data-bs-target="#productCustomizationDetail"
                    >
                      Review Summary
                    </button>
                  </div>
                  <div class="row">
                    <button
                      v-if="isEditing == false"
                      :disabled="!isInStock"
                      @click="addToCart"
                      class="btn btn-outline-success"
                    >
                      Add to Cart
                    </button>

                    <button
                      v-else
                      class="btn btn-outline-success"
                      data-bs-toggle="modal"
                      data-bs-target="#saveCustomizationsModal"
                    >
                      Add to Cart
                    </button>
                    <!-- <div v-if="!isInStock" class="text-danger mt-2 ms-2">
                      Oops! This product is currently out of stock.
                    </div> -->
                    <button
                      v-if="!isInStock"
                      class="btn btn-outline-danger mt-2"
                      disabled
                    >
                      Oops! This product is currently out of stock.
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="v-pills-desktop"
          role="tabpanel"
          aria-labelledby="v-pills-desktop-tab"
        >
          <div
            v-if="product.product_feature"
            v-html="product.product_feature"
          ></div>
        </div>

        <div
          class="tab-pane fade"
          id="v-pills-server"
          role="tabpanel"
          aria-labelledby="v-pills-server-tab"
        >
          <ReviewList />
        </div>

        <div
          class="tab-pane fade"
          id="v-pills-workstation"
          role="tabpanel"
          aria-labelledby="v-pills-workstation-tab"
        >
          <div class="container">
            <h5>Prysm Support</h5>
            <p>
              From drivers, user guides and manuals to diagnostic tools and
              replacement parts, Prysm Product Support has you covered!
            </p>
            <p>Use the link below to visit our support page</p>
            <button class="btn btn-success">
              <a href="https://main.d3ncvylwbruldr.amplifyapp.com/Home">
                Go to Support Page
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="deliveryFormModal"
      tabindex="-1"
      aria-labelledby="deliveryFormModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deliveryFromModalLabel">
              Enter Your PIN Code
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="pincode">PIN Code</label>
              <input
                type="text"
                class="form-control"
                id="pincode"
                placeholder="Enter PIN Code"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-outline-success"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#deliveryDateModal"
              @click="getExpectedDeliveryDate"
            >
              Expected Date
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="deliveryDateModal"
      tabindex="-1"
      aria-labelledby="deliveryDateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deliveryDateModalLabel">
              Expected Delivery Date
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Your Expected Delivery Date is around
            <strong>{{ expectedDeliveryDate }} </strong>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="saveCustomizationsModal"
      tabindex="-1"
      aria-labelledby="saveCustomizationsModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="saveCustomizationsModalLabel">
              Save Customization Changes
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              Do you want to save the customizations made before adding the item
              to your cart?
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-dark">
              Reset Customizations
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Keep Customizing
            </button>
            <button
              @click="saveChangesAndAddToCart"
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
            >
              Save Changes & Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>

    <ReviewSummaryModal
      @add-to-cart="saveChangesAndAddToCart"
      :selectedCustomizations="selectedCustomizations"
      :product="product"
      :customizedPrice="parseFloat(customizedPrice)"
    />
  </div>
</template>

<script>
// import ProductCustomization from '@/components/Products/ProductCustomization.vue'
import ProductCustomizationSelection from "@/components/Products/ProductCustomizationSelection.vue";
import ReviewSummaryModal from "@/components/Products/ReviewSummaryModal.vue";
import ReviewList from "@/components/Products/ReviewList.vue";

import LoadingBar from "@/components/layout/LoadingBar.vue";
import { VueImageZoomer } from "vue-image-zoomer";
import "vue-image-zoomer/dist/style.css";

export default {
  data() {
    return {
      images: [],
      product: {},
      customizations: [],
      currentId: "",
      mainImageSrc: "",
      showAllImages: true,
      selectedCustomizations: [],
      expectedDeliveryDate: "",
      pincode: "",
      isEditing: false,
      defaultCustomizations: [],
      customizedPrice: 0,
      product_features: "",
    };
  },

  components: {
    ReviewSummaryModal,
    ProductCustomizationSelection,
    ReviewList,
    LoadingBar,
    VueImageZoomer,
  },
  computed: {
    currentImage() {
      return (
        this.images.find((img) => img.id === this.currentId)
          ?.product_document_path || ""
      );
    },
    isInStock() {
      return this.product?.stock > 0;
    },
  },

  mounted() {
    this.getProduct();
  },

  methods: {
    dynamicHeight(image) {
      // Calculate height based on image aspect ratio
      if (image.width && image.height) {
        return (300 * image.height) / image.width;
      }
      return 300; // Default height if dimensions are not available
    },
    nextImage() {
      const currentIndex = this.images.findIndex(
        (img) => img.id === this.currentId
      );
      const nextIndex = (currentIndex + 1) % this.images.length;
      this.currentId = this.images[nextIndex].id;
    },
    prevImage() {
      const currentIndex = this.images.findIndex(
        (img) => img.id === this.currentId
      );
      const prevIndex =
        (currentIndex - 1 + this.images.length) % this.images.length;
      this.currentId = this.images[prevIndex].id;
    },
    async getProduct() {
      this.$store.commit("setIsLoading", true);

      const productID = this.$route.params.product_id;

      await axiosInstance
        .get(`/products/get_product_data/${productID}/`)
        .then((response) => {
          console.log("Parent Categories", response.data);
          this.product = response.data.product;
          this.customizedPrice = response.data.product.price_with_tax;
          this.customizations = response.data.parent_categories;
          this.images.push(this.product.product_image_path);
          response.data.product.product_images.forEach((image) => {
            if (image.product_document_path) {
              this.images.push(image.product_document_path);
            }
          });

          if (this.images && this.images.length > 0) {
            this.currentId = this.images[0].id;
          }
          console.log("Product Data", this.product);
          console.log("customizations Data", this.customizations);
          this.defaultCustomizations =
            this.product.product_default_customizations;
          const customizationQueryParam = this.$route.query.customizationId;
          if (customizationQueryParam) {
            if (
              sessionStorage.getItem(
                `customization_${this.$route.params.product_id}_${customizationQueryParam}`
              )
            ) {
              this.selectedCustomizations = JSON.parse(
                sessionStorage.getItem(
                  `customization_${this.$route.params.product_id}_${customizationQueryParam}`
                )
              );
              this.customizedPrice = sessionStorage.getItem(
                `customization_${this.$route.params.product_id}_${customizationQueryParam}_price`
              );
              console.log("session data", this.selectedCustomizations);
            } else {
              this.getCustomizedProduct();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async getCustomizedProduct() {
      const customizationId = this.$route.query.customizationId;
      this.$store.commit("setIsLoading", true);
      await axiosInstance
        .get(`/products/get-customized-product-data/${customizationId}/`)
        .then((response) => {
          console.log(response.data);
          let backendCustomizations = [];
          for (let customization of response.data.customized_order_item
            .order_item_customization_options) {
            backendCustomizations.push({
              option: customization.default_customization,
              selected_quantity: customization.quantity,
            });
          }
          sessionStorage.setItem(
            `customization_${this.$route.params.product_id}_${customizationId}`,
            JSON.stringify(backendCustomizations)
          );
          sessionStorage.setItem(
            `customization_${this.$route.params.product_id}_${customizationId}_price`,
            response.data.customized_order_item.price
          );
          this.$router.go(0);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    handleSelectedOptions(options) {
      this.selectedCustomizations = options;
    },
    async addToCart() {
      let errors = this.verifyConfiguration(this.selectedCustomizations);
      if (errors.length !== 0) {
        for (let error of errors) {
          this.$notify({
            title: error,
            type: "bg-danger-subtle text-danger",
            duration: 10000,
          });
        }
      } else {
        const customizationQueryParam = this.$route.query.customizationId;
        let customizations = [];

        if (customizationQueryParam) {
          customizations = JSON.parse(
            sessionStorage.getItem(
              `customization_${this.$route.params.product_id}_${customizationQueryParam}`
            )
          );
        } else {
          customizations = this.selectedCustomizations;
        }

        if (this.$store.state.user.isAuthenticated) {
          this.$store.commit("setIsLoading", true);
          const productID = this.$route.params.product_id;
          let data = {
            customizations: this.selectedCustomizations,
            customized_price: this.customizedPrice,
            customization_id: customizationQueryParam,
          };
          await axiosInstance
            .post(`/orders/add-to-cart/${productID}/`, data)
            .then((response) => {
              this.$notify({
                title: "Item added to cart successfully",
                type: "bg-success-subtle text-success",
                duration: "5000",
              });
              localStorage.setItem(
                "userCart",
                JSON.stringify(response.data.updated_cart.order_items)
              );
              this.$store.commit(
                "updateUserCart",
                response.data.updated_cart.order_items
              );
            })
            .catch((error) => {
              console.log(error);
            });
          this.$store.commit("setIsLoading", false);
        }

        const cartItem = {
          product: this.product,
          quantity: 1,
          customization_id: customizationQueryParam || 0,
          customizations: customizations,
          customizedPrice: this.customizedPrice,
        };

        let existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];

        const existingCartItem = existingCart.find(
          (item) =>
            item.customization_id === cartItem.customization_id &&
            item.product.product_id === cartItem.product.product_id
        );

        if (existingCartItem) {
          existingCartItem.quantity += 1;
        } else {
          existingCart.push(cartItem);
        }

        sessionStorage.setItem("cart", JSON.stringify(existingCart));
        this.$store.commit("updateCart", existingCart);

        if (!this.$store.state.user.isAuthenticated) {
          this.$notify({
            title: "Item added to cart successfully",
            type: "bg-success-subtle text-success",
            duration: "5000",
          });
        }
        this.$router.push("/my-cart");
      }
    },
    saveChangesAndAddToCart() {
      let errors = this.verifyConfiguration(this.selectedCustomizations);
      if (errors.length !== 0) {
        for (let error of errors) {
          this.$notify({
            title: error,
            type: "bg-danger-subtle text-danger",
            duration: 10000,
          });
        }
      } else {
        this.saveCustomization();
        this.addToCart();
      }
    },
    resetCustomizations() {
      this.isEditing = false;
      window.location.href = this.$route.path;
    },
    getExpectedDeliveryDate() {
      console.log(this.pincode);
      const currentDate = new Date();

      const expectedDeliveryDate = new Date(currentDate);
      expectedDeliveryDate.setDate(currentDate.getDate() + 14);

      const formattedDate = `${expectedDeliveryDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${(expectedDeliveryDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${expectedDeliveryDate.getFullYear()}`;

      this.expectedDeliveryDate = formattedDate;
    },
    startCustomization() {
      const customizationQueryParam = this.$route.query.customizationId;
      const currentUrl = this.$route.path;
      let customizedUrl = "";
      if (customizationQueryParam) {
        customizedUrl = `${currentUrl}?customizationId=${customizationQueryParam}`;
      }
      // else{
      //   const customizationId = this.generateUniqueCustomizationId();
      //   customizedUrl = `${currentUrl}?customizationId=${customizationId}`;
      // }
      this.$router.push(customizedUrl);
      this.isEditing = true;
    },
    compareArrays(currentCustomizations) {
      if (this.selectedCustomizations.length !== currentCustomizations.length) {
        return false;
      }
      for (let i = 0; i < this.selectedCustomizations.length; i++) {
        const selected = this.selectedCustomizations[i];
        const current = currentCustomizations[i];
        if (
          selected.selected_quantity !== current.selected_quantity ||
          selected.option.product_customization_default_quantity_id !==
            current.option.product_customization_default_quantity_id
        ) {
          return false;
        }
      }
      return true;
    },
    verifyConfiguration(selectedOptions) {
      let errors = [];
      let ram_count = 0;
      let selected_rams = selectedOptions.filter(
        (opt) =>
          opt.option &&
          opt.option.customization_option &&
          opt.option.customization_option.customization_category &&
          opt.option.customization_option.customization_category.name == "RAM"
      );
      console.log("selected_rams", selected_rams);
      for (let ram of selected_rams) {
        ram_count += ram.selected_quantity;
      }
      let processor = selectedOptions.find(
        (option) =>
          option.option.customization_option &&
          option.option.customization_option.customization_category &&
          option.option.customization_option.customization_category
            .parent_customization_category &&
          option.option.customization_option.customization_category
            .parent_customization_category.name === "CPU"
      );
      if (processor) {
        console.log("processor", processor);
        console.log("ram count and quant", ram_count);
        if (processor.selected_quantity == 1) {
          if (ram_count > 16) {
            errors.push("RAM Count cannot be more than 16");
          }
          if (ram_count < 1) {
            errors.push("The number of RAMs cannot be less than 1");
          }
        } else if (processor.selected_quantity >= 2) {
          if (ram_count > 32) {
            errors.push("RAM Count cannot be more than 32 for 2 processors");
          }
          if (ram_count % 2 !== 0) {
            errors.push("RAM Count should be a multiple of 2 for 2 processors");
          }
          if (ram_count < 2) {
            errors.push(
              "The number of RAMs cannot be less than 2 for 2 processors"
            );
          }
        } else {
          errors.push("At least one processor should be selcted.");
        }
      } else {
        errors.push("No Processor selected");
      }
      let storage_count = 0;
      let u2_count = 0;

      let selected_storage = selectedOptions.filter(
        (option) =>
          option.option.customization_option.customization_category
            .parent_customization_category.name == "Storage"
      );

      for (let ssd of selected_storage) {
        storage_count += ssd.selected_quantity;
      }

      let selected_u2 = this.selectedCustomizations.filter(
        (option) =>
          option.option.customization_option.customization_category.name ==
          "U.2 SSD"
      );
      for (let ssd of selected_u2) {
        u2_count += ssd.selected_quantity;
      }
      if (u2_count > 6) {
        errors.push("U.2 Storage Drives cannot be more than 6");
      }
      if (storage_count > 24) {
        errors.push("Storage Drives cannot be more than 24");
      }
      if (storage_count < 1) {
        errors.push("Storage Drives cannot be less than 1");
      }

      return errors;
    },
    saveCustomization() {
      const customizationQueryParam = this.$route.query.customizationId;
      const currentUrl = this.$route.path;
      const customizations = this.selectedCustomizations;
      const errors = this.verifyConfiguration(customizations);
      console.log(errors);
      if (errors.length !== 0) {
        for (let error of errors) {
          this.$notify({
            title: error,
            type: "bg-danger-subtle text-danger",
            duration: 10000,
          });
        }
      } else {
        let customizedUrl = "";
        if (customizationQueryParam) {
          if (
            sessionStorage.getItem(
              `customization_${this.$route.params.product_id}_${customizationQueryParam}`
            )
          ) {
            if (
              this.compareArrays(
                JSON.parse(
                  sessionStorage.getItem(
                    `customization_${this.$route.params.product_id}_${customizationQueryParam}`
                  )
                )
              )
            ) {
              customizedUrl = `${currentUrl}?customizationId=${customizationQueryParam}`;
            } else {
              const newCustomizationId = this.generateUniqueCustomizationId();
              customizedUrl = `${currentUrl}?customizationId=${newCustomizationId}`;
              sessionStorage.setItem(
                `customization_${this.$route.params.product_id}_${newCustomizationId}`,
                JSON.stringify(customizations)
              );
              sessionStorage.setItem(
                `customization_${this.$route.params.product_id}_${newCustomizationId}_price`,
                this.customizedPrice
              );
            }
          }
        } else {
          const customizationId = this.generateUniqueCustomizationId();
          customizedUrl = `${currentUrl}?customizationId=${customizationId}`;
          sessionStorage.setItem(
            `customization_${this.$route.params.product_id}_${customizationId}`,
            JSON.stringify(customizations)
          );
          sessionStorage.setItem(
            `customization_${this.$route.params.product_id}_${customizationId}_price`,
            this.customizedPrice
          );
        }

        this.$router.push(customizedUrl);
        this.isEditing = false;
      }
    },
    generateRandomId() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const idLength = 6;
      let id = "";
      for (let i = 0; i < idLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        id += characters[randomIndex];
      }
      return id;
    },
    generateUniqueCustomizationId() {
      let customizationId;
      do {
        customizationId = this.generateRandomId();
      } while (
        sessionStorage.getItem(
          `customization_${this.$route.params.product_id}_${customizationId}`
        ) !== null
      );
      return customizationId;
    },
    handleIncreasePrice(price) {
      let newPrice = parseFloat(this.customizedPrice) + parseFloat(price);
      this.customizedPrice = newPrice;
    },
    handleDecreasePrice(price) {
      let newPrice = parseFloat(this.customizedPrice) - parseFloat(price);
      this.customizedPrice = newPrice;
    },
    getRatingLabel(rating) {
      return rating === 0 ? "Select Rating" : `${rating} Stars`;
    },
  },
};
</script>

<style scoped>
.button.btn-prev {
  margin-right: 100px;
}

.button.btn-prev:hover,
.button.btn-next:hover {
  background-color: red;
  cursor: pointer;
}

.nav-pills .nav-item .active {
  background-color: #dc3545;
}

.nav-item {
  flex: 1;
  text-align: center;
  padding: 10px;
}

.nav-link {
  color: #333;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #e74c3c;
}

.nav-icon {
  font-size: 32px;
  margin-bottom: 8px;
}

/* .card-img-top:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
} */

/* .button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
} */

.btn-prev {
  color: #dc3545;
}

.btn-next {
  color: #dc3545;
}

.bi-chevron-up,
.bi-chevron-down {
  font-size: 1.5rem;
  vertical-align: middle;
}

.small-text {
  font-size: 14px;
}
a {
  color: inherit; /* Use the color inherited from the parent element */
  text-decoration: none; /* Remove underline */
}

a:hover {
  text-decoration: underline; /* Add underline on hover if desired */
}

a:visited {
  color: inherit; /* Use the color inherited from the parent element for visited links */
}
</style>
