<template>
  <div class="container">
    <nav
      v-if="!this.isModal"
      style="--bs-breadcrumb-divider: '>'"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/">
            <i class="bi bi-house"></i>
            Home
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary" href="/customers-detail">
            <b>
              <i class="bi bi-person"></i>
              Customers
            </b>
          </a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-secondary" href="#">
            <b>
              <i
                :class="[edit ? 'bi bi-person-check' : 'bi bi-person-add']"
              ></i>
              {{ edit ? "Edit Customers" : "Add Customers" }}
            </b>
          </a>
        </li>
      </ol>
    </nav>

    <h1 v-if="!this.isModal">
      {{ edit ? "Edit Customers" : "Add Customers" }}
    </h1>

    <hr v-if="!this.isModal" />

    <div class="container-fluid">
      <div class="row">
        <nav class="col-md-3 col-lg-2 d-md-block">
          <div class="position-sticky mt-2">
            <ul class="nav nav-pills flex-column mb-auto">
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  @click="showContent('general')"
                  :class="{ active: selectedSection == 'general' }"
                >
                  Genral
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  @click="showContent('content1')"
                  :class="{ active: selectedSection == 'content1' }"
                >
                  Others
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  @click="showContent('content2')"
                  :class="{ active: selectedSection == 'content2' }"
                >
                  Address
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  @click="showContent('content3')"
                  :class="{ active: selectedSection == 'content3' }"
                >
                  Contact Person
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  @click="showContent('content4')"
                  :class="{ active: selectedSection == 'content4' }"
                >
                  Remarks
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <template v-if="customerData !== null">
            <CustomerForms
              :activeSection="selectedSection"
              @currency-selected="handleCurrencySelected"
              :currencies="currencies"
              :regions="regions"
              :paymentTerms="paymentTerms"
              :edit="edit"
              @save-form="updateCustomer"
              :customerData="customerData"
            />
          </template>
          <template v-else>
            <CustomerForms
              :activeSection="selectedSection"
              @currency-selected="handleCurrencySelected"
              :currencies="currencies"
              :regions="regions"
              :paymentTerms="paymentTerms"
              :edit="edit"
              @save-form="handleSaveForm"
              :customerData="customerData"
              :isModal="this.isModal"
            />
          </template>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import CustomerForms from "@/components/Account/CustomerForms.vue";

export default {
  components: {
    CustomerForms,
  },

  data() {
    return {
      selectedSection: "general",
      currencies: "",
      regions: "",
      paymentTerms: "",
      countryId: "",
      edit: false,
      customerId: "",
      customerData: null,
    };
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getCustomerById();
    this.fetchAllList();
    if (this.isModal) {
      console.log("isModal is true:", this.isModal);
    } else {
      console.log("MOdal is False", this.isModal);
    }

    const isEdit = this.$route.query.edit === "true";
    console.log("Edit mode:", isEdit);
    const customerId = this.$route.query.customerId;
    console.log("Customer ID:", customerId);
  },
  created() {
    const editQueryParam = this.$route.query.edit;
    if (editQueryParam === "true") {
      this.edit = true;
      this.customerId = this.$route.query.customerId || "";
    }
  },
  methods: {
    showContent(section) {
      this.selectedSection = section;
    },
    handleCurrencySelected(currencyId) {
      console.log("Selected Currency ID:", currencyId);
      this.countryId = currencyId;
      this.fetchAllList();
      // this.fetchRegions(currencyId);
    },
    initializeVariables(userData) {
      userData.addresses.forEach((address) => {
        if (address.address_type === "billing") {
          this.billingAddress = { ...address };
        } else if (address.address_type === "shipping") {
          this.shippingAddress = { ...address };
        }
      });
    },
    async getCustomerById() {
      try {
        if (this.customerId) {
          const response = await axios.get(
            `/accounts/get_customer_by_id/${this.customerId}`
          );
          console.log("customerData", response.data);
          this.customerData = response.data;
        }
      } catch (error) {
        console.error("Error retrieving customer data:", error);
      }
    },

    async handleSaveForm(formData) {
      const formDataToSend = new FormData();
      formDataToSend.append("formData", JSON.stringify(formData.formData));
      for (const key in formData) {
        if (key === "formData" && formData[key].file) {
          formDataToSend.append("image[]", formData[key].file);
        } else {
          formDataToSend.append(key, JSON.stringify(formData[key]));
        }
      }

      try {
        const response = await axiosInstance.post(
          "/accounts/create_customer/",
          formDataToSend
        );
        console.log("Backend response:", response.data);
        // window.location.reload();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },

    async fetchAllList() {
      try {
        const response = await axiosInstance.get("/accounts/create_customer/");
        this.paymentTerms = response.data.payment_terms;
        this.currencies = response.data.currencies;
        this.regions = response.data.regions;

        console.log("this is all data", response.data);
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    },
    updateCustomer(formData) {
      const customerId = this.customerId;
      const formDataToSend = new FormData();
      formDataToSend.append("formData", JSON.stringify(formData.formData));
      for (const key in formData) {
        if (key === "formData" && formData[key].file) {
          formDataToSend.append("image[]", formData[key].file);
        } else {
          formDataToSend.append(key, JSON.stringify(formData[key]));
        }
      }

      axios
        .put(`/accounts/update_customer/${customerId}/`, formDataToSend)
        .then((response) => {
          console.log(response.data.message);

          this.$router.push("/customers-detail");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.nav-link.active {
  color: white !important;
  background-color: #dc3545 !important;
}

.nav-link {
  color: black !important;
}

.nav-link:not(.active):hover {
  color: grey !important;
}
</style>
